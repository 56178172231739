import React, { useCallback, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Image from '@components/core/media/image'
import Section from '@components/core/container/section'
import Slider from '@components/core/ui/slider'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import Button from '@components/core/ui/button'
import VideoPlayer from '@components/core/media/videoPlayer'
import { isVideoFile } from '@utils/props'
import useBreakpointValue from '@components/system/hooks/useBreakpointValue'

export type MediaGalleryProps = SOG.Props.IDefault & {
  initialIndex: number
  entries: SOG.Props.IGlobalMedia[]
}

export default function MediaGallery({
  entries,
  initialIndex = 0,
  sx = {},
}: MediaGalleryProps): React.ReactElement {
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex)
  const sliderHeight = useBreakpointValue({
    sm: 180,
    md: 400,
    lg: 600,
  })
  const currentEntry = entries[activeIndex]

  const sliderEntries: {
    media: SOG.Props.Image | SOG.Props.Video
    isVideo?: boolean
    thumbnail?: SOG.Props.Image
    key: string
  }[] = useMemo(() => {
    return entries.map((entry, index) => {
      const isVideo = isVideoFile(entry.media?.file)
      return {
        key: `${index}-${entry.id}`,
        media: entry.media,
        thumbnail: entry.thumbnail,
        isVideo,
      }
    })
  }, [])

  const changeActiveSlide = useCallback((activeIndex) => {
    setActiveIndex(activeIndex)
  }, [])

  return (
    <Section
      maxWidth={false}
      sx={[
        (theme) => ({
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            overflow: 'hidden',
          },
        }),
        sx as SOG.Props.SxInArray,
      ]}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          mb: 8,
          [theme.breakpoints.up('md')]: {
            mb: 12,
          },
        })}
      >
        <Slider
          onChangeSlide={changeActiveSlide}
          slidesPerView={1}
          initialSlide={initialIndex}
          arrowsType="overlay"
          loop
          overflow
          centeredSlides
          sx={{
            height: sliderHeight,
          }}
        >
          {sliderEntries.map((entry) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                key={entry.key}
              >
                {!entry.isVideo && (
                  <Image
                    {...(entry.media as SOG.Props.Image)}
                    sx={{ width: 'auto' }}
                    fixedHeight={sliderHeight}
                  />
                )}
                {entry.isVideo && (
                  <VideoPlayer
                    video={entry.media as SOG.Props.Video}
                    thumbnail={entry.thumbnail}
                    width="auto"
                    height={sliderHeight}
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </Box>
            )
          })}
        </Slider>
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        })}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Headline variant="h7" sx={{ mb: 2 }}>
            {currentEntry.title}
          </Headline>
          <Copy sx={{ mb: 2 }}>{currentEntry.description}</Copy>
          <Copy
            sx={{ mt: 'auto' }}
            html={`<sup>®</sup> ${currentEntry.copyright}`}
          />
        </Box>
        <Box
          sx={(theme) => ({
            mt: 8,
            [theme.breakpoints.up('md')]: {
              mt: 0,
              flexShrink: 1,
              flexGrow: 0,
              ml: 'auto',
            },
          })}
        >
          <Button
            variant="primary"
            to={currentEntry.media?.file?.url}
            isExternal
            isDownload
          >
            <FormattedMessage id="button.download" />
          </Button>
        </Box>
      </Box>
    </Section>
  )
}
