import React, {
  ComponentType,
  CSSProperties,
  ReactElement,
  useState,
} from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { MotionProps } from 'framer-motion'

import MediaOverlay from '@components/core/ui/mediaOverlay'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'

export type ImageProps = SOG.Props.IDefault &
  MotionProps & {
    data: IGatsbyImageData
    alt?: string
    description?: string
    copyright?: string
    objectFit?: CSSProperties['objectFit']
    objectPosition?: CSSProperties['objectPosition']
    position?: 'static' | 'relative'
    imgStyle?: React.CSSProperties
    backgroundColor?: 'string'
    fixedHeight?: number
    onDownload?: () => void
    onFullscreen?: () => void
    component?: ComponentType
  }

/**
 * Image
 */
export default function Image({
  data,
  alt = '',
  position = 'relative',
  objectFit,
  objectPosition,
  backgroundColor,
  onFullscreen,
  onDownload,
  component,
  initial,
  animate,
  whileHover,
  whileInView,
  transition,
  variants,
  viewport,
  imgStyle,
  fixedHeight,
  sx,
}: ImageProps): ReactElement | null {
  if (!data || !data.images) return null
  const [isHover, setIsHover] = useState(false)
  const ref = React.useRef(null)
  // const mousePosition = useMouse(ref, {
  //   enterDelay: 150,
  //   leaveDelay: 100,
  // })
  const additionalProps = {
    ...(component && {
      component,
    }),
  }
  const aspectRation = data.width / data.height
  const dataWidth = fixedHeight ? aspectRation * fixedHeight : data.width
  const dataHeight = fixedHeight || data.height

  return (
    <StyledMotionDiv
      {...additionalProps}
      initial={initial}
      animate={animate}
      whileInView={whileInView}
      whileHover={whileHover}
      variants={variants}
      viewport={viewport}
      transition={transition}
      sx={[
        {
          position,
          overflow: 'hidden',
          width: '100%',
          '& picture, & picture img': {
            height: 'inherit',
            width: 'inherit',
          },
          '& .gatsby-image-wrapper [data-main-image]': {
            //transition: 'opacity 500ms linear 0s, transform 1s ease !important',
            transformOrigin: 'center',
            '&:hover': {
              // transition: 'opacity 500ms linear 0s, transform 300ms ease !important',
            },
          },
        },
        sx as SOG.Props.SxInArray,
      ]}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      ref={ref}
    >
      <GatsbyImage
        image={{ ...data, width: dataWidth, height: dataHeight }}
        alt={alt}
        loading="lazy"
        objectFit={objectFit}
        objectPosition={objectPosition}
        backgroundColor={backgroundColor}
        style={{ height: 'inherit' }}
        imgStyle={
          imgStyle || {
            height: '100%',
            width: '100%',
            // transform: getMousePositionTransform(mousePosition, 0.07),
          }
        }
      />
      {(onFullscreen || onDownload) && (
        <MediaOverlay
          onFullscreen={onFullscreen}
          onDownload={onDownload}
          isOpen={isHover}
        />
      )}
    </StyledMotionDiv>
  )
}
