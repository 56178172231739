import { getGlobalProp } from '@utils/props'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulNewestGlobalJobs = (limit = 4): SOG.Props.IGlobalJob[] => {
  const { locale } = useIntl()
  const { newestGlobalJobs } = useStaticQuery(
    graphql`
      query {
        newestGlobalJobs: allContentfulGlobalJob(
          sort: { order: ASC, fields: createdAt }
        ) {
          nodes {
            ...GlobalJobFragment
          }
        }
      }
    `
  )
  return newestGlobalJobs.nodes
    .filter((entry: SOG.Contentful.IGlobalJob) => entry.node_locale === locale)
    .slice(0, limit)
    .map((entry: SOG.Contentful.IGlobalJob) => getGlobalProp(entry))
}
export default useContentfulNewestGlobalJobs
