import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'
import { Box, IconButton } from '@mui/material'

import Icon from '../icon'
import { imageControlsMotionProps } from '@utils/animation'

export type MediaOverlayProps = SOG.Props.IDefault & {
  isOpen: boolean
  onDownload?: () => void
  onFullscreen?: () => void
}

export default function MediaOverlay({
  isOpen,
  onDownload,
  onFullscreen,
}: MediaOverlayProps): ReactElement {
  return (
    <Box
      component={motion.div}
      animate={isOpen ? 'show' : 'hide'}
      {...imageControlsMotionProps}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '80px',
        height: '100%',
        backgroundColor: 'secondary.main',
        color: 'text.inverted',
        zIndex: 1,
      }}
    >
      {onFullscreen && (
        <IconButton
          sx={{
            width: '100%',
            height: '50%',
            borderRadius: 0,
            borderBottom: '1px solid #fff',
            color: 'hotpink',
          }}
          color="inherit"
          size="small"
          onClick={onFullscreen}
        >
          <Icon
            name="Fullscreen"
            sx={{
              '& path': {
                fill: 'white',
              },
            }}
          />
        </IconButton>
      )}
      {onDownload && (
        <IconButton
          sx={{
            width: '100%',
            height: '50%',
            borderRadius: 0,
          }}
          color="inherit"
          size="small"
          onClick={onDownload}
        >
          <Icon name="Download" color="inherit" />
        </IconButton>
      )}
    </Box>
  )
}
