import React, { useState } from 'react'
import { Grid, RadioGroup, Typography, Box, IconButton } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { validateEmail } from '@utils/string-tools'
import themeConfig from '@config/theme'

import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import Radio from '@components/core/ui/formfields/radio'
import TextInput from '@components/core/ui/formfields/textinput'
import TextArea from '@components/core/ui/formfields/textArea'
import Button from '@components/core/ui/button'
import Icon from '@components/core/ui/icon'

import FriendlyCaptcha from '@components/core/ui/formfields/friendlyCaptcha'
import Link from '@components/core/ui/link'

export type ContactFormProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    linkTo?: SOG.Props.LinkTo
    headline?: string
    fixedCategory?: string
    copy?: SOG.Props.RichText
  }

export default function ContactForm({
  contentful_id,
  linkTo,
  headline,
  fixedCategory,
  copy,
}: ContactFormProps): React.ReactElement {
  const intl = useIntl()
  const [success, setSuccess] = useState(false)
  const [sending, setSending] = useState(false)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [category, setCategory] = useState(fixedCategory ?? '')
  const [captchacode, setCaptchacode] = useState('')
  const contactApiEndpoint = `${
    process.env.GATSBY_CONTACT_SERVICE_URL ?? ''
  }/api/contact`
  const categories = [
    'label.contactCategory1',
    'label.contactCategory2',
    'label.contactCategory3',
    'label.contactCategory4',
    'label.contactCategory5',
    'label.contactCategory6',
    'label.contactCategory7',
  ]
  const socialItems: {
    icon: 'Instagram' | 'LinkedIn' | 'Xing' | 'Facebook'
    link: string
  }[] = [
    {
      icon: 'Instagram',
      link: 'https://www.instagram.com/inside_solivergroup',
    },
    {
      icon: 'LinkedIn',
      link: 'https://www.linkedin.com/company/s-oliver-group',
    },
    { icon: 'Xing', link: 'https://www.xing.com/pages/soliver' },
    { icon: 'Facebook', link: 'https://www.facebook.com/s.OliverFashion/' },
  ]

  async function handleSendRequest() {
    setSending(true)

    const response = await fetch(contactApiEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subject: intl.formatMessage({
          id: category,
        }),
        name: name,
        email: email,
        text: message,
        friendlyCaptchaCode: captchacode,
      }),
    })

    if (response.status === 202) {
      setSuccess(true)
      const element = document.getElementById('section')
      if (!element) return

      window.scroll({
        top: 0,
        behavior: 'instant',
      })
    }
    setSending(false)
  }

  return (
    <>
      <Section
        id={contentful_id}
        maxWidth={fixedCategory ? 'sm' : 'md'}
        sx={(theme) => ({
          mb: 10,
          [theme.breakpoints.up('md')]: {
            mb: 18,
          },
        })}
      >
        <Headline
          variant="h2"
          sx={(theme) => ({
            mb: 6,
            [theme.breakpoints.up('md')]: {
              mb: 5,
            },
          })}
        >
          {headline && !success && headline}
          {(!headline || success) && (
            <FormattedMessage
              id={
                success
                  ? 'label.contactThankYouHeadline'
                  : 'label.contactHeadline'
              }
            />
          )}
        </Headline>
        <Copy
          variant="body1"
          sx={(theme) => ({
            mb: 10,
            [theme.breakpoints.up('md')]: {
              mb: 16,
            },
          })}
        >
          {copy && !success && copy}
          {(!copy || success) && (
            <FormattedMessage
              id={success ? 'label.contactThankYouCopy' : 'label.contactCopy'}
            />
          )}
        </Copy>
        {!success ? (
          <>
            {!fixedCategory && (
              <>
                <Headline
                  variant="h5"
                  sx={(theme) => ({
                    mb: 8,
                    [theme.breakpoints.up('md')]: {
                      mb: 12,
                    },
                  })}
                >
                  <FormattedMessage id="label.contactSubline" />
                </Headline>
                <Headline
                  variant="h7"
                  sx={(theme) => ({
                    mb: 6,
                    [theme.breakpoints.up('md')]: {
                      mb: 10,
                    },
                  })}
                >
                  <FormattedMessage id="label.contactCategoryHeadline" />
                </Headline>
                <RadioGroup
                  name="categories"
                  sx={(theme) => ({
                    mb: 10,
                    [theme.breakpoints.up('md')]: {
                      mb: 16,
                    },
                  })}
                  value={category}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCategory(e.target.value)
                  }
                >
                  <Grid container spacing={{ xs: 1, md: 3 }}>
                    {categories.map((title, index) => (
                      <Grid key={index} item xs={12} md={4}>
                        <Radio
                          name={title}
                          value={title}
                          id={title}
                          label={intl.formatMessage({
                            id: title,
                          })}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </>
            )}
            <TextInput
              variant="filled"
              id="name"
              name="name"
              label={intl.formatMessage({
                id: 'label.contactName',
              })}
              placeholder={intl.formatMessage({
                id: 'label.contactNamePlaceholder',
              })}
              required
              sx={(theme) => ({
                mb: 6,
                [theme.breakpoints.up('md')]: {
                  mb: 8,
                },
              })}
              value={name}
              error={nameError}
              errorText={intl.formatMessage({
                id: 'error.empty',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value)
                setNameError(e.target.value.length === 0)
              }}
            />
            <TextInput
              variant="filled"
              id="email"
              name="email"
              label={intl.formatMessage({
                id: 'label.contactEmail',
              })}
              placeholder={intl.formatMessage({
                id: 'label.contactEmailPlaceholder',
              })}
              type="email"
              required
              sx={(theme) => ({
                mb: 6,
                [theme.breakpoints.up('md')]: {
                  mb: 8,
                },
              })}
              value={email}
              error={emailError}
              errorText={
                !!email
                  ? intl.formatMessage({
                      id: 'error.email',
                    })
                  : intl.formatMessage({
                      id: 'error.empty',
                    })
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value)
                setEmailError(!validateEmail(e.target.value))
              }}
            />
            <TextArea
              variant="filled"
              name="message"
              id="message"
              label={intl.formatMessage({
                id: 'label.contactMessage',
              })}
              placeholder={intl.formatMessage({
                id: 'label.contactMessagePlaceholder',
              })}
              charLimit={500}
              minRows={6}
              required
              sx={(theme) => ({
                mb: 16,
                [theme.breakpoints.up('md')]: {
                  mb: 8,
                },
              })}
              value={message}
              error={messageError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMessage(e.target.value)
                setMessageError(e.target.value.length === 0)
              }}
            />
            <FriendlyCaptcha
              name="friendlyCaptchaCode"
              setCaptchacode={setCaptchacode}
            />
            <Button
              variant="primary"
              sx={{ mr: 'auto' }}
              onClick={handleSendRequest}
              isDisabled={
                !name ||
                !email ||
                !message ||
                !category ||
                !captchacode ||
                nameError ||
                emailError ||
                messageError ||
                sending
              }
            >
              <FormattedMessage id="button.send" />
            </Button>
          </>
        ) : (
          <Button variant="primary" sx={{ mr: 'auto' }} to="/">
            <FormattedMessage id="button.backHome" />
          </Button>
        )}
      </Section>
      <Section theme="light" maxWidth="md">
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              flex: 1,
              flexDirection: 'column',
              borderBottom: `1px solid ${themeConfig.palette.grey[500]}`,
              pb: 6,
              px: 6,
              [theme.breakpoints.up('md')]: {
                borderBottom: 0,
                borderRight: `1px solid ${themeConfig.palette.grey[500]}`,
                pr: 12,
                pl: 0,
                pb: 0,
              },
            })}
          >
            <Typography
              variant="smallBold"
              component="div"
              sx={{
                textTransform: 'uppercase',
                color: themeConfig.palette.common.black,
                mb: 4,
              }}
            >
              <FormattedMessage id="label.call" />
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="Phone" fontSize="large" sx={{ mr: 6 }} />
              <Headline variant="h5" component="span">
                <FormattedMessage id="label.contactPhoneNumber" />
              </Headline>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              flex: 1,
              flexDirection: 'column',
              pt: 6,
              px: 6,
              [theme.breakpoints.up('md')]: {
                pt: 0,
                pl: 12,
                pr: 0,
              },
            })}
          >
            <Typography
              variant="smallBold"
              component="div"
              sx={{
                textTransform: 'uppercase',
                color: themeConfig.palette.common.black,
                mb: 4,
              }}
            >
              <FormattedMessage id="label.follow" />
            </Typography>
            <Grid container spacing={3}>
              {socialItems.map(({ icon, link }, index) => (
                <Grid item key={index}>
                  <IconButton color="inherit" sx={{ p: 0 }}>
                    <Link to={link} isExternal>
                      <Icon name={icon} fontSize="large" />
                    </Link>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Section>
      {linkTo && (
        <Section paddingSize="lg">
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Headline
              variant="h3"
              sx={(theme) => ({
                hyphens: 'auto',
                flex: 1,
                [theme.breakpoints.up('md')]: {
                  flex: 2,
                },
              })}
            >
              <FormattedMessage id="label.contactPressHeadline" />
            </Headline>
            <Box
              sx={(theme) => ({
                flex: 1,
                mt: 6,
                [theme.breakpoints.up('md')]: {
                  mt: 0,
                  ml: 20,
                },
              })}
            >
              <Button
                variant="primary"
                to={linkTo.url}
                isExternal={linkTo.isExternal}
                sx={(theme) => ({
                  mr: 'auto',
                  mt: 16,
                  [theme.breakpoints.up('md')]: {
                    mt: 8,
                  },
                })}
              >
                <FormattedMessage id="button.contactPress" />
              </Button>
            </Box>
          </Box>
        </Section>
      )}
    </>
  )
}
