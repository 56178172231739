import React, { Fragment } from 'react'
import { useInView } from 'react-intersection-observer'

import ComponentContainer from '@components/core/container/componentContainer'
import Section from '@components/core/container/section'
import { richTextOptions } from '@utils/richtext'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Headline from '@components/core/text/headline'
import Image from '@components/core/media/image'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'

export type YearItemProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    year: string
    theme: SOG.Contentful.Theme
    copy?: SOG.Contentful.BasicRichTextType
    textWithImage: boolean
    image: SOG.Contentful.IAssetImage
  }

export default function YearItem({
  contentful_id,
  year,
  theme,
  copy,
  sx,
  textWithImage,
  image,
}: YearItemProps) {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const [ref, InView] = useInView({
    triggerOnce: true,
    threshold: 1.0,
    rootMargin: '20px 0px -20px 0px',
  })
  return (
    <Section ref={ref} id={contentful_id} sx={sx} maxWidth={false}>
      {!!image && !!textWithImage ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column-reverse',
            [theme.breakpoints.up('xl')]: {
              marginTop: '180px',
              flexDirection: 'row',
            },
          })}
        >
          <StyledMotionDiv sx={{ flex: '1' }}>
            <Image data={image.gatsbyImageData} />
          </StyledMotionDiv>
          <Box sx={{ flex: '1' }}>
            <Headline
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: theme.spacing(30),
                lineHeight: '1 !important',
                textAlign: 'right',
                transform: `translate(-16px, 35px)`,
                transition: 'transform 0.7s ease-in-out',
                opacity: '1',
                [theme.breakpoints.up('xl')]: {
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  fontSize: theme.spacing(45),
                  transform: `translate(30px, ${InView ? '-15px' : '15px'})`,
                },
              })}
            >
              {year}
            </Headline>
            <ComponentContainer
              theme={theme}
              size={isLarge ? undefined : 'xlarge'}
              sx={(theme) => ({
                [theme.breakpoints.up('xl')]: {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  fontSize: theme.spacing(4.5),
                  lineHeight: '30px',
                  padding: theme.spacing(7),
                },
              })}
            >
              {!!copy && renderRichText(copy, richTextOptions)}
            </ComponentContainer>
          </Box>
        </Box>
      ) : (
        <Fragment>
          <Headline
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontSize: theme.spacing(30),
              lineHeight: '1 !important',
              textAlign: 'right',
              transform: `translate(-16px, 35px)`,
              transition: 'transform 0.7s ease-in-out',
              [theme.breakpoints.up('xl')]: {
                fontSize: theme.spacing(45),
                transform: `translate(30px, ${InView ? '-16px' : '35px'})`,
              },
            })}
          >
            {year}
          </Headline>
          <ComponentContainer theme={theme} size="xlarge">
            {!!copy && renderRichText(copy, richTextOptions)}
          </ComponentContainer>
        </Fragment>
      )}
    </Section>
  )
}
