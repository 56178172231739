const unit = 4
const gutters = {
  xs: 6,
  sm: 6,
  md: 8,
  lg: 8,
  xl: 8,
}
const padding = {
  sm: {
    sm: 8,
    md: 8,
    lg: 20,
  },
  md: {
    sm: 8,
    md: 8,
    lg: 20,
  },
  lg: {
    sm: 12,
    md: 12,
    lg: 30,
  },
}

const Spacing = {
  unit,
  gutters,
  padding,
  maxWidth: {
    xs: 1280 / unit + gutters.lg * 2,
    sm: 768 / unit + gutters.md * 2,
    md: 968 / unit + gutters.md * 2,
    lg: 1280 / unit + gutters.lg * 2,
    xl: 1920 / unit + gutters.lg * 2,
  },
}

export default Spacing
