import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  KeyboardEvent,
} from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Box } from '@mui/material'

import NavItem from './horizontalNavItem'

interface IHorizontalNav {
  items: SOG.Props.LinkTo[]
  forceClose?: boolean
  onOpen: (open: boolean) => void
}

export default function HorizontalNav({
  items,
  forceClose = false,
  onOpen,
}: IHorizontalNav): ReactElement {
  const CompRef = useRef<HTMLElement>(null)
  const [focusIndex, setFocusIndex] = useState(-1)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [prevActiveIndex, setPrevActiveIndex] = useState(-1)

  useEffect(() => {
    onOpen(activeIndex !== -1)
    setPrevActiveIndex(activeIndex)
  }, [activeIndex])

  useEffect(() => {
    if (forceClose) {
      setFocusIndex(-1)
      setActiveIndex(-1)
    }
  }, [forceClose])

  function handleClickOutside() {
    setFocusIndex(-1)
    setActiveIndex(-1)
  }

  // function handleKeyDown(e: KeyboardEvent) {
  //   const curIndex = focusIndex === -1 ? 0 : focusIndex
  //   if (e.key === 'ArrowRight') {
  //     setFocusIndex((curIndex + 1) % items.length)
  //   } else if (e.key === 'ArrowLeft') {
  //     setFocusIndex(curIndex === 0 ? items.length - 1 : curIndex - 1)
  //   }
  // }

  function renderNavList(items: SOG.Props.LinkTo[]) {
    return items.map((item, i) => {
      if (!!item.hideInNavigation) return
      return (
        <NavItem
          key={i}
          item={item}
          tabIndex={focusIndex === i || (i === 0 && focusIndex === -1) ? 0 : -1}
          isOpen={
            activeIndex === i || (prevActiveIndex === i && activeIndex === -1)
          }
          isFocused={focusIndex === i}
          toggleMenu={(open) => {
            setActiveIndex(!open || activeIndex === i ? -1 : i)
          }}
        />
      )
    })
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleClickOutside()
      }}
    >
      <Box component="nav" ref={CompRef}>
        <Box
          component="ul"
          data-testid="list"
          role="menubar"
          aria-label="Hauptnavigation"
          // onKeyDown={handleKeyDown}
          sx={{
            listStyle: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
            marginTop: '20px',
          }}
        >
          {renderNavList(items)}
        </Box>
      </Box>
    </ClickAwayListener>
  )
}
