import React, { ReactElement } from 'react'

import { Box } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import List from '@mui/material/List'

import theme from '@config/theme'
import NavItem from './drawerNavItem'
import MobileSearch from '../mobileSearch'
import LanguageSwitch from '@components/core/ui/languageSwitch'

export interface INavItemData {
  label: string
  url: string
  icon?: string
  children?: INavItemData[]
}

export interface IDrawerNav extends SOG.Props.IDefault {
  items: SOG.Props.LinkTo[]
  openState: boolean
  toggleDrawer: (open: boolean) => void
}

export default function DrawerNav({
  items,
  openState,
  toggleDrawer,
}: IDrawerNav): ReactElement {
  function renderNavList(items: SOG.Props.LinkTo[], level: number) {
    return (
      <>
        <List component="div" disablePadding>
          {items.map((item) => {
            if (!!item.hideInNavigation) return
            return (
              <NavItem
                level={level}
                key={item.url}
                to={item.url}
                label={item.label as string}
                onClick={toggleDrawer}
              >
                {item.children &&
                  item.children.length > 0 &&
                  renderNavList(item.children, level + 1)}
              </NavItem>
            )
          })}
        </List>
      </>
    )
  }

  return (
    <SwipeableDrawer
      data-testid="drawer"
      anchor={'right'}
      open={openState}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      transitionDuration={500}
      PaperProps={{
        sx: {
          width: '100vw',
          pt: 12.5,
          display: 'flex',
        },
      }}
    >
      <MobileSearch
        toggleMenu={(bool: boolean) => toggleDrawer(bool)}
        menuState={openState}
      />
      {renderNavList(items, 0)}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '72px',
          borderTop: 'solid 1px',
          borderColor: theme.palette.grey[300],
          mt: 'auto',
          px: 6,
          [theme.breakpoints.up('md')]: {
            px: 8,
          },
        }}
      >
        <LanguageSwitch />
      </Box>
    </SwipeableDrawer>
  )
}
