import sOliverCaseRegularWoff2 from '../../../static/font/sOliverCaseWebFonts/sOliverCase-RegularWeb.woff2'
import sOliverCaseRegularWoff from '../../../static/font/sOliverCaseWebFonts/sOliverCase-RegularWeb.woff'
import sOliverCaseMediumWoff2 from '../../../static/font/sOliverCaseWebFonts/sOliverCase-MediumWeb.woff2'
import sOliverCaseMediumWoff from '../../../static/font/sOliverCaseWebFonts/sOliverCase-MediumWeb.woff'

export const sOliverCaseRegular = {
  fontFamily: 'sOliverCase',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${sOliverCaseRegularWoff2}) format('woff2'),
        url(${sOliverCaseRegularWoff}) format('woff')`,
}

export const sOliverCaseMedium = {
  fontFamily: 'sOliverCase',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${sOliverCaseMediumWoff2}) format('woff2'),
        url(${sOliverCaseMediumWoff}) format('woff')`,
}

export const fontFamily = 'sOliverCase, Helvetica, Arial, sans-serif'
