import {
  AnimationControls,
  Target,
  TargetAndTransition,
  Transition,
  Variants,
  VariantLabels,
} from 'framer-motion'
import { ViewportProps } from 'framer-motion/types/motion/features/viewport/types'

type CustomTransitionType = Transition & {
  duration?: number
}

type CustomMotionPropsType = ViewportProps & {
  initial?: boolean | Target | VariantLabels
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean
  exit?: TargetAndTransition | VariantLabels
  enter?: TargetAndTransition | VariantLabels
  whileHover?: TargetAndTransition | VariantLabels
  variants?: Variants
  transition?: CustomTransitionType
}

export const addDelayToWhileInView = (
  whileInViewProps: VariantLabels | TargetAndTransition | undefined,
  delay: number
) => ({
  ...((whileInViewProps as TargetAndTransition) || {}),
  transition: {
    ...((whileInViewProps as TargetAndTransition)?.transition || {}),
    delay: delay,
  },
})
// -------------------------------------------------------------
// PAGE LAYOUT Motion Props
// -------------------------------------------------------------
export const pageMainMotionProps: CustomMotionPropsType = {
  initial: { y: -24 },
  animate: {
    y: 0,
  },
  exit: {
    y: 0,
    transition: { type: 'tween', ease: 'easeIn', duration: 0.6 },
  },
  enter: {
    y: 0,
  },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.6,
    delay: 0.2,
  },
}

export const pageCoverMotionProps: CustomMotionPropsType = {
  initial: { transform: 'scale(1, 1)' },
  animate: {
    transform: 'scale(1, 0)',
  },
  exit: {
    transform: 'scale(1, 1)',
    transition: { type: 'tween', ease: 'easeOut', duration: 0.6 },
  },
  enter: {
    transform: 'scale(1, 0)',
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
    delay: 0.2,
  },
}

export const pageListMotionProps: CustomMotionPropsType = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
  },
}

// -------------------------------------------------------------
// NAVIGATION Motion Props
// -------------------------------------------------------------
export const appBarMotionProps: CustomMotionPropsType = {
  initial: { y: '-100%' },
  variants: {
    hide: {
      y: '-100%',
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.6,
      },
    },
    show: { y: 0 },
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
    delay: 0.15,
  },
}

export const naviFlyoutMotionProps: CustomMotionPropsType = {
  initial: { opacity: 0, pointerEvents: 'none' },
  variants: {
    show: (isFlyoutOpen) => ({
      opacity: 1,
      // y: 0,
      position: 'relative',
      transitionEnd: {
        pointerEvents: 'all',
        opacity: 1,
      },
      transition: {
        type: 'tween',
        ease: 'easeIn',
        duration: 0.25,
        delay: isFlyoutOpen ? 0.25 : 0.05,
      },
    }),
    hide: (isFlyoutOpen) => ({
      opacity: 0,
      transitionEnd: {
        position: 'absolute',
      },
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 0.25,
        delay: !isFlyoutOpen ? 0.5 : 0,
      },
    }),
  },
}

export const naviDimmerMotionProps: CustomMotionPropsType = {
  initial: 'hide',
  variants: {
    show: {
      opacity: 0.5,
      y: 0,
      pointerEvents: 'none',
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.4,
      },
    },
    hide: {
      opacity: 0,
      y: '-100%',
      transitionEnd: {
        pointerEvents: 'none',
      },
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.4,
        delay: 0.3,
      },
    },
  },
}

// -------------------------------------------------------------
// Tab Motion Props
// -------------------------------------------------------------

export const tabContentMotionProps: CustomMotionPropsType = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: { type: 'tween', ease: 'easeOut', duration: 0.2 },
  },
  enter: { opacity: 1 },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.4,
  },
}

// -------------------------------------------------------------
// CAROUSELL Motion Props
// -------------------------------------------------------------

export const carouselContentMotionProps: CustomMotionPropsType = {
  initial: { opacity: 0, scale: 1 },
  animate: { opacity: 1, scale: 1 },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: { type: 'tween', ease: 'easeIn', duration: 0.4 },
  },
  enter: { opacity: 0, scale: 1 },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.4,
    delay: 0.25,
  },
}

// -------------------------------------------------------------
// Image Motion Props
// -------------------------------------------------------------

export const imageControlsMotionProps: CustomMotionPropsType = {
  initial: 'hide',
  variants: {
    show: {
      x: 0,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 0.25,
        delay: 0.25,
      },
    },
    hide: {
      x: '-100%',
      transition: {
        type: 'tween',
        ease: 'easeIn',
        duration: 0.25,
      },
    },
  },
}

// -------------------------------------------------------------
// LIGHTBOX Motion Props
// -------------------------------------------------------------

export const lightboxDimmerMotionProps: CustomMotionPropsType = {
  initial: 'hide',
  variants: {
    show: (opacity: number) => ({
      opacity,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 0.25,
      },
    }),
    hide: {
      opacity: 0,
      transition: {
        type: 'tween',
        ease: 'easeIn',
        duration: 0.25,
      },
    },
  },
}

export const lightboxCloseButtonMotionProps: CustomMotionPropsType = {
  initial: 'hide',
  variants: {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 0.25,
      },
    },
    hide: {
      opacity: 0,
      transition: {
        type: 'tween',
        ease: 'easeIn',
        duration: 0.25,
      },
    },
  },
}

export const lightboxDialogMotionProps: CustomMotionPropsType = {
  initial: { opacity: 0, y: 32 },
  animate: { opacity: 1, y: 0 },
  exit: {
    opacity: 0,
    y: -32,
    transition: { type: 'tween', ease: 'easeIn', duration: 0.4 },
  },
  enter: { opacity: 0, y: 32 },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.5,
  },
}

// -------------------------------------------------------------
// MODULE SECTION Motion Props
// -------------------------------------------------------------

const moduleTransition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.6,
  opacity: { type: 'tween', ease: 'easeOut', duration: 0.2 },
  // delay: 0.25,
}
const moduleInViewport = { once: true, amount: 0.25 }

export const moduleStaggerParentMotionProps: CustomMotionPropsType = {
  initial: 'hide',
  whileInView: 'show',
  variants: {
    show: {
      opacity: 1,
      transition: {
        // when: 'beforeChildren',
        opacity: { type: 'tween', ease: 'easeOut', duration: 0.2 },
        staggerChildren: 0.1,
      },
    },
    hide: {
      opacity: 0,
      transition: {
        // when: 'afterChildren',
      },
    },
  },
  viewport: moduleInViewport,
}

export const moduleStaggerChildUpMotionProps: CustomMotionPropsType = {
  // initial: 'hide',
  variants: {
    show: {
      opacity: 1,
      y: 0,
    },
    hide: {
      opacity: 0,
      y: 16,
    },
  },
  transition: moduleTransition,
}

export const moduleStaggerChildDownMotionProps: CustomMotionPropsType = {
  // initial: 'hide',
  variants: {
    show: {
      opacity: 1,
      y: 0,
    },
    hide: {
      opacity: 0,
      y: 16,
    },
  },
  transition: moduleTransition,
}

export const moduleStaggerChildRightMotionProps: CustomMotionPropsType = {
  variants: {
    show: {
      opacity: 1,
      x: 0,
    },
    hide: {
      opacity: 0,
      x: -16,
    },
  },
  transition: moduleTransition,
}

export const moduleStaggerChildLeftMotionProps: CustomMotionPropsType = {
  variants: {
    show: {
      opacity: 1,
      x: 0,
    },
    hide: {
      opacity: 0,
      x: 16,
    },
  },
  transition: moduleTransition,
}

export const moduleSlideUpMotionProps: CustomMotionPropsType = {
  initial: {
    opacity: 0,
    y: 16,
  },
  whileInView: {
    opacity: 1,
    y: 0,
    transition: moduleTransition,
  },
  viewport: moduleInViewport,
}

export const moduleSlideDownMotionProps: CustomMotionPropsType = {
  initial: {
    opacity: 0,
    y: -16,
  },
  whileInView: {
    opacity: 1,
    y: 0,
    transition: moduleTransition,
  },
  viewport: moduleInViewport,
}

export const moduleRotateInMotionProps: CustomMotionPropsType = {
  initial: {
    opacity: 0,
    y: '50%',
    transformOrigin: 'left bottom',
    rotate: '4deg',
  },
  whileInView: {
    opacity: 1,
    y: 0,
    rotate: 0,
    transition: moduleTransition,
  },
  viewport: moduleInViewport,
}

export const moduleSlideLeftMotionProps: CustomMotionPropsType = {
  initial: {
    opacity: 0,
    x: 16,
  },
  whileInView: {
    opacity: 1,
    x: 0,
    transition: moduleTransition,
  },
  viewport: moduleInViewport,
}

export const moduleSlideRightMotionProps: CustomMotionPropsType = {
  initial: { opacity: 0, x: -16 },
  whileInView: {
    opacity: 1,
    x: 0,
    transition: moduleTransition,
  },
  viewport: moduleInViewport,
}

// -------------------------------------------------------------
// BRANDWALL Motion Props
//

export const brandwallItemMotionProps: CustomMotionPropsType = {
  initial: 'hide',
  whileHover: 'show',
  variants: {
    show: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
    hide: {},
  },
}

export const brandwallItemImageMotionProps: CustomMotionPropsType = {
  variants: {
    show: {
      scale: 1.1,
    },
    hide: {
      scale: 1,
    },
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
  },
}
export const brandwallItemIconMotionProps: CustomMotionPropsType = {
  variants: {
    show: {
      scale: 1.2,
    },
    hide: {
      scale: 1,
    },
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
  },
}

// -------------------------------------------------------------
// STAGE Motion Props
// -------------------------------------------------------------

export const stageLogoMotionProps: CustomMotionPropsType = {
  initial: { opacity: 1, scale: 1.5, translateX: '-50%', translateY: '-50%' },
  transition: {
    duration: 0.4,
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { opacity: 0, scale: 1 },
    default: { opacity: 1, scale: 1, transition: { duration: 3 } },
  },
}

const delay = (pageCoverMotionProps.transition?.delay || 0) + 0.05
export const stageHeadlineMotionProps: CustomMotionPropsType = {
  initial: 'default',
  transition: {
    duration: 0.5,
    delay: delay,
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { y: '0', rotate: '0deg' },
    default: { y: '100%', rotate: '20deg', transformOrigin: 'left bottom' },
  },
}

export const stageHeadlineCustomMotionProps: CustomMotionPropsType = {
  initial: 'default',
  transition: {
    duration: 0.5,
    delay: delay,
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { y: '0' },
    default: { y: '100%' },
  },
}

export const stageButtonMotionProps: CustomMotionPropsType = {
  initial: 'default',
  transition: {
    duration: 0.5,
    delay: delay + (stageHeadlineMotionProps.transition?.duration || 0),
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { y: '0', rotate: 0 },
    default: { y: '100%', transformOrigin: 'left top', rotate: '4deg' },
  },
}

export const stageImageMotionProps: CustomMotionPropsType = {
  initial: 'default',
  transition: {
    duration: 0.65,
    delay: delay + (stageHeadlineMotionProps.transition?.duration || 0) - 0.3,
    opacity: { type: 'tween', ease: 'linear', duration: 0.2 },
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { x: 0, opacity: 1, rotateY: '0deg', visibility: 'visible' },
    default: (slideLeft: boolean) => ({
      x: slideLeft ? '25%' : '-25%',
      rotateY: slideLeft ? '10deg' : '-10deg',
      opacity: 0,
      // rotateY: '-90deg',
      transformOrigin: slideLeft ? 'left center' : 'right center',
      transitionEnd: {
        visibility: 'hidden',
      },
    }),
  },
}

export const stageTeaserMotionProps: CustomMotionPropsType = {
  initial: {
    y: '100%',
    rotate: '4deg',
    transformOrigin: 'left top',
  },
  transition: {
    duration: 0.55,
    delay:
      (stageImageMotionProps.transition?.delay || 0) +
      (stageImageMotionProps.transition?.duration || 0) -
      0.45,
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { y: 0, rotate: 0 },
    default: {
      y: '100%',
    },
  },
}

export const stageFadeInMotionProps: CustomMotionPropsType = {
  initial: 'default',
  transition: {
    duration: 0.5,
    delay:
      (stageImageMotionProps.transition?.delay || 0) +
      (stageImageMotionProps.transition?.duration || 0) -
      0.1,
    ease: 'easeOut',
    type: 'tween',
  },
  variants: {
    ready: { opacity: 1 },
    default: { opacity: 0 },
  },
}

export const stageScrollToLineMotionProps: CustomMotionPropsType = {
  initial: 'default',
  transition: {
    duration: 0.75,
    ease: 'easeOut',
    type: 'tween',
    repeat: Infinity,
    repeatType: 'mirror',
  },
  variants: {
    ready: {
      height: '32px',
    },
    default: { height: '10px' },
  },
}
