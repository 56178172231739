import CollapsableContainer from '@components/core/container/collapsableContainer'
import Headline from '@components/core/text/headline'
import { Box, Theme, useMediaQuery } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../button'
import Chip from '../chip'

export type FilterProps = SOG.Props.IDefault &
  SOG.Props.PressFilterValues & {
    type?: 'articleDetail' | 'media'
    initialTopics?: SOG.Props.IGlobalTopic[]
    initialMediums?: SOG.Props.IGlobalMedium[]
    initialBrands?: SOG.Props.IGlobalBrand[]
    initialTargetGroups?: SOG.Props.IGlobalTargetGroup[]
    initialFormats?: string[]
    onChange: (values: SOG.Props.PressFilterValues) => void
  }

export default function Filter({
  sx,
  topics,
  mediums,
  brands,
  targetGroups,
  formats,
  initialTopics = [],
  initialBrands = [],
  initialMediums = [],
  initialTargetGroups = [],
  initialFormats = [],
  onChange,
}: FilterProps): ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedTopics, setSelectedTopics] = useState<number[]>(
    initialTopics.map((entry1) =>
      topics.findIndex((entry2) => entry1.id === entry2.id)
    )
  )
  const [selectedMediums, setSelectedMediums] = useState<number[]>(
    initialMediums.map((entry1) =>
      mediums.findIndex((entry2) => entry1.id === entry2.id)
    )
  )
  const [selectedBrands, setSelectedBrands] = useState<number[]>(
    initialBrands.map((entry1) =>
      brands.findIndex((entry2) => entry1.id === entry2.id)
    )
  )
  const [selectedTargetGroups, setSelectedTargetGroups] = useState<number[]>(
    initialTargetGroups.map((entry1) =>
      targetGroups.findIndex((entry2) => entry1.id === entry2.id)
    )
  )
  const [selectedFormats, setSelectedFormats] = useState<number[]>(
    initialFormats.map((entry1) =>
      formats.findIndex((entry2) => entry1 === entry2)
    )
  )

  const changeHandler = () => {
    onChange({
      topics: selectedTopics.map((entryIndex) => topics[entryIndex]),
      brands: selectedBrands.map((entryIndex) => brands[entryIndex]),
      mediums: selectedMediums.map((entryIndex) => mediums[entryIndex]),
      targetGroups: selectedTargetGroups.map(
        (entryIndex) => targetGroups[entryIndex]
      ),
      formats: selectedFormats.map((entryIndex) => formats[entryIndex]),
    })
  }

  const changeTopicsHandler = (index: number) => {
    if (selectedTopics.includes(index)) {
      setSelectedTopics(
        selectedTopics.filter((entryIndex) => index !== entryIndex)
      )
      changeHandler()
      return
    }
    setSelectedTopics([...selectedTopics, index])
    changeHandler()
  }

  const changeMediumsHandler = (index: number) => {
    if (selectedMediums.includes(index)) {
      setSelectedMediums(
        selectedMediums.filter((entryIndex) => index !== entryIndex)
      )
      changeHandler()
      return
    }
    setSelectedMediums([...selectedMediums, index])
    changeHandler()
  }

  const changeBrandsHandler = (index: number) => {
    if (selectedBrands.includes(index)) {
      setSelectedBrands(
        selectedBrands.filter((entryIndex) => index !== entryIndex)
      )
      changeHandler()
      return
    }
    setSelectedBrands([...selectedBrands, index])
    changeHandler()
  }

  const changeTargetGroupsHandler = (index: number) => {
    if (selectedTargetGroups.includes(index)) {
      setSelectedTargetGroups(
        selectedTargetGroups.filter((entryIndex) => index !== entryIndex)
      )
      changeHandler()
      return
    }
    setSelectedTargetGroups([...selectedTargetGroups, index])
    changeHandler()
  }

  const changeFormatsHandler = (index: number) => {
    if (selectedFormats.includes(index)) {
      setSelectedFormats(
        selectedFormats.filter((entryIndex) => index !== entryIndex)
      )
      changeHandler()
      return
    }
    setSelectedFormats([...selectedFormats, index])
    changeHandler()
  }

  useEffect(() => {
    setSelectedTopics(
      initialTopics.map((entry1) =>
        topics.findIndex((entry2) => entry1.id === entry2.id)
      )
    )
    setSelectedMediums(
      initialMediums.map((entry1) =>
        mediums.findIndex((entry2) => entry1.id === entry2.id)
      )
    )
    setSelectedBrands(
      initialBrands.map((entry1) =>
        brands.findIndex((entry2) => entry1.id === entry2.id)
      )
    )
    setSelectedTargetGroups(
      initialTargetGroups.map((entry1) =>
        targetGroups.findIndex((entry2) => entry1.id === entry2.id)
      )
    )
  }, [])

  useEffect(() => {
    onChange({
      topics: selectedTopics.map((entryIndex) => topics[entryIndex]),
      mediums: selectedMediums.map((entryIndex) => mediums[entryIndex]),
      brands: selectedBrands.map((entryIndex) => brands[entryIndex]),
      targetGroups: selectedTargetGroups.map(
        (entryIndex) => targetGroups[entryIndex]
      ),
      formats: selectedFormats.map((entryIndex) => formats[entryIndex]),
    })
  }, [
    selectedTopics,
    selectedMediums,
    selectedBrands,
    selectedTargetGroups,
    selectedFormats,
  ])

  const hasFilterSelected =
    selectedTopics.length > 0 ||
    selectedMediums.length > 0 ||
    selectedBrands.length > 0 ||
    selectedTargetGroups.length > 0 ||
    selectedFormats.length > 0

  return (
    <Box sx={sx}>
      {isMobile && (
        <Headline component="p" variant="h4" sx={{ mb: 4 }}>
          <FormattedMessage id="label.filterNews" />
        </Headline>
      )}

      {!!hasFilterSelected && isMobile && (
        <Button
          variant="text"
          onClick={() => {
            setSelectedTopics([])
            setSelectedBrands([])
            setSelectedMediums([])
            setSelectedTargetGroups([])
            setSelectedFormats([])
          }}
          sx={{
            mb: 4,
            '& span': {
              fontSize: '16px',
            },

            '& .MuiButton-endIcon': {
              display: 'none',
            },
          }}
        >
          <FormattedMessage id="filter.removeAll" />
        </Button>
      )}

      <Box>
        {topics.length > 0 && (
          <Box>
            <Headline component="p" variant="h6" sx={{ mb: 6 }}>
              <FormattedMessage id="label.topics" />
            </Headline>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {topics.map((entry, index) => (
                <Chip
                  label={entry.title}
                  key={index}
                  selected={selectedTopics.includes(index)}
                  onClick={() => changeTopicsHandler(index)}
                  onDelete={() => changeTopicsHandler(index)}
                  size={isMobile ? 'small' : 'medium'}
                  sx={(theme) => ({
                    mr: 2,
                    mb: 2,
                    [theme.breakpoints.down('md')]: {
                      '&.Mui-selected': {
                        padding: '0px 6px',
                      },
                    },
                    '& .MuiChip-deleteIcon': {
                      '&:hover': { color: 'white' },
                    },
                  })}
                />
              ))}
            </Box>
          </Box>
        )}
        <CollapsableContainer isOpen={isMobile || isOpen}>
          <Box sx={{ pt: 6, '> * + *': { mt: 8 } }}>
            {brands.length > 0 && (
              <Box>
                <Headline component="p" variant="h6" sx={{ mb: 4 }}>
                  <FormattedMessage id="label.brands" />
                </Headline>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {brands.map((entry, index) => (
                    <Chip
                      label={entry.name}
                      key={index}
                      selected={selectedBrands.includes(index)}
                      onClick={() => changeBrandsHandler(index)}
                      onDelete={() => changeBrandsHandler(index)}
                      size={isMobile ? 'small' : 'medium'}
                      sx={(theme) => ({
                        mr: 2,
                        mb: 2,
                        [theme.breakpoints.down('md')]: {
                          '&.Mui-selected': {
                            padding: '0px 6px',
                          },
                        },
                        '& .MuiChip-deleteIcon': {
                          '&:hover': { color: 'white' },
                        },
                      })}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {mediums.length > 0 && (
              <Box>
                <Headline component="p" variant="h6" sx={{ mb: 6 }}>
                  <FormattedMessage id="label.mediums" />
                </Headline>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {mediums.map((entry, index) => (
                    <Chip
                      label={entry.title}
                      key={index}
                      selected={selectedMediums.includes(index)}
                      onClick={() => changeMediumsHandler(index)}
                      onDelete={() => changeMediumsHandler(index)}
                      size={isMobile ? 'small' : 'medium'}
                      sx={(theme) => ({
                        mr: 2,
                        mb: 2,
                        [theme.breakpoints.down('md')]: {
                          '&.Mui-selected': {
                            padding: '0px 6px',
                          },
                        },
                        '& .MuiChip-deleteIcon': {
                          '&:hover': { color: 'white' },
                        },
                      })}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {targetGroups.length > 0 && (
              <Box>
                <Headline component="p" variant="h7" sx={{ mb: 4 }}>
                  <FormattedMessage id="label.targetGroups" />
                </Headline>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {targetGroups.map((entry, index) => (
                    <Chip
                      label={entry.title}
                      key={index}
                      selected={selectedTargetGroups.includes(index)}
                      onClick={() => changeTargetGroupsHandler(index)}
                      onDelete={() => changeTargetGroupsHandler(index)}
                      size="small"
                      sx={(theme) => ({
                        mr: 2,
                        mb: 2,
                        [theme.breakpoints.down('md')]: {
                          '&.Mui-selected': {
                            padding: '0px 6px',
                          },
                        },
                        '& .MuiChip-deleteIcon': {
                          '&:hover': { color: 'white' },
                        },
                      })}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {formats.length > 0 && (
              <Box>
                <Headline component="p" variant="h7" sx={{ mb: 4 }}>
                  <FormattedMessage id="label.formats" />
                </Headline>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {formats.map((entry, index) => (
                    <Chip
                      label={entry}
                      key={index}
                      selected={selectedFormats.includes(index)}
                      onClick={() => changeFormatsHandler(index)}
                      onDelete={() => changeFormatsHandler(index)}
                      size="small"
                      sx={(theme) => ({
                        mr: 2,
                        mb: 2,
                        [theme.breakpoints.down('md')]: {
                          '&.Mui-selected': {
                            padding: '0px 6px',
                          },
                        },
                        '& .MuiChip-deleteIcon': {
                          '&:hover': { color: 'white' },
                        },
                      })}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </CollapsableContainer>
        <Box sx={{ mt: 4 }}>
          {!isMobile && (
            <Button
              variant="text"
              icon={isOpen ? 'ChevronDown' : 'ChevronUp'}
              sx={{ mr: 4 }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <FormattedMessage
                id={isOpen ? 'filter.showLess' : 'filter.showMore'}
              />
            </Button>
          )}

          {!!hasFilterSelected && !isMobile && (
            <Button
              variant="text"
              onClick={() => {
                setSelectedTopics([])
                setSelectedBrands([])
                setSelectedMediums([])
                setSelectedTargetGroups([])
                setSelectedFormats([])
              }}
              sx={{
                marginLeft: '16px',
                '& .MuiButton-endIcon': {
                  display: 'none',
                },
              }}
            >
              <FormattedMessage id="filter.removeAll" />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
