import { createTheme } from '@mui/material'

import { TypographyOverrides } from './overrides/typography'
import { PaletteOverrides } from './overrides/palette'
import { ComponentOverrides } from './overrides/components'
import Spacing from './definitions/spacing'
import Breakpoints from './definitions/breakpoints'
import Mixins from './mixins'
import FontSize from './definitions/fontSize'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Bold: true
  }
}

const theme = createTheme({
  spacing: Spacing.unit,
  breakpoints: Breakpoints,
  fontSize: FontSize,
  gutter: (breakpoint) => Spacing.gutters[breakpoint],
  maxWidth: (breakpoint) => Spacing.maxWidth[breakpoint],
})

// Palette Overrides
theme.palette = {
  ...theme.palette,
  ...PaletteOverrides(theme),
}

// Typography Overrides
theme.typography = {
  ...theme.typography,
  ...TypographyOverrides(theme),
}

theme.mixins = Mixins(theme)
// Component Overrides
theme.components = ComponentOverrides(theme)

export default theme
