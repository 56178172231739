import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Theme } from '@mui/material'
import FontSize from '../definitions/fontSize'
import { fontFamily } from '../definitions/fontFamily'

/**
 * Set which custom type can be used as a variant prop
 * See: More Variants in ../type/createTypography
 * */
declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    small: true
    smallBold: true
    smallFixed: true
    base: true
    baseBold: true
    footer: true
    captionCaps: true
  }
}

export const TypographyOverrides = (theme: Theme): TypographyOptions => {
  return {
    ...theme.typography,
    fontFamily: fontFamily,
    body1: {
      fontFamily: fontFamily,
      fontSize: FontSize.get('base'),
      lineHeight: FontSize.get('xl'),
      letterSpacing: '0.3px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'),
        lineHeight: '30px',
        letterSpacing: '0.3px',
      },
    },
    body2: {
      fontFamily: fontFamily,
      fontSize: FontSize.get('base'),
      lineHeight: FontSize.get('xl'),
      letterSpacing: '0.3px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'),
        lineHeight: '30px',
        letterSpacing: '0.3px',
      },
    },
    h1: {
      fontSize: FontSize.get('4xl'),
      lineHeight: '60px',
      letterSpacing: '0.2px',
      fontWeight: 400,
      [theme.breakpoints.up('sm')]: {
        fontSize: '80px',
        lineHeight: FontSize.get('6xl'),
        letterSpacing: '0.2px',
      },
    },
    h2: {
      fontSize: '34px',
      lineHeight: '46px',
      letterSpacing: '0.5px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'),
        lineHeight: '60px',
        letterSpacing: '0.5px',
      },
    },
    h3: {
      fontSize: '34px',
      lineHeight: '46px',
      letterSpacing: '0.5px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'),
        lineHeight: '60px',
        letterSpacing: '0.5px',
      },
    },
    h4: {
      fontSize: FontSize.get('3xl'),
      lineHeight: '52px',
      letterSpacing: '0.5px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'),
        lineHeight: '60px',
        letterSpacing: '0.5px',
      },
    },
    h5: {
      fontSize: FontSize.get('3lg'),
      lineHeight: FontSize.get('2xl'),
      letterSpacing: '0.3px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('xl'),
        lineHeight: '36px',
        letterSpacing: '0.3px',
      },
    },
    h6: {
      fontSize: FontSize.get('3lg'),
      lineHeight: FontSize.get('2xl'),
      letterSpacing: '0.3px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('xl'),
        lineHeight: '36px',
        letterSpacing: '0.3px',
      },
    },
    h7: {
      fontSize: FontSize.get('3lg'),
      lineHeight: FontSize.get('2xl'),
      letterSpacing: '0.3px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('xl'),
        lineHeight: '36px',
        letterSpacing: '0.3px',
      },
    },
    textLarge: {
      fontSize: FontSize.get('4xl'),
      lineHeight: 1.2,
    },
    quote: {
      fontSize: FontSize.get('3xl'),
      lineHeight: '52px',
      letterSpacing: '0.5px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'),
        lineHeight: '60px',
        letterSpacing: '0.5px',
      },
    },
    quoteSmall: {
      fontSize: FontSize.get('xl'),
      lineHeight: 1.2,
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('3xl'),
        lineHeight: 1.3,
      },
    },
    subline: {
      fontSize: FontSize.get('3lg'),
      lineHeight: '32px',
      letterSpacing: '0.5px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('xl'),
        lineHeight: '36px',
        letterSpacing: '0.3px',
      },
    },
    caption: {
      fontSize: FontSize.get('base'),
      lineHeight: FontSize.get('xl'),
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    captionCaps: {
      fontSize: FontSize.get('sm'),
      lineHeight: '21px',
      textTransform: 'uppercase',
      letterSpacing: '1.9px',
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        lineHeight: FontSize.get('xl'),
        fontSize: FontSize.get('base'),
      },
    },
    function: {
      fontSize: FontSize.get('base'),
      lineHeight: FontSize.get('xl'),
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
    },
    navigation: {
      fontSize: FontSize.get('sm'),
      lineHeight: 1.3334,
    },
    small: {
      fontSize: FontSize.get('sm'),
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0.3,
      lineHeight: FontSize.get('sm'),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('base'),
        lineHeight: 1.75,
        letterSpacing: 0.3,
      },
    },
    smallBold: {
      fontSize: FontSize.get('sm'),
      textDecoration: 'none',
      fontWeight: 400,
      lineHeight: FontSize.get('sm'),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('base'),
        lineHeight: FontSize.get('base'),
        letterSpacing: 0.3,
      },
    },
    smallFixed: {
      fontSize: FontSize.get('sm'),
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0.3,
      lineHeight: FontSize.get('sm'),
    },
    base: {
      fontSize: FontSize.get('base'),
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0.3,
      lineHeight: FontSize.get('base'),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'),
        lineHeight: FontSize.get('lg'),
      },
    },
    baseBold: {
      fontSize: FontSize.get('base'),
      textDecoration: 'none',
      fontWeight: 400,
      letterSpacing: 0.3,
      lineHeight: FontSize.get('base'),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'),
        lineHeight: FontSize.get('lg'),
      },
    },
    footer: {
      textDecoration: 'none',
      fontWeight: 400,
      lineHeight: '21px',
      textTransform: 'uppercase',
      letterSpacing: 0.3,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('sm'),
        lineHeight: FontSize.get('xl'),
      },
    },
  }
}
