import { Components, Theme } from '@mui/material'

import {
  sOliverCaseRegular,
  sOliverCaseMedium,
} from '../../definitions/fontFamily'

export const CssBaselineOverrides = (
  theme: Theme
): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    '@font-face': sOliverCaseRegular,
    fallbacks: [
      {
        '@font-face': sOliverCaseMedium,
      },
    ],
    a: {
      color: 'currentColor',
      fontWeight: 500,
    },
    strong: {
      fontWeight: 500,
    },
    html: {
      scrollBehavior: 'smooth',
    },
    'body.Mui-noScroll': {
      height: '100%',
      overflow: 'hidden',
    },
  },
})
