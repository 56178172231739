import React, { ReactElement } from 'react'
import { Radio as FormRadio, FormControlLabel } from '@mui/material'
import clsx from 'clsx'

export type RadioProps = SOG.Props.IDefault & {
  value?: string | undefined
  name?: string
  error?: boolean | string
  disabled?: boolean | false
  checked?: boolean
  id?: string
  label: string
}

export default function Radio({
  name,
  error,
  sx = {},
  ...props
}: RadioProps): ReactElement {
  return (
    <>
      <FormControlLabel
        sx={{
          ...sx,
          color: '#1e1e1e',
        }}
        control={
          <FormRadio
            sx={{
              color: '#1e1e1e',
              '&.Mui-checked': {
                color: '#1e1e1e',
              },
              '& .MuiSvgIcon-root': {
                fontSize: 20,
              },
            }}
            data-testid="radio"
            className={clsx({
              error: error,
            })}
            name={name}
          />
        }
        {...props}
      />
    </>
  )
}
