import React from 'react'
import { Box, styled } from '@mui/material'

import Image from '@components/core/media/image'
import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import { FormattedMessage } from 'react-intl'
import { motion } from 'framer-motion'
import {
  moduleSlideLeftMotionProps,
  moduleSlideRightMotionProps,
} from '@utils/animation'

export type TeaserContentProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    copy: SOG.Props.RichText
    image: SOG.Props.Image
    linkTo?: SOG.Props.LinkTo
    whiteOrGreyBackground?: boolean
  }

const FlexWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
  marginBottom: '10px',
}))

export default function TeaserContent({
  contentful_id,
  caption,
  headline,
  copy,
  image,
  linkTo,
  whiteOrGreyBackground = false,
}: TeaserContentProps): React.ReactElement {
  return (
    <Section id={contentful_id} sx={{ overflowX: 'hidden' }} maxWidth="xl">
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlexWrapper>
          <Image
            component={motion.div}
            {...moduleSlideRightMotionProps}
            {...image}
            sx={(theme) => ({
              width: '100%',
              aspectRatio: '3/2',
              [theme.breakpoints.up('lg')]: {
                width: '40vw',
                marginTop: !!whiteOrGreyBackground && '72px',
              },
            })}
          />

          <Box
            component={motion.div}
            {...moduleSlideLeftMotionProps}
            sx={(sxTheme) => ({
              position: 'relative',
              background:
                whiteOrGreyBackground === true
                  ? sxTheme.palette.common.white
                  : sxTheme.palette.background.light,
              padding: !whiteOrGreyBackground
                ? sxTheme.spacing(10)
                : sxTheme.spacing(10, 0),
              [sxTheme.breakpoints.up('lg')]: {
                padding: '64px 100px',
                width: '50vw',
              },
              [sxTheme.breakpoints.down('lg')]: {
                display: 'contents',
              },
            })}
          >
            {caption && (
              <Headline
                variant="caption"
                sx={(sxTheme) => ({
                  display: 'inline-block',
                  mb: 2,
                  color: 'background.medium',
                  [sxTheme.breakpoints.up('md')]: {
                    mb: 4,
                  },
                  [sxTheme.breakpoints.down('lg')]: {
                    paddingTop: '40px',
                  },
                })}
              >
                {caption}
              </Headline>
            )}

            {headline && (
              <Headline
                variant="h3"
                component="p"
                sx={(theme) => ({
                  fontWeight: 'regular',
                  mb: 4,
                  [theme.breakpoints.up('lg')]: {
                    mb: 8,
                  },
                  [theme.breakpoints.down('lg')]: {
                    hyphens: 'auto',
                  },
                })}
              >
                {headline}
              </Headline>
            )}
            <Copy variant="body1">{copy}</Copy>
            {linkTo && (
              <motion.div {...moduleSlideLeftMotionProps}>
                <Button
                  sx={{ margin: '32px 32px 0 0' }}
                  variant="primary"
                  to={linkTo.url}
                  isExternal={linkTo.isExternal}
                >
                  <FormattedMessage id="button.learnMore" />
                </Button>
              </motion.div>
            )}
          </Box>
        </FlexWrapper>
      </Box>
    </Section>
  )
}
