import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  KeyboardEvent,
} from 'react'
import { Box } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import Search from './horizontalSearch'

interface IHorizontalNav {
  items: SOG.Props.LinkTo[]
  onOpen: (open: boolean) => void
}

export default function HorizontalSearch({
  items,
  onOpen,
}: IHorizontalNav): ReactElement {
  const CompRef = useRef<HTMLElement>(null)
  const [focusIndex, setFocusIndex] = useState(-1)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    onOpen(activeIndex !== -1)
  }, [activeIndex])

  useEffect(() => {
    onOpen(isOpen !== false)
  }, [isOpen])

  function handleClickOutside() {
    setFocusIndex(-1)
    setActiveIndex(-1)
    setIsOpen(false)
  }

  // function handleKeyDown(e: KeyboardEvent) {
  //   const curIndex = focusIndex === -1 ? 0 : focusIndex
  //   if (e.key === 'ArrowRight') {
  //     setFocusIndex((curIndex + 1) % items.length)
  //   } else if (e.key === 'ArrowLeft') {
  //     setFocusIndex(curIndex === 0 ? items.length - 1 : curIndex - 1)
  //   }
  // }

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleClickOutside()
      }}
    >
      <Box
        component="div"
        ref={CompRef}
        sx={(theme) => ({
          marginTop: '18px',
          position: 'relative',
          '&:after': {
            content: `''`,
            position: 'absolute',
            left: 0,
            bottom: '-1px',
            width: '0%',
            height: theme.spacing(1),
            backgroundColor: theme.palette.grey[900],
            transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
            ...(isOpen === true && {
              width: '100%',
            }),
          },
          '&:hover, :focus > &': {
            '&:after': {
              width: '100%',
            },
          },
        })}
      >
        <Box
          data-testid="search"
          role="menubar"
          aria-label="Hauptnavigation"
          // onKeyDown={handleKeyDown}
          sx={{
            listStyle: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
          }}
        >
          <Search
            key="search"
            isOpen={isOpen}
            isFocused={!!focusIndex}
            toggleMenu={() => {
              setIsOpen(!isOpen)
            }}
          />
        </Box>
      </Box>
    </ClickAwayListener>
  )
}
