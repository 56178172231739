import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefObject,
  useEffect,
  useState,
} from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import { Box } from '@mui/material'

export type TextInputProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    label?: string
    placeholder: string | 'Placeholder'
    id: string
    error?: string | boolean | false
    errorText?: string
    type?: string | 'text'
    initialValue?: string | 'text'
    defaultValue?: string | 'text'
    multiline?: boolean
    rows?: string | number
    maxRows?: string | number
    outlined?: boolean
  }

const TextInput = forwardRef(function TextInput(
  {
    label,
    placeholder,
    id,
    type,
    multiline,
    rows,
    initialValue = '',
    minRows,
    maxRows,
    error,
    errorText = '',
    required,
    onChange,
    variant = 'filled',

    sx,
    ...props
  }: TextInputProps,
  ref: ForwardedRef<HTMLDivElement> | undefined
): ReactElement {
  const [value, setValue] = useState(initialValue || '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <Box sx={sx}>
      {label && (
        <InputLabel
          htmlFor={id}
          error={error}
          sx={
            error === true
              ? { color: 'primary.main' }
              : { color: 'text.primary' }
          }
          required={required}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        ref={ref}
        variant={variant}
        sx={{
          height: '100%',
          '& .MuiFilledInput-root:before': {
            borderColor: error === true ? 'primary.main' : '#AAAAAA',
          },
          '& .MuiFilledInput-root': {
            height: '100%',
          },
          '&:after': {
            borderColor: error === true ? 'primary.main' : '#1E1E1E',
          },
          '& .MuiFormHelperText-root': {
            fontSize: '12px !important',
            textTransform: 'unset',
          },
        }}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        data-testid="input"
        id={id}
        type={type}
        multiline={multiline}
        inputProps={{
          style: type === 'date' ? { textTransform: 'uppercase' } : {},
        }}
        error={error}
        helperText={error === true ? errorText : undefined}
        required={required}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        {...props}
      />
    </Box>
  )
})

export default TextInput
