import { Components, Theme } from '@mui/material'

export const PaginationOverrides = (
  theme: Theme
): Components['MuiPagination'] => ({
  defaultProps: {},
  styleOverrides: {
    root: { alignItems: 'center', justifyContent: 'center' },
    ul: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})

export const PaginationItemOverrides = (
  theme: Theme
): Components['MuiPaginationItem'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {},
    rounded: {
      borderRadius: 0,
      width: '56px',
      height: '56px',
      '&.Mui-selected, &.Mui-selected:hover, :hover': {
        color: theme.palette.background.medium,
        border: `2px solid ${theme.palette.background.medium}`,
        backgroundColor: theme.palette.common.white,
      },
      fontWeight: 500,
    },
    previousNext: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.background.medium,
      ':hover': {
        color: theme.palette.background.medium,
        border: `2px solid ${theme.palette.background.medium}`,
        backgroundColor: theme.palette.common.white,
      },
    },
  },
})
