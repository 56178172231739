import React, { ReactElement } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import { HistoryLocation } from '@reach/router'
import { FormattedMessage } from 'react-intl'

import Link from '@components/core/ui/link'
import Accordion from '@components/core/ui/accordion'
import Button from '@components/core/ui/button'
import Icon from '@components/core/ui/icon'
import RelatedContentTeaser from '@components/modules/relatedContentTeaser'
import useAccordionControls from '@components/system/hooks/useAccordionControls'
import LanguageSwitch from '@components/core/ui/languageSwitch'

import IconLogo from '@static/img/sog-logo.svg'
import { PageState } from '@components/core/container/layout'

export type FooterProps = SOG.Props.IDefault & {
  navigationItems: SOG.Props.LinkTo[]
  metaNavigationItems: SOG.Props.LinkTo[]
  brandItems: SOG.Props.IGlobalBrand[]
  relatedPages?: SOG.Props.IPageRelated[]
  contactFormPage?: SOG.Props.LinkTo
  location?: HistoryLocation
  currentPageState: PageState
}

export default function Footer({
  navigationItems,
  metaNavigationItems,
  relatedPages,
  contactFormPage,
  brandItems,
  currentPageState,
}: FooterProps): ReactElement {
  const [activeAccordionId, handleAccordionChange] = useAccordionControls(false)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const renderNavBlock = (item: SOG.Props.LinkTo) => {
    if (!!item.hideInNavigation) return
    return (
      <>
        <Link fontWeight="bold" to={item.url} isExternal={item.isExternal}>
          {item.label}
        </Link>
        {item.children &&
          item.children.map((childItem, childIndex: number) => (
            <Link
              to={childItem.url}
              fontSize="small"
              isExternal={childItem.isExternal}
              sx={{ mt: 4 }}
              key={childIndex}
            >
              {childItem.label}
            </Link>
          ))}
      </>
    )
  }

  const renderNavAccordion = (item: SOG.Props.LinkTo, index: number) => {
    if (!!item.hideInNavigation) return
    return (
      <Accordion
        title={item.label as string}
        expanded={activeAccordionId === index}
        onChange={(event, isExpanded) =>
          handleAccordionChange(index, isExpanded)
        }
        variant="outlined"
        key={index}
        theme={item.theme}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > * + *': {
              mt: 3,
            },
          }}
        >
          <Link fontSize="small" to={item.url} isExternal={item.isExternal}>
            <FormattedMessage id="button.overview" />
          </Link>
          {item.children &&
            item.children.map((childItem, childIndex) => (
              <Link
                fontSize="small"
                to={childItem.url}
                isExternal={childItem.isExternal}
                key={childIndex}
              >
                {childItem.label}
              </Link>
            ))}
        </Box>
      </Accordion>
    )
  }

  const socialItems: {
    icon: 'Instagram' | 'LinkedIn' | 'Xing'
    link: string
  }[] = [
    {
      icon: 'Instagram',
      link: 'https://www.instagram.com/inside_solivergroup',
    },
    {
      icon: 'LinkedIn',
      link: 'https://www.linkedin.com/company/s-oliver-group',
    },
    { icon: 'Xing', link: 'https://www.xing.com/pages/soliver' },
  ]

  const mainColumns = 6
  const homeItem = navigationItems[0]
  const mainItems = navigationItems.slice(1)

  return (
    <Box component="footer" sx={() => ({ color: 'text.inverted' })}>
      {relatedPages && (
        <RelatedContentTeaser
          currentPageState={currentPageState}
          entries={relatedPages}
        />
      )}
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          pt: 2.5,
          pb: 2.5,
          bgcolor: 'background.secondary',
          [theme.breakpoints.up('lg')]: {
            pt: 6.25,
            pb: 6.25,
          },
        })}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {brandItems.map(({ icon, linkTo }, index) => (
              <Grid
                item
                xs={6}
                lg
                display="flex"
                justifyContent="center"
                key={index}
              >
                <IconButton
                  component={GatsbyLink}
                  to={linkTo?.url}
                  sx={{ fontSize: '50px', width: '100%', borderRadius: 0 }}
                  color="inherit"
                  size="small"
                >
                  <Box
                    sx={{
                      width: '2.2em',
                      display: 'inline-block',
                      justifyContent: 'center',
                    }}
                    dangerouslySetInnerHTML={{ __html: icon?.content }}
                  />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          bgcolor: 'background.light',
          color: 'common.black',
        }}
      >
        <Container sx={{ pt: 20, pb: 20, maxWidth: '1168px' }} maxWidth="lg">
          <Grid container spacing={0} rowSpacing={{ xs: 12, lg: 20 }}>
            <Grid
              item
              xs={12}
              lg={3}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                [theme.breakpoints.up('lg')]: {
                  mr: 'auto',
                  alignItems: 'flex-start',
                },
              })}
            >
              <IconButton
                component={GatsbyLink}
                to={homeItem.url}
                color="inherit"
                sx={{ borderRadius: 0 }}
              >
                <IconLogo width="227px" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                [theme.breakpoints.up('lg')]: {
                  alignItems: 'flex-start',
                  order: 4,
                  mr: 'auto',
                },
              })}
            >
              <Typography variant="footer" sx={{ mb: 4 }}>
                <FormattedMessage id="label.follow" />
              </Typography>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up('lg')]: {
                    ml: -2,
                  },
                })}
              >
                {socialItems.map(({ icon, link }, index) => (
                  <IconButton color="inherit" key={index}>
                    <Link to={link} isExternal>
                      <Icon name={icon} fontSize="large" />
                    </Link>
                  </IconButton>
                ))}
              </Box>
            </Grid>
            {!isMobile &&
              [...Array(mainColumns)].map((x, index) => (
                <Grid
                  item
                  xs={3}
                  lg={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    order: index,
                  }}
                  key={index}
                >
                  {mainItems[index] && renderNavBlock(mainItems[index])}
                </Grid>
              ))}
            {isMobile && (
              <Grid item xs={12}>
                <Box
                  sx={(theme) => ({
                    marginLeft: theme.spacing(-theme.gutter('sm')),
                    marginRight: theme.spacing(-theme.gutter('sm')),
                  })}
                >
                  {mainItems.map(renderNavAccordion)}
                </Box>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={4}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                order: mainColumns,
                [theme.breakpoints.up('md')]: {
                  alignItems: 'flex-start',
                },
              })}
            >
              {contactFormPage && (
                <>
                  <Typography variant="footer">
                    <FormattedMessage id="label.contactHeadline" />
                  </Typography>
                  <Button sx={{ mt: 4 }} size="medium" to={contactFormPage.url}>
                    <FormattedMessage id="button.contactForm" />
                  </Button>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                gap: '48px 40px',
                justifyContent: 'center',
                order: mainColumns,
                [theme.breakpoints.up('lg')]: {
                  justifyContent: 'flex-start',
                  order: mainColumns + 1,
                },
              })}
            >
              {metaNavigationItems.map((item, index) => (
                <Link
                fontSize="small"
                fontWeight="bold"
                to={item.url}
                key={index}
                >
                  {item.label}
                </Link>
              ))}
              <Grid 
              item xs={0}
              lg={1.5}
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: {
                  marginLeft: 'auto',
                }
              })}> 
                <LanguageSwitch />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                order: mainColumns,
                [theme.breakpoints.up('lg')]: {
                  mr: 'auto',
                  alignItems: 'flex-start',
                  order: mainColumns,
                },
              })}
            >
              <Typography variant="footer">© 2023 S.OLIVER GROUP</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
