import { Components, Theme } from '@mui/material'

export const AppBarOverrides = (theme: Theme): Components['MuiAppBar'] => ({
  defaultProps: {
    elevation: 0,
    color: 'inherit',
  },
  styleOverrides: {
    root: {
      zIndex: theme.zIndex.modal,
    },
  },
})
