import React, { ComponentType, ReactElement, useState } from 'react'
import { Typography } from '@mui/material'
import { MotionProps } from 'framer-motion'

import Button from '../button'
import ComponentContainer from '@components/core/container/componentContainer'
import Link from '../link'

export type CalloutProps = SOG.Props.IDefault &
  MotionProps & {
    title: string
    linkTo: SOG.Props.LinkTo
    theme?: SOG.Contentful.Theme
    size?: 'small' | 'medium' | 'large'
    titleClamp?: number
    background?: SOG.Props.Image
    component?: ComponentType
  }

export default function Callout({
  title,
  linkTo,
  theme,
  size = 'large',
  titleClamp = 3,
  background,
  component,
  initial,
  animate,
  transition,
  variants,
  whileInView,
  viewport,
  sx,
}: CalloutProps): ReactElement {
  const [isHover, setIsHover] = useState(false)
  return (
    <Link
      to={linkTo.url}
      isExternal={linkTo.isExternal}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      sx={sx}
    >
      <ComponentContainer
        theme={theme}
        size={size}
        component={component}
        initial={initial}
        animate={animate}
        variants={variants}
        transition={transition}
        whileInView={whileInView}
        viewport={viewport}
        sx={{
          width: '100%',
          display: 'inline-flex',
          flexDirection: 'column',
          backgroundImage: background?.file?.url
            ? `url(${background.file.url})`
            : null,
          backgroundPosition: 'right center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <Typography
          variant={size === 'large' ? 'h4' : size === 'medium' ? 'h5' : 'h6'}
          component="div"
          sx={(theme) => ({
            ...theme.mixins.lineClamp(titleClamp),
            mb: 3,
            [theme.breakpoints.up('lg')]: {
              mb: 6,
            },
          })}
          gutterBottom={false}
        >
          {title}
        </Typography>
        <Button
          variant="text"
          size="small"
          isActive={isHover}
          theme={theme}
          to={linkTo.url}
          isExternal={linkTo.isExternal}
          sx={{
            mt: 'auto',
            alignSelf: 'flex-start',
            height: '30px',
          }}
        >
          {linkTo.label}
        </Button>
      </ComponentContainer>
    </Link>
  )
}
