import React, { ReactElement } from 'react'

import PageModuleParser from './PageModuleParser'

export type PageModuleProps = SOG.Props.IDefault & {
  pagemodule: SOG.Contentful.ModuleUnion & { __typename: string }
}

export default function PageModule({
  pagemodule,
  sx,
}: PageModuleProps): ReactElement {
  const Parser = PageModuleParser.find((parsermodule) => {
    return pagemodule.__typename === parsermodule.__typename
  })

  if (!Parser) {
    console.warn('Module not defined: ', pagemodule)
    return <></>
  }

  return React.createElement(Parser.component, Parser.props(pagemodule, sx))
}
