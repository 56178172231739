import FontSize from '@config/theme/definitions/fontSize'
import { Components, Theme } from '@mui/material'

export const LinkOverrides = (theme: Theme): Components['MuiLink'] => ({
  styleOverrides: {
    root: {
      color: 'currentcolor',
      fontWeight: 400,
      letterSpacing: 0.3,
      transition: 'color 300ms ease',
      textDecorationColor: 'currentcolor',
      ':hover': {
        color: theme.palette.secondary.main,
      },
      '&.MuiLink-fontSizeSmall': {
        fontSize: FontSize.get('sm'),
        lineHeight: 1.2,
      },
      '&.MuiLink-fontSizeMedium': {
        fontSize: FontSize.get('base'),
        lineHeight: 1.2,
      },
      '&.MuiLink-fontSizeLarge': {
        fontSize: FontSize.get('lg'),
        lineHeight: 1.2,
      },
      '&.MuiLink-fontWeightNormal': {},
      '&.MuiLink-fontWeightBold': {
        fontWeight: 500,
      },
    },
    button: {
      fontFamily: theme.typography.fontFamily,
    },
  },
})
