import React, { ReactElement } from 'react'
import { Link as MuiLink, LinkBaseProps } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'

import Icon, { IconProps } from '../icon'

export type LinkProps = SOG.Props.IDefault &
  LinkBaseProps & {
    id?: string
    to?: string
    isDownload?: boolean
    isExternal?: boolean
    isActive?: boolean
    icon?: IconProps
    iconLayout?: 'before' | 'after'
    underline?: 'none' | 'hover' | 'always'
  }

export default function Link({
  id,
  children,
  to,
  isExternal = false,
  isDownload = false,
  isActive = false,
  icon,
  iconLayout = 'after',
  fontSize = 'medium',
  fontWeight = 'normal',
  underline = 'none',
  onClick,
  ...props
}: LinkProps): ReactElement {
  const className = clsx({
    ['MuiLink-root']: true,
    ['Mui-active']: isActive,
    ['MuiLink-fontSizeSmall']: fontSize === 'small',
    ['MuiLink-fontSizeMedium']: fontSize === 'medium',
    ['MuiLink-fontSizeLarge']: fontSize === 'large',
    ['MuiLink-fontWeightNormal']: fontWeight === 'normal',
    ['MuiLink-fontWeightBold']: fontWeight === 'bold',
  })

  const LinkComponent =
    !isExternal && to ? GatsbyLink : !to && onClick ? 'button' : 'a'
  const additionalProps =
    isExternal || isDownload
      ? { href: to, target: '_blank', rel: 'noreferrer', download: isDownload }
      : { to }

  return (
    <MuiLink
      {...props}
      id={id}
      className={className}
      component={LinkComponent}
      underline={underline}
      onClick={onClick}
      {...additionalProps}
    >
      {icon && iconLayout === 'before' && <Icon {...icon} />}
      {icon && <span>{children}</span>}
      {!icon && children}
      {icon && iconLayout === 'after' && <Icon {...icon} />}
    </MuiLink>
  )
}
