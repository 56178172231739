import React, { ReactElement } from 'react'
import { Chip as MuiChip } from '@mui/material'

import Icon from '../icon'

export type ChipProps = SOG.Props.IDefault & {
  label: string
  selected?: boolean
  size?: 'small' | 'medium'
  onClick?: () => void
  onDelete?: () => void
  withDeleteIcon?: boolean
}

export default function Chip({
  label,
  onClick,
  onDelete,
  selected = false,
  size = 'medium',
  withDeleteIcon = false,
  sx = {},
}: ChipProps): ReactElement {
  return (
    <MuiChip
      className={selected === true ? 'Mui-selected' : undefined}
      sx={sx}
      label={label}
      variant="outlined"
      size={size}
      deleteIcon={
        onDelete || withDeleteIcon ? (
          <Icon name="Close" color="inherit" />
        ) : undefined
      }
      onClick={onClick}
      onDelete={onDelete}
    />
  )
}
