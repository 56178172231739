import { Theme } from '@mui/material/'
import {
  ColorPartial,
  PaletteOptions,
} from '@mui/material/styles/createPalette'

/**
 * Used https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * for [Shade] = hex based on styleguide colors.
 */
export const Colors: Record<string, ColorPartial> = {
  red: {
    50: '#ffeaee',
    100: '#ffcad3',
    200: '#ef949b',
    300: '#e56974',
    400: '#f04153',
    500: '#f62339',
    600: '#e71538',
    // style guide color
    700: '#D50032',
    800: '#c8002a',
    900: '#ba001e',
  },
  greyBrown: {
    50: '#eaeaea',
    100: '#cacaca',
    200: '#aba6a1',
    // style guide color
    300: '#8c8278',
    400: '#766859',
    500: '#604f3c',
    600: '#554636',
    700: '#473a2d',
    800: '#3a2e24',
    900: '#2b2119',
  },
  grey: {
    // style guide color
    100: '#F5F5F5',
    200: '#ececec',
    300: '#dddddd',
    400: '#b9b9b9',
    // style guide color
    500: '#AAAAAA',
    600: '#717171',
    700: '#5d5d5d',
    800: '#3f3f3f',
    // style guide color
    900: '#1E1E1E',
  },
  orange: {
    100: '#E1A588',
    200: '#E15742',
  },
}
export const PaletteOverrides = (theme: Theme): PaletteOptions => ({
  ...theme.palette,
  mode: 'light',
  primary: {
    main: Colors.red[700] || '',
    dark: Colors.red[800],
    ...Colors.red,
  },
  secondary: {
    main: Colors.greyBrown[300],
    ...Colors.greyBrown,
  },
  tertiary: {
    main: Colors.grey[500],
    ...Colors.grey,
  },
  background: {
    ...theme.palette.background,
    main: theme.palette.common.white,
    primary: Colors.grey[900],
    secondary: Colors.greyBrown[300],
    grey: Colors.grey,
    light: Colors.grey[100],
    medium: Colors.greyBrown[300],
    dark: Colors.grey[900],
  },
  text: {
    ...theme.palette.text,
    main: Colors.grey[900],
    primary: Colors.grey[900],
    secondary: Colors.greyBrown[300],
    inverted: theme.palette.common.white,
  },
  hyperlink: {
    ...theme.palette.hyperlink,
    active: Colors.orange[100],
    hover: Colors.orange[200],
  },
  grey: Colors.grey,
})
