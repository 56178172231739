import React, { useState, useRef, useEffect, ReactElement } from 'react'

import List from '@mui/material/List'

import SubNavItem from './horizontalSubNavItem'
import { IMenuState } from './horizontalNavItem'

import theme from '@config/theme'

interface IHorizontalSubNav extends SOG.Props.IDefault {
  items: SOG.Props.LinkTo[]
  onNavigate: () => void
  setAdjustedHeight: React.Dispatch<React.SetStateAction<string>>
  adjustedHeight: string
  returnFocus: (close: boolean) => void
  level: number
  menuState: IMenuState
  isVisible?: boolean
  isFirstItemInTopLevelNav?: boolean
}

export default function HorizontalSubNav({
  items,
  menuState,
  onNavigate,
  setAdjustedHeight,
  adjustedHeight,
  returnFocus,
  level,
  isVisible,
  isFirstItemInTopLevelNav,
  ...props
}: IHorizontalSubNav): ReactElement {
  const CompRef = useRef<HTMLUListElement>(null)
  const [focusIndex, setFocusIndex] = useState(-1)
  const [activeIndex, setActiveIndex] = useState(-1)

  useEffect(() => {
    if (!menuState.open) {
      setFocusIndex(-1)
      setActiveIndex(-1)
    } else if (menuState.trigger === 'keyUp') {
      // setIndex to pass focus to last list child when triggered by keyUp
      setFocusIndex(items.length - 1)
    } else if (menuState.trigger === 'keyDown') {
      // setIndex to pass focus to first list child when triggered by keyDown
      setFocusIndex(0)
    } else if (menuState.trigger === 'key') {
      // setIndex ot pass focus to first child when triggered by parent item ArrowRight (3rd level only)
      setFocusIndex(0)
    }
  }, [menuState])

  useEffect(() => {
    if (focusIndex === -1) {
      // go back to first level link / headline
      returnFocus(false)
    }
  }, [focusIndex])

  const locationsULChildren =
    CompRef.current?.children[activeIndex]?.children[1]?.children
  const isLocationsSelected =
    activeIndex === 3 && isFirstItemInTopLevelNav && locationsULChildren

  if (!!isLocationsSelected) {
    setTimeout(() => {
      const locations = Array.from(locationsULChildren)
      const newHeight =
        locations[0].clientHeight * (locations.length + 1) + 'px'
      if (adjustedHeight === 'auto') {
        setAdjustedHeight(newHeight)
      }
    }, 100)
  }

  return (
    <List
      ref={CompRef}
      role="menu"
      component="ul"
      sx={{
        listStyle: 'none',
        ml: 'auto',
        padding: theme.spacing(8, 22, 8, 0),
        ...(level === 2 && {
          position: 'relative',
          height: '100%',
          flexGrow: 1,
        }),
        ...(level === 3 && {
          display: menuState.open === true ? 'block' : 'none',
          position: 'absolute',
          top: 0,
          left: '100%',
          backgroundColor: theme.palette.grey[100],
          height: '100%',
          flexGrow: 2,
          width: '400%',
          zIndex: 1,
          paddingLeft: theme.spacing(8),
        }),
      }}
      {...props}
    >
      {items?.map((item, idx) => {
        return (
          <SubNavItem
            key={idx}
            item={item}
            isFocused={focusIndex === idx}
            isOpen={activeIndex === idx}
            level={2}
            onNavigate={onNavigate}
            isVisible={isVisible}
            transitionDelay={(0.6 / items.length) * idx}
            onMouseEnter={() => {
              setActiveIndex(idx)
            }}
          />
        )
      })}
    </List>
  )
}
