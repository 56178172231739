import React, { useEffect, useState } from 'react'
import { Theme, useMediaQuery, Box, IconButton } from '@mui/material'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper'
import Icon from '@components/core/ui/icon'

export type ListItem = {
  id: string
  label: string
}

export type TocListProps = SOG.Props.IDefault & {
  items: Array<ListItem>
  position: number
}

export default function ToCList({ items, position }: TocListProps) {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const [SwiperInstance, setSwiperInstance] = useState<SwiperClass>()
  const [SwiperState, setSwiperState] = useState<
    'beginning' | 'middle' | 'end'
  >('beginning')
  const [SwiperActiveIndex, setSwiperActiveIndex] = useState<number>(0)

  const _SwiperProps: SwiperProps = {
    slidesPerView: 5,
    direction: isLarge ? 'vertical' : 'horizontal',
    onSwiper: setSwiperInstance,
    onSlideChange: (swiper) => {
      if (swiper.isBeginning) {
        setSwiperState('beginning')
      } else if (swiper.isEnd) {
        setSwiperState('end')
      } else {
        setSwiperState('middle')
      }
      setSwiperActiveIndex(swiper.activeIndex)
    },
  }

  useEffect(() => {
    if (position === SwiperActiveIndex || !_SwiperProps.slidesPerView) return

    if (SwiperActiveIndex - position <= -3) {
      SwiperInstance?.slideTo(position)
    } else if (SwiperActiveIndex - position >= 1) {
      SwiperInstance?.slideTo(position)
    }
  }, [position])

  function getPosition(element: HTMLElement | null) {
    let yPosition = 0

    while (element) {
      yPosition += element.offsetTop - element.scrollTop + element.clientTop
      element = element.offsetParent as HTMLElement
    }

    return yPosition - (isLarge ? 120 : 60)
  }

  function _itemActivateHandler(item: ListItem) {
    window.scrollTo({
      top: getPosition(document.getElementById(item.id)),
      left: 0,
      behavior: 'smooth',
    })
  }

  function renderItems() {
    if (!items) return
    return items.map((item, i) => {
      return (
        item.id && (
          <SwiperSlide
            key={`tocItem-${item.id}`}
            style={{ textAlign: isLarge ? 'left' : 'center' }}
          >
            <Box
              component={'a'}
              href={`#${item.id}`}
              onClick={(
                ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                ev.preventDefault()
                _itemActivateHandler(item)
              }}
              sx={(theme) => ({
                fontWeight: 400,
                textDecoration: 'none',
                position: 'relative',
                paddingBottom: theme.spacing(1),
                width: '100%',
                textAlign: 'center',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  height: '2px',
                  right: 0,
                  left: 0,
                  bottom: 0,
                  backgroundColor: theme.palette.text.primary,
                  transition: 'transform 0.2s',
                  transitionTimingFunction: theme.transitions.easing.easeInOut,
                  transform: `scaleX(${position === i ? 1 : 0})`,
                  transformOrigin: '100% 1px',
                },
                '&:hover': {
                  color: theme.palette.primary.light,
                  '&:before': {
                    transformOrigin: '0 1px',
                    transform: `scaleX(1)`,
                  },
                },
              })}
            >
              {item.label}
            </Box>
          </SwiperSlide>
        )
      )
    })
  }

  return (
    <Box
      data-testid="list"
      component={'nav'}
      sx={(theme) => ({
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
          position: 'sticky',
          top: theme.spacing(40),
          paddingY: theme.spacing(10),
        },
      })}
    >
      <Swiper
        {..._SwiperProps}
        height={(_SwiperProps.slidesPerView as number) * 42}
        style={{ maxHeight: (_SwiperProps.slidesPerView as number) * 42 }}
      >
        {renderItems()}
      </Swiper>
      <IconButton
        disabled={SwiperState === 'beginning'}
        onClick={() => SwiperInstance?.slidePrev()}
        sx={(theme) => ({
          visibility: SwiperState === 'beginning' ? 'hidden' : 'visible',
          position: 'absolute',
          transform: 'translate(-100%, -1%) rotate(90deg)',
          top: -6,
          left: 0,
          [theme.breakpoints.up('lg')]: {
            top: 0,
            transform: 'rotate(180deg)',
          },
        })}
      >
        <Icon name="ChevronDown" />
      </IconButton>
      <IconButton
        disabled={SwiperState === 'end'}
        onClick={() => SwiperInstance?.slideNext()}
        sx={(theme) => ({
          visibility: SwiperState === 'end' ? 'hidden' : 'visible',
          position: 'absolute',
          [theme.breakpoints.down('md')]: {
            transform: 'translate(100%) rotate(-90deg)',
            top: -6,
            right: 0,
          },
          [theme.breakpoints.up('lg')]: {
            left: 0,
            bottom: 5,
          },
        })}
      >
        <Icon name="ChevronDown" />
      </IconButton>
    </Box>
  )
}
