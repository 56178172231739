import { Components, Theme } from '@mui/material'
import { TypeBackground, TypeText } from '@mui/material/styles/createPalette'

export const MuiFormControlOverrides = (
  theme: Theme
): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      width: '100%',
    },
  },
})

export const MuiInputBaseOverrides = (
  theme: Theme
): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
})

export const MuiFilledInputOverrides = (
  theme: Theme
): Components['MuiFilledInput'] => ({
  styleOverrides: {
    root: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '&:before': {
        borderBottom: '1px solid',
      },
      '&:after': {
        borderBottom: '1px solid',
      },

      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    input: {
      cursor: 'pointer',
      padding: 0,
    },
  },
})

export const MuiFormHelperTextOverrides = (
  theme: Theme
): Components['MuiFormHelperText'] => ({
  styleOverrides: {
    root: {
      marginLeft: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
})

export const MuiInputLabelOverrides = (
  theme: Theme
): Components['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      fontSize: 'lg',
      fontWeight: '700',
      marginBottom: theme.spacing(3),
    },
    asterisk: {
      color: theme.palette.primary.main,
    },
  },
})
