import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import theme from '@config/theme'

function Burger({ active }) {
  return (
    <Box
      data-testid="burger"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        height: theme.spacing(4),
        width: theme.spacing(5),
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '1.6px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '110%',
            transform: 'translateY(3px) translateX(-2px) rotate(45deg)',
          }),
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '1.6px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '110%',
            transform: 'translateY(-5px) translateX(-2px) rotate(-45deg)',
          }),
        }}
      ></Box>
    </Box>
  )
}

Burger.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
}

export default Burger
