import React, { useCallback, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import useLightbox from '@components/system/hooks/useLightbox'
import Image from '@components/core/media/image'
import Section from '@components/core/container/section'
import Slider from '@components/core/ui/slider'
import ModuleSection from '@components/core/container/moduleSection'
import Button from '@components/core/ui/button'
import Copy from '@components/core/text/copy'
import Headline from '@components/core/text/headline'
import CollapsableContainer from '@components/core/container/collapsableContainer'
import {
  moduleSlideRightMotionProps,
  moduleSlideLeftMotionProps,
} from '@utils/animation'
import Icon from '@components/core/ui/icon'
import { isVideoFile } from '@utils/props'
import VideoPlayer from '@components/core/media/videoPlayer'
import useBreakpointValue from '@components/system/hooks/useBreakpointValue'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'

export type CarouselContentProps = SOG.Props.IDefault & SOG.Props.IGlobalContent

export const CarouselContent = ({
  id,
  caption,
  headline,
  copy,
  linkTo,
  sx,
}: CarouselContentProps): React.ReactElement => {
  return (
    <StyledMotionDiv
      {...moduleSlideLeftMotionProps}
      sx={[
        (sxTheme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: 'transparent',
          [sxTheme.breakpoints.up('md')]: {
            p: 8,
            pb: 26,
          },
        }),
        sx as SOG.Props.SxInArray,
      ]}
      key={id}
    >
      {caption && (
        <Headline
          variant="caption"
          sx={(sxTheme) => ({
            mb: 2,
            color: sxTheme.palette.background.medium,
            [sxTheme.breakpoints.up('md')]: {
              mb: 4,
            },
          })}
        >
          {caption}
        </Headline>
      )}
      <Headline variant="h4">{headline}</Headline>
      <Copy
        sx={(sxTheme) => ({
          mt: 4,
          '& > div': {
            color: sxTheme.palette.common.black,
          },
          [sxTheme.breakpoints.up('lg')]: {
            mt: 8,
            mb: '90px',
          },
        })}
      >
        {copy}
      </Copy>
      {linkTo && (
        <Button
          variant="text"
          to={linkTo.url}
          isExternal={linkTo.isExternal}
          sx={(theme) => ({
            mt: 4,
            [theme.breakpoints.up('lg')]: {
              mt: 8,
            },
          })}
        >
          {linkTo.label}
        </Button>
      )}
    </StyledMotionDiv>
  )
}

export type CarouselContentStoryProps = SOG.Props.IDefault &
  SOG.Props.IGlobalStory

export const CarouselContentStory = ({
  id,
  employee,
  copy,
  video,
  sx,
}: CarouselContentStoryProps): React.ReactElement => {
  const { showDialog } = useLightbox()
  return (
    <Box
      sx={[
        (sxTheme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          p: 6,
          [sxTheme.breakpoints.up('md')]: {
            p: 8,
          },
        }),
        sx as SOG.Props.SxInArray,
      ]}
      key={id}
    >
      <motion.div {...moduleSlideLeftMotionProps}>
        <Copy
          sx={(theme) => ({
            mb: employee && 2,
            [theme.breakpoints.up('md')]: {
              mb: employee && 4,
            },
          })}
          html={
            employee && `<strong>${employee.name}</strong> ${employee.position}`
          }
        />
        <Copy>{copy}</Copy>
        {video && (
          <Button
            variant="text"
            onClick={() =>
              showDialog({
                type: 'video',
                id: video.anchor,
                props: video,
              })
            }
            sx={(theme) => ({
              mt: 4,
              [theme.breakpoints.up('lg')]: {
                mt: 8,
              },
            })}
          >
            <FormattedMessage id="button.watchVideo" />
          </Button>
        )}
      </motion.div>
    </Box>
  )
}

export type CarouselProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    type: 'media' | 'content' | 'stories'
    caption?: string
    headline?: string
    copy?: SOG.Props.RichText
    entries:
      | SOG.Props.IGlobalStory[]
      | SOG.Props.IGlobalContent[]
      | SOG.Props.IGlobalMedia[]
    lightboxEntries?: SOG.Props.IGlobalMedia[]
    linkTo?: SOG.Props.LinkTo
    backgroundColour: boolean
    theme?: SOG.Contentful.Theme
  }

export default function Carousel({
  type,
  caption,
  headline,
  copy,
  entries = [],
  lightboxEntries = [],
  linkTo,
  backgroundColour = false,
  theme,
  sx = {},
}: CarouselProps): React.ReactElement {
  const isContent = type === 'content'
  const isStories = type === 'stories'
  const isMedia = type === 'media'
  const withHeader = !!caption || !!headline || !!copy || !!linkTo

  const { showDialog } = useLightbox()
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const sliderHeight = isMedia
    ? useBreakpointValue({
        sm: 240,
        md: 400,
        lg: 600,
      })
    : undefined

  const sliderEntries: {
    media: SOG.Props.Image | SOG.Props.Video
    thumbnail?: SOG.Props.Image
    isVideo?: boolean
    withPlayIcon?: boolean
    key: string
    theme?: SOG.Contentful.Theme
  }[] = useMemo(() => {
    return isMedia
      ? (entries as SOG.Props.IGlobalMedia[]).map((entry, index) => {
          const isVideo = isVideoFile(entry.media?.file)
          return {
            key: `${index}-${entry.id}`,
            media: entry.media,
            thumbnail: entry.thumbnail,
            isVideo,
          }
        })
      : (entries as SOG.Props.IGlobalContent[] | SOG.Props.IGlobalStory[]).map(
          (entry, index) => {
            const isVideo = !!(entry as SOG.Props.IGlobalStory).video
            return {
              key: `${index}-${entry.id}`,
              media: entry.image,
              withPlayIcon: isVideo,
            }
          }
        )
  }, [])

  const changeActiveSlide = useCallback(
    (index: number) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  const onDownloadHandler = useCallback((index: number) => {
    const entry: SOG.Props.IGlobalMedia = entries[
      index
    ] as SOG.Props.IGlobalMedia
    window.open(entry.media.file.url, '_blank')
  }, [])

  const onFullscreenHandler = useCallback((index: number) => {
    const entry: SOG.Props.IGlobalMedia = entries[
      index
    ] as SOG.Props.IGlobalMedia
    showDialog({
      type: 'media',
      id: entry.anchor,
      props: {
        initialIndex: index,
        entries: lightboxEntries,
      },
    })
  }, [])

  return (
    <Section
      theme={backgroundColour === true ? 'light' : 'white'}
      sx={[
        {
          overflow: 'hidden',
          width: '100%',
        },
        sx as SOG.Props.SxInArray,
      ]}
    >
      <ModuleSection
        theme="light"
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={
          linkTo
            ? {
                ...linkTo,
                label: <FormattedMessage id="button.learnMore" />,
              }
            : undefined
        }
      >
        <Box
          component={motion.div}
          {...moduleSlideRightMotionProps}
          sx={(theme) => ({
            position: 'relative',
            mt: withHeader ? 10 : undefined,
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          })}
        >
          <Slider
            type={!isMedia ? 'scaleZoom' : undefined}
            onChangeSlide={changeActiveSlide}
            slidesPerView="auto"
            loop={isMedia}
            overflow
            theme={isContent ? theme : undefined}
            sx={(theme) => ({
              width: isContent ? '50%' : isStories ? '60%' : undefined,
              ml: !isMedia ? 'auto' : undefined,
              mr: !isMedia ? 'auto' : undefined,
              mb: !isMedia ? 8 : undefined,
              [theme.breakpoints.up('md')]: {
                position: 'relative',
                width: isContent ? '40%' : isStories ? '45%' : undefined,
                mr: 0,
                mt: 'auto',
              },
            })}
            sxUi={(sxTheme) =>
              isContent
                ? {
                    '& > button:hover': {
                      border: `2px solid ${
                        theme === 'medium' || theme === 'dark'
                          ? sxTheme.palette.common.white
                          : sxTheme.palette.background.medium
                      }`,
                      color:
                        theme === 'medium' || theme === 'dark'
                          ? sxTheme.palette.common.white
                          : sxTheme.palette.background.medium,
                      background:
                        theme === 'dark'
                          ? sxTheme.palette.background.dark
                          : theme === 'light' || theme === null
                          ? sxTheme.palette.background.light
                          : sxTheme.palette.background.medium,
                    },
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    [sxTheme.breakpoints.up('md')]: {
                      display: 'flex',
                      right: sxTheme.spacing(-8),
                      transform: 'translateX(100%)',
                    },
                  }
                : isStories
                ? {
                    [sxTheme.breakpoints.down('md')]: {
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                    },
                    [sxTheme.breakpoints.up('md')]: {
                      mt: 16,
                      justifyContent: 'flex-start',
                    },
                  }
                : {}
            }
          >
            {sliderEntries.map((entry, index) => (
              <Box key={entry.key} sx={{ width: '100%', height: '100%' }}>
                {entry.withPlayIcon && (
                  <Icon
                    name="Play"
                    fontSize="inherit"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '80px',
                      color: 'text.inverted',
                      zIndex: 1,
                    }}
                  />
                )}
                {!entry.isVideo && (
                  <Image
                    {...(entry.media as SOG.Props.Image)}
                    fixedHeight={sliderHeight}
                    onDownload={
                      isMedia && lightboxEntries
                        ? () => onDownloadHandler(index)
                        : undefined
                    }
                    onFullscreen={
                      isMedia && lightboxEntries
                        ? () => onFullscreenHandler(index)
                        : undefined
                    }
                  />
                )}
                {entry.isVideo && (
                  <VideoPlayer
                    video={entry.media as SOG.Props.Video}
                    thumbnail={entry.thumbnail}
                    height={sliderHeight}
                    onDownload={
                      isMedia && lightboxEntries
                        ? () => onDownloadHandler(index)
                        : undefined
                    }
                    onFullscreen={
                      isMedia && lightboxEntries
                        ? () => onFullscreenHandler(index)
                        : undefined
                    }
                  />
                )}
              </Box>
            ))}
          </Slider>
          {!isMedia && (
            <Box
              sx={(theme) => ({
                position: 'relative',
                display: 'flex',
                alignItems: isContent ? 'center' : 'flex-end',
                pb: 20,
                [theme.breakpoints.up('md')]: {
                  pb: 0,
                  width: '50%',
                  ml: isStories ? '-22%' : undefined,
                  zIndex: isStories ? 1 : undefined,
                },
                [theme.breakpoints.up('lg')]: {
                  width: isContent ? '38%' : '45%',
                  ml: isStories ? '-26%' : undefined,
                },
              })}
            >
              <CollapsableContainer
                isOpen
                sx={(sxTheme) =>
                  isStories
                    ? {
                        color:
                          theme === 'dark' || theme === 'medium'
                            ? sxTheme.palette.common.white
                            : sxTheme.palette.common.black,
                        backgroundColor:
                          theme === 'dark'
                            ? sxTheme.palette.background.dark
                            : theme === 'medium'
                            ? sxTheme.palette.background.medium
                            : sxTheme.palette.background.light,
                      }
                    : {
                        backgroundColor:
                          theme === 'dark'
                            ? sxTheme.palette.background.dark
                            : theme === 'medium'
                            ? sxTheme.palette.background.medium
                            : sxTheme.palette.background.light,
                        color:
                          theme === 'dark'
                            ? sxTheme.palette.common.white
                            : theme === 'light' || theme === null
                            ? sxTheme.palette.common.black
                            : sxTheme.palette.background.light,
                      }
                }
              >
                <AnimatePresence exitBeforeEnter>
                  {isContent && (
                    <CarouselContent
                      {...(entries[activeIndex] as SOG.Props.IGlobalContent)}
                    />
                  )}
                  {isStories && (
                    <CarouselContentStory
                      {...(entries[activeIndex] as SOG.Props.IGlobalStory)}
                    />
                  )}
                </AnimatePresence>
              </CollapsableContainer>
            </Box>
          )}
        </Box>
      </ModuleSection>
    </Section>
  )
}
