import React, { ReactElement, useEffect, useState, useRef } from 'react'
import { Box, IconButton } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import YouTube from '@components/core/media/youtube'
import {
  lightboxCloseButtonMotionProps,
  lightboxDialogMotionProps,
  lightboxDimmerMotionProps,
} from '@utils/animation'
import MediaGallery from '@components//modules/mediaGallery'
import Icon from '@components/core/ui/icon'
import Filter from '@components/core/ui/filter'
import useGlobalState from '@components/system/hooks/useGlobalState'
import Button from '@components/core/ui/button'
import Popup, { PopupProps } from '@components/core/ui/popup'
import { StyledMotionDiv, StyledMuiContainer } from '../fowardPropsContainer'

type MediaConfigType = {
  initialIndex: number
  entries: SOG.Props.IGlobalMedia[]
}

export type DialogConfigType = {
  type: 'media' | 'video' | 'filter' | 'popup'
  id: string
  theme?: SOG.Contentful.Theme
  props:
    | SOG.Props.IGlobalVideo
    | MediaConfigType
    | SOG.Props.PressFilterValues
    | PopupProps
}

export type LightboxProps = SOG.Props.IDefault & {
  theme?: SOG.Contentful.Theme
  currentDialog?: DialogConfigType
  isOpen: boolean
  onClose?: () => void
  onHide?: () => void
}

type LightboxFilterProps = SOG.Props.PressFilterValues & {
  onClose?: () => void
}

const LightboxFilter = (props: LightboxFilterProps) => {
  const { globalFilters, setGlobaleFilters, globalFilterResultsTotal } =
    useGlobalState()
  return (
    <>
      <Filter
        topics={props.topics}
        initialTopics={globalFilters.topics}
        brands={props.brands}
        initialBrands={globalFilters.brands}
        mediums={props.mediums}
        initialMediums={globalFilters.mediums}
        targetGroups={props.targetGroups}
        initialTargetGroups={globalFilters.targetGroups}
        formats={props.formats}
        initialFormats={globalFilters.formats}
        sx={{ pt: 5, pb: 20 }}
        onChange={(values) => {
          setGlobaleFilters(values)
        }}
      />

      <Button
        variant="secondary"
        onClick={props.onClose}
        sx={{ position: 'fixed', left: 0, bottom: 0, width: '100%' }}
      >
        <FormattedMessage
          id="button.showResults"
          values={{
            total: globalFilterResultsTotal,
          }}
        />
      </Button>
    </>
  )
}

export default function Lightbox({
  currentDialog,
  isOpen = false,
  onClose,
  onHide,
}: LightboxProps): ReactElement {
  const [isOverflow, setIsOverflow] = useState(false)
  const [stateDialog, setStateDialog] = useState(currentDialog)
  const containerRef = useRef<HTMLElement>()
  const isPopup = stateDialog?.type === 'popup'
  const isFilter = stateDialog?.type === 'filter'

  useEffect(() => {
    if (currentDialog && currentDialog?.id !== stateDialog?.id) {
      setStateDialog(currentDialog)
    }
  }, [currentDialog])

  useEffect(() => {
    const resizeHandler = () => {
      if (!containerRef.current) return
      const currentIsOverflow =
        containerRef.current.scrollHeight > containerRef.current.clientHeight

      if (!isOverflow && currentIsOverflow) {
        setIsOverflow(true)
      }

      if (isOverflow && !currentIsOverflow) {
        setIsOverflow(false)
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => window.removeEventListener('resize', resizeHandler)
  }, [containerRef.current])

  const exitHandler = () => {
    setStateDialog(undefined)
    if (onHide) {
      onHide()
    }
  }

  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: theme.zIndex.modal,
        color: !isFilter ? 'text.inverted' : 'text.primary',
      })}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
      >
        {currentDialog?.type !== 'popup' && (
          <StyledMotionDiv
            onClick={onClose}
            animate={isOpen ? 'show' : 'hide'}
            {...lightboxCloseButtonMotionProps}
            sx={(theme) => ({
              position: 'absolute',
              right: 6,
              top: 6,
              zIndex: 2,
              [theme.breakpoints.up('lg')]: {
                right: 12,
                top: 12,
              },
            })}
          >
            <IconButton onClick={onClose} color="inherit">
              <Icon name="Close" color="inherit" />
            </IconButton>
          </StyledMotionDiv>
        )}

        <StyledMotionDiv
          onClick={onClose}
          custom={isPopup ? 0.8 : 1}
          animate={isOpen ? 'show' : 'hide'}
          {...lightboxDimmerMotionProps}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: isFilter ? 'common.white' : 'background.dark',
            cursor: 'pointer',
          }}
        />
        <Box
          ref={containerRef}
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',

            width: '100%',
            height: '100%',
            overflow: 'auto',

            [theme.breakpoints.up('md')]: {
              pt: 0,
              alignItems:
                !isOverflow && currentDialog?.type !== 'filter'
                  ? 'center'
                  : undefined,
            },
          })}
        >
          <AnimatePresence exitBeforeEnter onExitComplete={exitHandler}>
            {currentDialog && (
              <StyledMuiContainer
                {...lightboxDialogMotionProps}
                maxWidth="lg"
                sx={(theme) => ({
                  width: '100%',
                  zIndex: 1,
                  py: theme.gutter('sm'),
                  pt: 10,
                  [theme.breakpoints.up('md')]: {
                    py: theme.gutter('md'),
                  },
                  [theme.breakpoints.up('lg')]: {
                    py: theme.gutter('lg'),
                  },
                })}
                key={currentDialog.id}
              >
                {currentDialog.type === 'video' && (
                  <YouTube
                    {...(currentDialog.props as SOG.Props.IGlobalVideo)}
                  />
                )}
                {currentDialog.type === 'media' && (
                  <MediaGallery {...(currentDialog.props as MediaConfigType)} />
                )}
                {currentDialog.type === 'filter' && (
                  <LightboxFilter
                    {...(currentDialog.props as SOG.Props.PressFilterValues)}
                    onClose={onClose}
                  />
                )}
                {currentDialog.type === 'popup' && (
                  <Popup
                    {...(currentDialog.props as PopupProps)}
                    onClose={onClose}
                  />
                )}
              </StyledMuiContainer>
            )}
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  )
}
