import React from 'react'
import { Box, Grid } from '@mui/material'
import { motion } from 'framer-motion'

import { moduleSlideUpMotionProps } from '@utils/animation'
import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import { FormattedMessage } from 'react-intl'
import IconText from '@components/core/ui/icontext'

export type BenefitsProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption?: string
    headline: string
    copy?: SOG.Props.RichText
    linkTo?: SOG.Props.LinkTo
    benefits: SOG.Props.IGlobalBenefit[]
  }

export default function Benefits({
  caption,
  headline,
  copy,
  linkTo,
  benefits = [],
}: BenefitsProps): React.ReactElement {
  return (
    <Section>
      <ModuleSection
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={
          linkTo
            ? {
                ...linkTo,
                label: <FormattedMessage id="button.learnMore" />,
              }
            : undefined
        }
        wrapped
        sx={(theme) => ({
          mr: 8,
          '& .MuiTypography-h2': {
            [theme.breakpoints.down('md')]: {
              fontSize: '32px',
            },
          },
          [theme.breakpoints.up('lg')]: {
            mr: 0,
          },
        })}
      >
        <Box sx={{ mt: 4 }}>
          <Grid container columnSpacing={24} rowSpacing={8}>
            {benefits.map((entry, index) => (
              <Grid
                item
                key={index}
                xs={12}
                md={6}
                sx={(theme) => ({
                  [theme.breakpoints.down('lg')]: {
                    paddingTop: '48px !important',
                  },
                  [theme.breakpoints.up('lg')]: {
                    paddingTop: '64px !important',
                  },
                })}
                component={motion.div}
                {...moduleSlideUpMotionProps}
              >
                <IconText
                  icon={entry.icon}
                  headline={entry.title}
                  copy={entry.description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </ModuleSection>
    </Section>
  )
}
