import React, { useState } from 'react'
import { Box } from '@mui/material'

import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'

import { FormattedMessage } from 'react-intl'

import Link from '@components/core/ui/link'
import Image from '@components/core/media/image'
import Icon from '@components/core/ui/icon'
import { graphql, useStaticQuery } from 'gatsby'
import { PageState } from '@components/core/container/layout'

export type RelatedContentTeaser = SOG.Props.IDefault & {
  entries: SOG.Props.IPageRelated[]
  currentPageState: PageState
}

export default function RelatedContentTeaser({
  entries = [],
  currentPageState,
}: RelatedContentTeaser): React.ReactElement | null {
  if (!currentPageState) return null

  const [hoverIndex, setIsHoverIndex] = useState(-1)
  const thisGenericPageId =
    !!currentPageState.pageContext && currentPageState.pageContext.id
  const { relatedPageUrls } = useStaticQuery(
    graphql`
      query {
        relatedPageUrls: allContentfulPageGeneric {
          nodes {
            relatedPages {
              ... on ContentfulPageGeneric {
                fields {
                  linkTo {
                    url
                  }
                }
              }
            }
            id
          }
        }
      }
    `
  )
  const thisFilteredGenericPage = relatedPageUrls.nodes.filter(
    (pages: HTMLObjectElement) => pages.id === thisGenericPageId
  )
  if (!thisFilteredGenericPage.length) return null

  return (
    <Section maxWidth={false}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          position: 'relative',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        })}
      >
        {entries.map((entry, index) => (
          <Link
            key={index}
            isExternal={false}
            to={
              thisFilteredGenericPage[0].relatedPages[index].fields.linkTo.url
            }
            onMouseOver={() => setIsHoverIndex(index)}
            onMouseOut={() => setIsHoverIndex(-1)}
            sx={(theme) => ({
              position: 'relative',
              width: '50%',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                height: '33.3vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',

                [theme.breakpoints.down('sm')]: {
                  height: '66.6vw',
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  zIndex: 1,
                  pointerEvents: 'none',
                  p: 16,
                  [theme.breakpoints.down('sm')]: {
                    p: 6,
                  },
                })}
              >
                <Headline
                  variant="h5"
                  sx={(theme) => ({
                    ...theme.mixins.fluidFontSize('md', 'xl', '2lg', '2xl'),
                    color: 'text.inverted',
                    transition: `color 50ms cubic-bezier(0.4, 0, 0.2, 1) ${
                      hoverIndex !== index ? '50ms' : '0ms'
                    }`,
                  })}
                >
                  {entry.teaserTitle}
                </Headline>

                <Box
                  sx={(theme) => ({
                    marginTop: theme.spacing(2),
                  })}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: 'text.inverted',
                    }}
                  >
                    <FormattedMessage id="button.learnMore" />
                  </Box>
                  <span>
                    <Icon
                      name="ChevronRight"
                      sx={{
                        fontSize: '16px',
                        transform:
                          index === hoverIndex
                            ? 'translateX(14px)'
                            : 'translateX(8px)',
                        color: 'text.inverted',
                        transition: `transform 1s ease ${
                          hoverIndex !== index ? '50ms' : '0ms'
                        }`,
                      }}
                    />
                  </span>
                </Box>
              </Box>
              {entry.teaserImage && (
                <Image
                  {...entry.teaserImage}
                  initial="over"
                  animate={index === hoverIndex ? 'over' : 'out'}
                  variants={{
                    over: {
                      opacity: 0.7,
                      transition: {
                        type: 'tween',
                        ease: 'linear',
                        duration: 0.2,
                      },
                    },
                    out: {
                      opacity: 1,
                      transition: {
                        type: 'tween',
                        ease: 'linear',
                        duration: 0.1,
                        delay: 0.1,
                      },
                    },
                  }}
                  objectFit="cover"
                  sx={{
                    position: 'absolute',
                    height: '100%',
                  }}
                />
              )}
            </Box>
          </Link>
        ))}
      </Box>
    </Section>
  )
}
