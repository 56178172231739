/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAssetProp, getGlobalProp } from '@utils/props'
import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

const useContentfulNavigation = () => {
  const { locale } = useIntl()
  const { main } = useStaticQuery(
    graphql`
      query {
        main: allContentfulNavigation(
          filter: { identifier: { eq: "navigationMain" } }
        ) {
          nodes {
            ...NavigationFragment
          }
        }
      }
    `
  )

  const results = useMemo(() => {
    const { entries, metaEntries, contactFormPage, brands } = main.nodes.find(
      (entry: SOG.Contentful.INavigation) => entry.node_locale === locale
    )
    return {
      main: {
        entries: entries.map((entry: any) => ({
          ...entry.fields?.linkTo,
          hideInNavigation: entry.hideInNavigation ?? false,
          teaserImage: entry.teaserImage
            ? getAssetProp(entry.teaserImage)
            : undefined,
          children:
            entry.children?.map((childEntry: any) => ({
              ...childEntry.fields?.linkTo,
              children:
                childEntry.children?.map(
                  (deepchildEntry: any) => deepchildEntry.fields.linkTo
                ) || [],
            })) || [],
          contentful_id: entry.contentful_id,
        })),
        metaEntries: metaEntries.map((entry: any) => ({
          ...entry.fields?.linkTo,
        })),
        contactFormPage: contactFormPage?.fields?.linkTo
          ? { ...contactFormPage.fields?.linkTo }
          : undefined,
        brands: brands.map((entry: any) => ({
          ...getGlobalProp(entry),
        })),
      },
    }
  }, [main, locale])

  return results
}
export default useContentfulNavigation
