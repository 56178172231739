import { FontSizeType } from '@mui/material/styles/createTheme'

const FontSize: FontSizeType = new Map([
  ['xs', '13px'], // function mobile
  ['sm', '14px'], // caption mobile
  ['base', '16px'], // caption desk, function desk, button mobile
  ['baseXS', '16px'],
  ['lg', '18px'], // body copy desk, h6 mobile, h7 mobile, subline mobile
  ['2lg', '20px'], // h7 desk, h5 mobile, button desk, accordion mobile
  ['3lg', '22px'], //
  ['xl', '24px'], // h6 desk, subline desk, h4 mobile, quote2 mobile
  ['2xl', '32px'], // h5 desk, h3 mobile, accordion desk
  ['3xl', '40px'], // h4 desk, h2 mobile, quote2 desk
  ['4xl', '48px'], // h3 desk, h1 mobile, zitat mobile
  ['5xl', '72px'], // h2 desk, zitat desk
  ['6xl', '96px'], // h1 desk
  ['iconSmall', '16px'], // icon small <--vorläufig
  ['iconDefault', '24px'], // icon default <--vorläufig
  ['iconLarge', '32px'], // icon large <--vorläufig
])

export default FontSize
