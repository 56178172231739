import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { motion } from 'framer-motion'

import Button from '@components/core/ui/button'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import {
  moduleSlideRightMotionProps,
  moduleSlideUpMotionProps,
  moduleSlideLeftMotionProps,
} from '@utils/animation'

export type ModuleProps = SOG.Props.IDefault & {
  caption?: string
  headline?: string
  copy?: SOG.Props.RichText
  linkTo?: SOG.Props.LinkTo
  wrapped?: boolean
  theme?: SOG.Contentful.Theme
}

export default function ModuleSection({
  caption,
  headline,
  copy,
  linkTo,
  children,
  wrapped,
  theme,
  sx,
}: ModuleProps): ReactElement {
  return (
    <>
      {(caption || headline || copy || linkTo) && (
        <Box sx={sx}>
          {caption && (
            <Headline
              component={motion.div}
              {...moduleSlideRightMotionProps}
              variant="caption"
              sx={(sxTheme) => ({
                display: 'inline-block',
                mb: 2,
                [sxTheme.breakpoints.up('md')]: {
                  mb: 4,
                },
                color:
                  theme === 'medium'
                    ? sxTheme.palette.common.white
                    : sxTheme.palette.background.medium,
              })}
            >
              {caption}
            </Headline>
          )}
          {(headline || copy || linkTo) && (
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  display: 'flex',
                },
              })}
            >
              {(headline || copy) && (
                <Box
                  sx={
                    linkTo
                      ? (theme) => ({
                          [theme.breakpoints.up('md')]: {
                            pr: 20,
                          },
                          [theme.breakpoints.up('lg')]: {
                            pr: 25,
                            maxWidth: '75%',
                          },
                        })
                      : undefined
                  }
                >
                  {headline && (
                    <Headline
                      variant="h2"
                      component={motion.div}
                      {...moduleSlideRightMotionProps}
                      sx={{ hyphens: 'auto' }}
                    >
                      {headline}
                    </Headline>
                  )}
                  {copy && (
                    <Copy
                      component={motion.p}
                      {...moduleSlideRightMotionProps}
                      sx={(theme) => ({
                        mt: 4,
                        [theme.breakpoints.up('lg')]: {
                          mt: 8,
                        },
                      })}
                    >
                      {copy}
                    </Copy>
                  )}
                </Box>
              )}
              {linkTo && (
                <Box
                  component={motion.div}
                  {...moduleSlideLeftMotionProps}
                  sx={(theme) => ({
                    mt: 8,
                    alignSelf: 'flex-start',
                    flexShrink: 0,
                    [theme.breakpoints.down('md')]: wrapped && {
                      display: 'none',
                    },
                    [theme.breakpoints.up('md')]: {
                      // mt: 16,
                      mt: 0,
                      ml: 'auto',
                    },
                    [theme.breakpoints.up('lg')]: {
                      mt: 4,
                    },
                  })}
                >
                  <Button
                    variant="primary"
                    to={linkTo.url}
                    isExternal={linkTo.isExternal}
                  >
                    {linkTo.label}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      {children}
      {linkTo && wrapped && (
        <Box
          component={motion.div}
          {...moduleSlideUpMotionProps}
          sx={(theme) => ({
            mt: 8,
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          })}
        >
          <Button variant={'primary'} to={linkTo.url}>
            {linkTo.label}
          </Button>
        </Box>
      )}
    </>
  )
}
