import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'

import React, { ReactElement } from 'react'
import * as ReactIntl from 'react-intl'

import { WrapPageElementNodeArgs } from 'gatsby'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import theme from '@config/theme'
import Layout from '@components/core/container/layout'
import { defaultLang, messages } from '../../translations/'
import PageMetaTags from './PageMetaTags'
import LightboxProvider from './providers/LightboxProvider'
import GlobalStateProvider from './providers/GlobalStateProvider'

type PageWrapperProps = WrapPageElementNodeArgs & {
  props: {
    pageContext: SOG.Helpers.IPageContext
    location: Record<string, boolean>
  }
}

export function PageWrapper({
  element,
  props,
}: PageWrapperProps): ReactElement {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={theme}>
        {props.pageContext?.page?.metaTags && (
          <PageMetaTags
            locale={props.pageContext.locale}
            meta={props.pageContext.page.metaTags}
          />
        )}
        <CssBaseline />
        <GlobalStateProvider>
          <LightboxProvider>
            <Layout {...props}>{element}</Layout>
          </LightboxProvider>
        </GlobalStateProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}
