import { getAssetProp } from '@utils/props'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulPageArticleDetail = (
  topics?: SOG.Contentful.IGlobalTopic[],
  limit = 4
): SOG.Props.IPageArticleDetail[] => {
  const { locale } = useIntl()
  const { entries } = useStaticQuery(
    graphql`
      query {
        entries: allContentfulPageArticleDetail(
          sort: { order: ASC, fields: createdAt }
        ) {
          nodes {
            ...PageArticleDetailFragment
          }
        }
      }
    `
  )

  const filterEntries = topics
    ? entries.nodes.filter((entry: SOG.Contentful.IPageArticleDetail) =>
        topics.some(
          (topic: SOG.Contentful.IGlobalTopic) => entry?.topic?.id === topic?.id
        )
      )
    : entries.nodes

  return filterEntries
    .filter(
      (entry: SOG.Contentful.IPageArticleDetail) => entry.node_locale === locale
    )
    .sort(
      (
        a: SOG.Contentful.IPageArticleDetail,
        b: SOG.Contentful.IPageArticleDetail
      ) =>
        new Date(a.releaseDate).getTime() < new Date(b.releaseDate).getTime()
          ? 1
          : -1
    )
    .slice(0, limit)
    .map(({ image, fields, ...rest }: SOG.Contentful.IPageArticleDetail) => ({
      ...rest,
      image: getAssetProp(image) as SOG.Props.Image,
      linkTo: fields?.linkTo,
    }))
}
export default useContentfulPageArticleDetail
