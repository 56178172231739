import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

import Section from '@components/core/container/section'
import Image from '@components/core/media/image'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'

export type ContactsProps = SOG.Props.IDefault & {
  theme?: SOG.Contentful.Theme
  headline: string | React.ReactElement
  entries: SOG.Props.IGlobalContact[]
  withSeparator?: boolean
}

export type ContactProps = SOG.Props.IDefault & {
  name: string
  position: string
  phone: string
  email: string
  image: SOG.Props.Image
}

const SxContactLink = styled('a')({})

function Contact({
  name,
  position,
  phone,
  email,
  image,
  theme,
  sx,
}: ContactProps) {
  return (
    <Box
      sx={[
        (theme) => ({
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        }),
        sx as SOG.Props.SxInArray,
      ]}
    >
      <Image
        {...image}
        sx={(theme) => ({
          height: 'auto',
          maxWidth: 169,
          [theme.breakpoints.up('md')]: {
            maxWidth: 240,
          },
        })}
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
          [theme.breakpoints.up('md')]: {
            mt: 0,
            ml: 8,
          },
        })}
      >
        <Headline
          variant="h6"
          sx={(theme) => ({
            mb: 2,
            [theme.breakpoints.up('md')]: {
              mb: 4,
            },
          })}
        >
          {name}
        </Headline>
        <Typography
          variant="baseBold"
          sx={(sxTheme) => ({
            mb: 4,
            color:
              theme === 'medium'
                ? sxTheme.palette.common.white
                : sxTheme.palette.text.secondary,
            [sxTheme.breakpoints.up('md')]: {
              mb: 6,
            },
          })}
        >
          {position}
        </Typography>

        <SxContactLink
          href={'tel:' + phone}
          sx={{
            fontWeight: 'normal',
            textDecoration: 'none',
          }}
        >
          {phone}
        </SxContactLink>

        <SxContactLink
          href={'mailto:' + email}
          sx={{
            fontWeight: 'normal',
            textDecoration: 'none',
          }}
        >
          {email}
        </SxContactLink>
      </Box>
    </Box>
  )
}

export default function Contacts({
  theme,
  headline,
  entries = [],
  withSeparator,
}: ContactsProps): React.ReactElement {
  return (
    <Section theme={theme} withSeparator={withSeparator} paddingSize="lg">
      <Headline variant="h3">{headline}</Headline>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          mt: 10,
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            mt: 16,
          },
        })}
      >
        {entries.map((entry, index) => (
          <Contact
            theme={theme}
            {...entry}
            key={index}
            sx={(theme) => ({
              mr: 14,
              mb: index === 0 ? 10 : 0,
              [theme.breakpoints.up('md')]: {
                mb: 0,
                width: '50%',
                mr: index % 2 === 0 ? 8 : 0,
              },
            })}
          />
        ))}
      </Box>
    </Section>
  )
}
