import React, { ReactElement } from 'react'

import { styled, Typography } from '@mui/material'

export type ListProps = SOG.Props.IDefault & {
  type?: 'ordered' | 'unordered'
}

const UnorderedStyled = styled('ul')({
  listStyleType: 'square',
  paddingLeft: '1em',
  marginTop: 0,
  marginBottom: 0,
})

const OrderedStyled = styled('ol')({
  paddingLeft: '1em',
  marginTop: 0,
  marginBottom: 0,
})

export default function List({
  type = 'unordered',
  children,
  sx,
}: ListProps): ReactElement {
  const Component = type === 'ordered' ? OrderedStyled : UnorderedStyled
  return (
    <Component sx={sx}>
      {React.Children.map(children, (child, index) => (
        <Typography key={index}>{child}</Typography>
      ))}
    </Component>
  )
}
