import React, { useCallback, useState } from 'react'
import { Box } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'
import {
  moduleSlideRightMotionProps,
  moduleSlideLeftMotionProps,
  moduleSlideUpMotionProps,
  tabContentMotionProps,
} from '@utils/animation'
import Image from '@components/core/media/image'
import Button from '@components/core/ui/button'
import Chip from '@components/core/ui/chip'
import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import { FormattedMessage } from 'react-intl'
import Slider from '@components/core/ui/slider'

type TabContentProps = SOG.Props.IDefault &
  SOG.Props.IGlobalContent & {
    type?: 'standalone'
  }

const TabContent = ({
  headline,
  copy,
  linkTo,
  image,
  type,
}: TabContentProps): React.ReactElement => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: type === 'standalone' ? 'column-reverse' : 'column',
        [theme.breakpoints.up('sm')]: {
          flexDirection: type === 'standalone' ? 'row-reverse' : 'row',
          justifyContent: 'space-between',
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: '48px',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: '50%',
          },
        })}
      >
        <Headline
          component={motion.div}
          {...moduleSlideLeftMotionProps}
          variant={type === 'standalone' ? 'h3' : 'h2'}
          sx={(theme) => ({
            hyphens: 'auto',
            [theme.breakpoints.up('sm')]: {
              ...theme.mixins.fluidFontSize('md', 'xl', '2xl', '4xl'),
            },
            mb: 6,
          })}
        >
          {headline}
        </Headline>
        <Copy
          component={motion.div}
          {...moduleSlideLeftMotionProps}
          variant="body2"
          sx={(theme) => ({
            mb: 4,
            [theme.breakpoints.up('md')]: {
              mb: 8,
            },
          })}
        >
          {copy}
        </Copy>
        {linkTo && (
          <motion.div {...moduleSlideUpMotionProps}>
            <Button variant="text" to={linkTo.url} isExternal={linkTo.isExternal}>
              <FormattedMessage id="button.learnMore" />
            </Button>
          </motion.div>
        )}
      </Box>
      <StyledMotionDiv
        {...moduleSlideRightMotionProps}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            width: '50%',
            ml: type === 'standalone' ? 0 : 15,
            mr: type === 'standalone' ? 15 : 0,
          },
          [theme.breakpoints.up('md')]: {
            ml: type === 'standalone' ? 0 : 25,
            mr: type === 'standalone' ? 15 : 0,
          },
        })}
      >
        <Image
          {...image}
          sx={(theme) => ({
            position: 'relative',
            width: '100%',
            ml: type === 'standalone' ? 0 : 'auto',
            mr: type === 'standalone' ? 'auto' : 0,
            mt: type === 'standalone' ? 0 : 8,
            mb: type === 'standalone' ? 8 : 8,
            [theme.breakpoints.up('sm')]: {
              maxWidth: '500px',
              mt: 0,
              mb: 0,
            },
          })}
        />
      </StyledMotionDiv>
    </Box>
  )
}

export type TabsProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    copy?: SOG.Props.RichText
    entries: SOG.Props.IGlobalContent[]
  }

export default function Tabs({
  caption,
  headline,
  copy,
  entries = [],
}: TabsProps): React.ReactElement {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const tabTitles: string[] = (entries as SOG.Props.IGlobalContent[]).map(
    (entry) => (entry.titleNavigation ? entry.titleNavigation : 'empty')
  )

  const changeActiveTab = useCallback(
    (activeIndex) => {
      setActiveIndex(activeIndex)
    },
    [setActiveIndex]
  )

  return (
    <Section sx={{ overflow: 'hidden' }}>
      <ModuleSection caption={caption} headline={headline} copy={copy}>
        <Box
          sx={(theme) => ({
            position: 'relative',
            mt: 10,
            [theme.breakpoints.up('sm')]: {
              display: 'flex',
              flexDirection: 'column',
            },
          })}
        >
          <Slider
            type={'horizontal'}
            slidesPerView={'auto'}
            arrowsType="none"
            overflow={true}
          >
            {tabTitles.map((title, index) => (
              <Chip
                label={title}
                key={index}
                selected={activeIndex === index}
                onClick={() => changeActiveTab(index)}
                sx={{
                  mr: 2,
                }}
              />
            ))}
          </Slider>
          <Box
            sx={(theme) => ({
              position: 'relative',
              display: 'contents',
              alignItems: 'center',
              [theme.breakpoints.up('sm')]: {
                display: 'flex',
                width: '100%',
                mt: 16,
              },
            })}
          >
            <AnimatePresence exitBeforeEnter>
              <StyledMotionDiv {...tabContentMotionProps} key={activeIndex}>
                <TabContent
                  {...(entries[activeIndex] as SOG.Props.IGlobalContent)}
                  type={!!headline ? 'standalone' : undefined}
                />
              </StyledMotionDiv>
            </AnimatePresence>
          </Box>
        </Box>
      </ModuleSection>
    </Section>
  )
}
