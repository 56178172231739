import { pageMainMotionProps } from '@utils/animation'
import { PageWrapper } from './src/components/system/PageWrapper'

let rafId = -1
export const wrapPageElement = PageWrapper
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  const TRANSITION_DELAY =
    pageMainMotionProps.exit.transition.duration * 1000 + 1
  if (!prevRouterProps) {
    window.scrollTo([0, 0])
  } else {
    const lastPosition = getSavedScrollPosition(prevRouterProps.location) || [
      0, 0,
    ]
    const nextPosition = getSavedScrollPosition(routerProps.location) || [0, 0]
    window.scrollTo(...lastPosition)

    window.cancelAnimationFrame(rafId)
    let start = undefined

    function step(timestamp) {
      if (start === undefined) {
        start = timestamp
      }
      const elapsed = timestamp - start
      if (elapsed < TRANSITION_DELAY) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        rafId = window.requestAnimationFrame(step)
      } else {
        window.scrollTo(...nextPosition)
      }
    }

    window.requestAnimationFrame(step)

    // window.setTimeout(() => window.scrollTo(...nextPosition), TRANSITION_DELAY)
  }

  return false
}
