import { getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { ReactNode, useMemo } from 'react'
import { richTextOptions } from './richtext'

export const getAssetProp = (
  value:
    | SOG.Contentful.IAsset
    | SOG.Contentful.IAssetImage
    | SOG.Contentful.IAssetSvg
    | undefined
): SOG.Props.Image | SOG.Props.Svg | SOG.Props.Video | undefined => {
  if (!value || !value.file) return undefined

  const isImage = value.file.contentType.startsWith('image')
  const asset = {
    title: value.title,
    description: value.description,
    file: value.file,
  }
  if (isImage && (value as SOG.Contentful.IAssetSvg).svg) {
    return {
      ...asset,
      content: (value as SOG.Contentful.IAssetSvg).svg.content,
      originalContent: (value as SOG.Contentful.IAssetSvg).svg.originalContent,
    }
  }

  return isImage && (value as SOG.Contentful.IAssetImage).gatsbyImageData
    ? {
        ...asset,
        data: getImage((value as SOG.Contentful.IAssetImage).gatsbyImageData),
      }
    : asset
}

export const getRichTextProp = (
  value: SOG.Contentful.BasicRichTextType | undefined
): ReactNode => {
  return value ? renderRichText(value, richTextOptions) : undefined
}

export const getGlobalProp = (
  value: SOG.Contentful.GlobalTypeUnion | undefined
): SOG.Props.GlobalTypeUnion | undefined => {
  if (!value) return undefined
  switch (value.__typename) {
    case 'ContentfulGlobalTopic': {
      const { id, node_locale, title } = value as SOG.Contentful.IGlobalTopic
      return {
        id,
        node_locale,
        title,
      } as SOG.Props.IGlobalTopic
    }
    case 'ContentfulGlobalMedium': {
      const { id, node_locale, title } = value as SOG.Contentful.IGlobalMedium
      return {
        id,
        node_locale,
        title,
      } as SOG.Props.IGlobalMedium
    }
    case 'ContentfulGlobalTargetGroup': {
      const { id, node_locale, title } =
        value as SOG.Contentful.IGlobalTargetGroup
      return {
        id,
        node_locale,
        title,
      } as SOG.Props.IGlobalTargetGroup
    }
    case 'ContentfulGlobalBrand': {
      const {
        id,
        node_locale,
        title,
        name,
        description,
        icon,
        image,
        linkPage,
      } = value as SOG.Contentful.IGlobalBrand
      return {
        id,
        node_locale,
        title,
        name,
        description: description?.description,
        icon: getAssetProp(icon) as SOG.Props.Svg,
        image: getAssetProp(image) as SOG.Props.Image,
        linkTo: linkPage.fields?.linkTo,
      } as SOG.Props.IGlobalBrand
    }
    case 'ContentfulGlobalBenefit': {
      const { id, node_locale, title, description, icon } =
        value as SOG.Contentful.IGlobalBenefit
      return {
        id,
        node_locale,
        title,
        description: description?.description,
        icon: getAssetProp(icon) as SOG.Props.Svg,
      } as SOG.Props.IGlobalBenefit
    }
    case 'ContentfulGlobalFact': {
      const {
        id,
        node_locale,
        descriptionMax,
        descriptionMin,
        factValue,
        hasSeparator,
        caption,
        icon,
      } = value as SOG.Contentful.IGlobalFact
      return {
        id,
        node_locale,
        factValue,
        hasSeparator,
        caption,
        descriptionMin,
        descriptionMax: descriptionMax?.descriptionMax,
        icon: getAssetProp(icon) as SOG.Props.Svg,
      } as SOG.Props.IGlobalFact
    }
    case 'ContentfulGlobalEmployee': {
      const { id, node_locale, name, position, image } =
        value as SOG.Contentful.IGlobalEmployee
      return {
        id,
        node_locale,
        name,
        position,
        image: getAssetProp(image) as SOG.Props.Image,
      } as SOG.Props.IGlobalEmployee
    }
    case 'ContentfulGlobalContact': {
      const { id, node_locale, name, position, phone, email, image } =
        value as SOG.Contentful.IGlobalContact
      return {
        id,
        node_locale,
        name,
        position,
        phone,
        email,
        image: getAssetProp(image) as SOG.Props.Image,
      } as SOG.Props.IGlobalContact
    }
    case 'ContentfulGlobalVideo': {
      const { id, node_locale, poster, posterMobile, anchor, youtubeId, sys } =
        value as SOG.Contentful.IGlobalVideo
      return {
        id,
        node_locale,
        anchor,
        youtubeId,
        poster: poster ? (getAssetProp(poster) as SOG.Props.Image) : undefined,
        posterMobile: posterMobile
          ? (getAssetProp(posterMobile) as SOG.Props.Image)
          : undefined,
        sys,
      } as SOG.Props.IGlobalVideo
    }
    case 'ContentfulGlobalImage': {
      const { id, node_locale, description, image, sys } =
        value as SOG.Contentful.IGlobalImage
      return {
        id,
        node_locale,
        description,
        image: image ? (getAssetProp(image) as SOG.Props.Image) : undefined,
        sys,
      } as SOG.Props.IGlobalImage
    }
    case 'ContentfulGlobalJob': {
      const { id, node_locale, title, location, linkPage } =
        value as SOG.Contentful.IGlobalJob
      return {
        id,
        node_locale,
        title,
        location,
        linkTo: linkPage?.fields?.linkTo,
      } as SOG.Props.IGlobalJob
    }
    case 'ContentfulGlobalAccordionContent': {
      const { id, node_locale, title, theme, content } =
        value as SOG.Contentful.IGlobalAccordionContent
      return {
        id,
        node_locale,
        title,
        theme,
        content: getRichTextProp(content),
      } as SOG.Props.IGlobalAccordionContent
    }
    case 'ContentfulGlobalContent': {
      const {
        id,
        node_locale,
        caption,
        headline,
        titleNavigation,
        image,
        copy,
        linkPage,
      } = value as SOG.Contentful.IGlobalContent
      return {
        id,
        node_locale,
        caption,
        headline,
        titleNavigation,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as SOG.Props.Image,
        linkTo: linkPage?.fields?.linkTo,
      } as SOG.Props.IGlobalContent
    }
    case 'ContentfulGlobalStory': {
      const { id, node_locale, employee, image, copy, video, theme } =
        value as SOG.Contentful.IGlobalStory
      return {
        id,
        node_locale,
        employee: getGlobalProp(employee),
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as SOG.Props.Image,
        video: video ? getGlobalProp(video) : undefined,
        theme,
      } as SOG.Props.IGlobalStory
    }
    case 'ContentfulGlobalMedia': {
      const {
        id,
        node_locale,
        anchor,
        title,
        copyright,
        media,
        thumbnail,
        description,
        topics,
        mediums,
        targets,
        brands,
      } = value as SOG.Contentful.IGlobalMedia
      return {
        id,
        node_locale,
        anchor,
        title,
        copyright,
        topics: (getGlobalPropList(topics) as SOG.Props.IGlobalTopic[]) || [],
        mediums:
          (getGlobalPropList(mediums) as SOG.Props.IGlobalMedium[]) || [],
        targets:
          (getGlobalPropList(targets) as SOG.Props.IGlobalTargetGroup[]) || [],
        brands: (getGlobalPropList(brands) as SOG.Props.IGlobalBrand[]) || [],
        media: getAssetProp(media) as SOG.Props.Image,
        thumbnail: thumbnail
          ? (getAssetProp(thumbnail) as SOG.Props.Image)
          : undefined,
        description: description?.description,
      } as SOG.Props.IGlobalMedia
    }
    case 'ContentfulGlobalBenefit': {
      const { id, node_locale, title, description, icon } =
        value as SOG.Contentful.IGlobalBenefit
      return {
        id,
        node_locale,
        title,
        description: description?.description,
        icon: getAssetProp(icon) as SOG.Props.Svg,
      } as SOG.Props.IGlobalBenefit
    }
  }

  return {
    id: value.id,
    node_locale: value.node_locale,
    title: 'N/A',
  }
}

export const getContextRelatedPages = (
  pageContext?: SOG.Helpers.IPageContext
): SOG.Props.IPageRelated[] => {
  const relatedPages = useMemo(
    () =>
      pageContext?.page && !!pageContext?.page.relatedPages
        ? (pageContext.page.relatedPages.map(
            ({
              teaserImage,
              fields,
              ...rest
            }: SOG.Contentful.IPageRelated) => ({
              ...rest,
              linkTo: fields?.linkTo,
              teaserImage: teaserImage
                ? (getAssetProp(teaserImage) as SOG.Props.Image)
                : undefined,
            })
          ) as SOG.Props.IPageRelated[])
        : [],
    [pageContext]
  )
  return relatedPages
}

export function getPressRelatedPages(
  pages: Array<SOG.Contentful.IPageRelated>
): Array<SOG.Props.IPageRelated> {
  return pages.map(({ teaserImage, fields, ...rest }) => ({
    ...rest,
    linkTo: fields?.linkTo,
    teaserImage: teaserImage
      ? (getAssetProp(teaserImage) as SOG.Props.Image)
      : undefined,
  })) as Array<SOG.Props.IPageRelated>
}

export const getGlobalPropList = (
  entries: SOG.Contentful.GlobalTypeUnion[] | undefined
): SOG.Props.GlobalTypeUnion[] | undefined =>
  entries
    ? (entries
        .map((entry) => getGlobalProp(entry))
        .filter((entry) => !!entry) as SOG.Props.GlobalTypeUnion[])
    : undefined

export const getAssetPropList = (
  entries:
    | (
        | SOG.Contentful.IAsset
        | SOG.Contentful.IAssetImage
        | SOG.Contentful.IAssetSvg
      )[]
    | undefined
): (SOG.Props.Image | SOG.Props.Svg | SOG.Props.Video)[] | undefined =>
  entries
    ? (entries
        .map((entry) => getAssetProp(entry))
        .filter((entry) => !!entry) as (
        | SOG.Props.Image
        | SOG.Props.Svg
        | SOG.Props.Video
      )[])
    : undefined

export const isVideoFile = (file?: SOG.Contentful.IFile): boolean => {
  return file?.contentType?.startsWith('video') || false
}
