import React, { ReactElement, useEffect, useState } from 'react'
import { Box, Button as MuiButton, Fade } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'

import Icon, { IconNamesType } from '../icon'
import clsx from 'clsx'

export type ButtonProps = SOG.Props.IDefault & {
  id?: string
  to?: string
  onClick?: (event: React.SyntheticEvent) => void
  variant?: 'text' | 'primary' | 'secondary' | 'tertiary'
  size?: 'small' | 'medium' | 'large'
  icon?: IconNamesType
  isExternal?: boolean
  isDownload?: boolean
  isActive?: boolean
  isDisabled?: boolean
  backtop?: boolean | false
  isAnchor?: boolean
  theme?: SOG.Contentful.Theme
}

const variantProps: { [key: string]: any } = {
  primary: { variant: 'contained', color: 'primary' },
  secondary: { variant: 'contained', color: 'secondary' },
  tertiary: { variant: 'contained', color: 'tertiary' },
  text: { variant: 'text' },
}

/**
 * MUI Button Wrapper
 */
export default function Button({
  id,
  to,
  onClick,
  variant = 'primary',
  icon = 'ArrowUpRight',
  size = 'large',
  children,
  isExternal,
  isDownload,
  isActive,
  isDisabled,
  backtop,
  theme,
  isAnchor,
  sx = {},
}: ButtonProps): ReactElement {
  const className = clsx({
    ['Mui-active']: isActive,
    ['Mui-disabled']: isDisabled,
    ['MuiButton-containedPadding']: !!children,
  })

  const iconName: IconNamesType =
    variant === 'text' && !icon ? 'ChevronRight' : icon
  const IconComponent = (
    <Icon name={iconName} sx={{ fontSize: !children ? '2em' : undefined }} />
  )

  const LinkComponent =
    to && !isExternal && !isAnchor
      ? GatsbyLink
      : !to && onClick
      ? 'button'
      : 'a'
  const additionalProps = isExternal
    ? { href: to, target: '_blank', rel: 'noreferrer', download: isDownload }
    : isAnchor
    ? { href: to }
    : { to }

  const [isVisible, setVisibility] = useState(false)

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (!backtop) return
    setVisibility(false)
    document.addEventListener('scroll', toggleVisibility)
    return () => {
      document.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  function toggleVisibility() {
    if (window.pageYOffset > 50) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }

  if (backtop) {
    return (
      <Fade in={isVisible} timeout={1000}>
        <MuiButton
          id={id}
          className={className}
          component={Box}
          startIcon={!children ? IconComponent : undefined}
          endIcon={children ? IconComponent : undefined}
          underline="none"
          size={size}
          onClick={scrollTop}
          {...variantProps[variant]}
          {...additionalProps}
          disableFocusRipple={variant === 'text'}
          disableRipple={variant === 'text'}
          sx={{
            display: 'flex',
            position: 'fixed',
            zIndex: 1,
            right: 0,
            bottom: 0,
            marginRight: '32px',
            marginBottom: '32px',
            backgroundColor: '#AAAAAA',
            color: 'white',
            borderColor: '#AAAAAA',
            '&:hover': {
              backgroundColor: '#AAAAAA',
              color: 'white',
            },
          }}
        ></MuiButton>
      </Fade>
    )
  }

  if (!backtop) {
    return (
      <MuiButton
        id={id}
        className={className}
        component={LinkComponent}
        startIcon={!children ? IconComponent : undefined}
        endIcon={children ? IconComponent : undefined}
        underline="none"
        size={size}
        onClick={onClick}
        {...variantProps[variant]}
        {...additionalProps}
        disableFocusRipple={variant === 'text'}
        disableRipple={variant === 'text'}
        sx={(sxTheme) => ({
          ...sx,
          color:
            theme === 'light' && variant !== 'secondary'
              ? sxTheme.palette.background.secondary
              : theme === 'medium' && variant === 'secondary'
              ? sxTheme.palette.background.secondary
              : theme === 'medium' ||
                variant === 'primary' ||
                variant === 'secondary'
              ? sxTheme.palette.common.white
              : sxTheme.palette.background.secondary,
          backgroundColor:
            variant === 'text'
              ? 'transparent'
              : theme === 'medium'
              ? sxTheme.palette.common.white
              : sxTheme.palette.background.medium,
          border:
            variant === 'text' ||
            theme === 'dark' ||
            theme === 'medium' ||
            theme === 'light'
              ? '2px solid transparent'
              : `2px solid ${
                  variant === 'secondary' && theme === 'medium'
                    ? sxTheme.palette.common.white
                    : sxTheme.palette.background.medium
                }`,
          '&:hover': {
            color:
              variant === 'secondary' && theme === 'dark'
                ? sxTheme.palette.background.secondary
                : variant === 'secondary' && theme === 'medium'
                ? sxTheme.palette.common.white
                : theme === 'medium' || (theme === 'dark' && variant !== 'text')
                ? 'text.inverted'
                : sxTheme.palette.background.secondary,
            '& > span:nth-of-type(2) > svg': {
              transform: 'scale(1.2)',
              transition: '0.1s all ease-in-out',
            },
            backgroundColor:
              variant === 'secondary' && theme === 'medium'
                ? sxTheme.palette.background.medium
                : 'transparent',
            border:
              theme === 'dark' && variant !== 'secondary' && variant !== 'text'
                ? `2px solid ${sxTheme.palette.common.white}`
                : (theme === 'medium' || theme === 'light') &&
                  variant !== 'secondary'
                ? '2px solid transparent'
                : `2px solid ${
                    theme === 'medium' && variant === 'secondary'
                      ? sxTheme.palette.common.white
                      : variant === 'secondary' && theme !== 'light'
                      ? sxTheme.palette.background.secondary
                      : variant === 'text'
                      ? 'transparent'
                      : sxTheme.palette.background.secondary
                  }`,
          },
        })}
      >
        {children && (
          <Box className="MuiButton-label" component="span">
            {children}
          </Box>
        )}
      </MuiButton>
    )
  }
}
