import React, { useCallback, useEffect, useState } from 'react'
import { Box, Container, styled } from '@mui/material'
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import Section from '@components/core/container/section'
import Button from '@components/core/ui/button'
import Callout from '@components/core/ui/callout'
import Headline from '@components/core/text/headline'
import useGlobalState from '@components/system/hooks/useGlobalState'
import {
  stageButtonMotionProps,
  stageFadeInMotionProps,
  stageHeadlineCustomMotionProps,
  stageLogoMotionProps,
  stageScrollToLineMotionProps,
  stageTeaserMotionProps,
} from '@utils/animation'

import IconLogo from '@static/img/sog-logo.svg'
import {
  StyledMotionDiv,
  StyledMotionSpan,
} from '@components/core/container/fowardPropsContainer'

const FlexWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
}))

const ColumnWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'index' && prop !== 'total' && prop !== 'overlap',
})<{
  index: number
  total: number
  overlap?: boolean
}>(({ theme, index, total, overlap }) => {
  switch (index) {
    case 1:
      return {
        gridColumnStart: '1',
        gridColumnEnd: '13',
        gridRow: '1',
        zIndex: 3,
        [theme.breakpoints.up('md')]: {
          gridColumnStart: '1',
          gridColumnEnd: total === 1 ? '8' : overlap ? '10' : '8',
        },
      }
    case 2:
      return {
        gridColumnStart: '1',
        gridColumnEnd: '13',
        gridRow: '2',
        [theme.breakpoints.up('md')]: {
          gridRow: '1',
          gridColumnStart: '6',
          gridColumnEnd: '13',
        },
      }
  }
})

export type StageProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    headline: string
    images?: SOG.Props.Image[]
    backgroundImage?: SOG.Props.Image
    backgroundVideo?: SOG.Props.Video
    linkTo?: SOG.Props.LinkTo
    teaserImage?: SOG.Props.Image
    teaserLinkTo?: SOG.Props.LinkTo
    fullViewOrWidescreen?: boolean
  }

export default function Stage({
  headline,
  images = [],
  backgroundImage,
  backgroundVideo,
  linkTo,
  teaserImage,
  teaserLinkTo,
  fullViewOrWidescreen,
}: StageProps): React.ReactElement {
  if (images.length) {
    images = []
  }
  fullViewOrWidescreen ? (images.length = 1) : images
  const { setVideoAsShown, hasVideoBeenShown } = useGlobalState()
  const withBackgroundVideo = !!backgroundImage && !!backgroundVideo
  const videoShown =
    !!backgroundVideo && hasVideoBeenShown(backgroundVideo.file.fileName)

  const [isBackgroundReady, setIsBackgroundReady] = useState<boolean>(
    !!!backgroundVideo || videoShown
  )
  const [isSafariBrowser, setIsSafariBrowser] = useState(false)
  const [hideScrollButton, setHideScrollButton] = useState(false)

  const [background] = useState<SOG.Props.Image | SOG.Props.Video | undefined>(
    withBackgroundVideo && !videoShown ? backgroundVideo : backgroundImage
  )
  const totalColumns = images.length > 0 ? 2 : 1
  const isTextOnly = images.length === 0

  const onBackgroundComplete = useCallback(() => {
    setIsBackgroundReady(true)
    setVideoAsShown((backgroundVideo as SOG.Props.Video).file.fileName)
  }, [])

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().includes('safari/')) {
      setIsSafariBrowser(true)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      if (scrollY > 100) {
        setHideScrollButton(true)
      } else {
        setHideScrollButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScrollTo = useCallback(() => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
    setTimeout(() => {
      setHideScrollButton(true)
    }, 500)
  }, [])

  const headlines = headline
    ?.split('\n')
    .map((entry) => entry.replace(/[\u2028]/g, ''))
    .filter((entry) => !!entry)
  return (
    <Section
      className="ModuleStage"
      background={background}
      maxWidth={false}
      paddingTop={
        !!background || isTextOnly || { sm: true, md: false, lg: false }
      }
      paddingBottom={!!background}
      onBackgroundComplete={backgroundVideo ? onBackgroundComplete : undefined}
      sx={(theme) => ({
        backgroundColor: background ? theme.palette.grey[100] : undefined,
        position: 'relative',
        mb: teaserLinkTo ? 20 : undefined,
        zIndex: 1,
        [theme.breakpoints.up('md')]: !isTextOnly
          ? {
              minHeight: 700,
              height: teaserLinkTo
                ? 'calc(100vh - 44px - 50px)'
                : 'calc(100vh - 50px)',
            }
          : undefined,
        [theme.breakpoints.up('lg')]: !isTextOnly
          ? {
              minHeight: 700,
              height: teaserLinkTo
                ? 'calc(100vh - 73px - 69px)'
                : 'calc(100vh - 69px)',
              mb: teaserLinkTo ? 20 : undefined,
              pb: teaserLinkTo ? 20 : undefined,
            }
          : {
              mb: teaserLinkTo ? 20 : undefined,
              pb: teaserLinkTo ? 20 : undefined,
            },
      })}
    >
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          position: 'relative',
          [theme.breakpoints.down('md')]: {
            mb: teaserLinkTo ? 25 : undefined,
          },
          [theme.breakpoints.up('md')]: {
            height: '100%',
          },
        })}
      >
        <StyledMotionDiv
          animate={isBackgroundReady ? 'ready' : 'default'}
          {...stageLogoMotionProps}
          sx={(theme) => ({
            display: backgroundVideo ? 'block' : 'none',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            transformOrigin: 'center',
            width: '50%',
            zIndex: 1,
            '& > svg > g > g > g > g > polygon': {
              fill: theme.palette.common.white,
            },
          })}
        >
          <IconLogo width="100%" />
        </StyledMotionDiv>
        <FlexWrapper
          sx={{
            justifyContent: fullViewOrWidescreen ? 'center' : 'start',
            padding: fullViewOrWidescreen ? '50px 0' : 'auto',
          }}
        >
          <ColumnWrapper
            index={1}
            total={totalColumns}
            overlap={images.length === 1}
          >
            <Headline variant="h1">
              {headlines?.map((entry, index) => (
                <Box
                  component="span"
                  sx={{ display: 'block', overflow: 'hidden' }}
                  key={index}
                >
                  <StyledMotionSpan
                    animate={isBackgroundReady ? 'ready' : 'default'}
                    {...stageHeadlineCustomMotionProps}
                    sx={(sxTheme) => ({
                      display: 'inline-block',
                      width: '100%',
                      [sxTheme.breakpoints.down('lg')]: {
                        hyphens: 'auto',
                        padding: isSafariBrowser ? '10px 24px' : '10px 0',
                        maxWidth: isSafariBrowser ? '100vw' : 'auto',
                      },
                      color: backgroundImage ? 'common.white' : 'common.black',
                      padding: '10px 0',
                      textAlign: 'center',
                    })}
                    transition={{
                      ...stageHeadlineCustomMotionProps.transition,
                      delay:
                        (stageHeadlineCustomMotionProps.transition?.delay ||
                          0) +
                        index * 0.15,
                    }}
                  >
                    {entry}
                  </StyledMotionSpan>
                </Box>
              ))}
            </Headline>
            {linkTo && (
              <Box
                sx={(theme) => ({
                  mt: 16,
                  overflow: 'hidden',
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                })}
              >
                <StyledMotionDiv
                  animate={isBackgroundReady ? 'ready' : 'default'}
                  {...stageButtonMotionProps}
                >
                  <Button
                    variant="primary"
                    to={linkTo.url}
                    isExternal={linkTo.isExternal}
                  >
                    <FormattedMessage id="button.readFurther" />
                  </Button>
                </StyledMotionDiv>
              </Box>
            )}
          </ColumnWrapper>
        </FlexWrapper>
        {fullViewOrWidescreen && !hideScrollButton && (
          <Box
            component={motion.span}
            animate={isBackgroundReady ? 'ready' : 'default'}
            {...stageFadeInMotionProps}
            sx={(theme) => ({
              color: 'common.white',
              position: 'fixed',
              left: theme.spacing(8),
              bottom: 0,
              textAlign: 'center',
              zIndex: 1,
              cursor: 'pointer',
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
            onClick={handleScrollTo}
          >
            <Headline component="span" variant="h7">
              <FormattedMessage id="label.scroll" />
            </Headline>
            <Box
              component={motion.span}
              animate={isBackgroundReady ? 'ready' : 'default'}
              {...stageScrollToLineMotionProps}
              sx={() => ({
                display: 'block',
                width: '2px',
                height: '20px',
                mx: 'auto',
                backgroundColor: 'common.white',
              })}
            />
          </Box>
        )}
      </Container>
      {teaserLinkTo && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            position: 'absolute',
            right: 0,
            bottom: -44,
            height: 88,
            width: '80%',
            overflow: 'hidden',
            zIndex: 1,
            [theme.breakpoints.up('md')]: {
              width: '50%',
            },
            [theme.breakpoints.up('lg')]: {
              height: 146,
              bottom: -73,
            },
          })}
        >
          <Callout
            component={motion.div}
            animate={isBackgroundReady ? 'ready' : 'default'}
            {...stageTeaserMotionProps}
            title={teaserLinkTo.title as string}
            titleClamp={1}
            theme="dark"
            size="medium"
            linkTo={{
              ...teaserLinkTo,
              label: <FormattedMessage id="button.readFurther" />,
            }}
            background={teaserImage}
            sx={(theme) => ({
              width: '100%',
              [theme.breakpoints.down('md')]: {
                '& .MuiPaper-root': {
                  backgroundImage: 'none',
                },
              },
            })}
          />
        </Box>
      )}
    </Section>
  )
}
