import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { navigate } from 'gatsby'
import { Box } from '@mui/material'

import theme from '@config/theme'
import SearchInput from '@components/core/ui/formfields/searchinput'
import useContentfulSearch from '@components/system/hooks/contentful/useContentfulSearch'
import { pathToLocalePath } from '@utils/string-tools'

interface IHorizontalNav {
  toggleMenu: (open: boolean) => void
  menuState: boolean
}

export default function MobileSearch({
  toggleMenu,
  menuState,
}: IHorizontalNav): ReactElement {
  const { locale, messages } = useIntl()
  const [results, query, setQuery] = useContentfulSearch()

  function closeMenu() {
    toggleMenu(false)
  }

  function keyDownHandler(ev: React.KeyboardEvent<HTMLInputElement>) {
    if ((ev as any).key === 'Enter') {
      navigate(pathToLocalePath(`/suche/#searchquery=${query}`, locale))

      setTimeout(closeMenu, 500)
    }
  }

  useEffect(() => {
    menuState === true && setQuery('')
  }, [menuState])

  return (
    <Box component="div">
      <Box
        sx={{
          px: 6,
          borderBottom: '1px solid',
          borderColor: theme.palette.grey[300],
          width: '100%',
          [theme.breakpoints.up('md')]: {
            px: 8,
          },
        }}
      >
        <SearchInput
          type="compact"
          value={query}
          placeholder={messages['search.placeholder'] as string}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setQuery(e.target.value)
          }
          onKeyDown={keyDownHandler}
          onClick={() => {
            navigate(pathToLocalePath(`/suche/#searchquery=${query}`, locale))
            toggleMenu(false)
          }}
          // withoutValidation
        />
      </Box>
    </Box>
  )
}
