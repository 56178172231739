import { getGlobalProp } from '@utils/props'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulGlobalMedia = (
  filterEntries: SOG.Props.IGlobalMedia[]
): SOG.Props.IGlobalMedia[] => {
  const { locale } = useIntl()
  const { globalMedia } = useStaticQuery(
    graphql`
      query {
        globalMedia: allContentfulGlobalMedia {
          nodes {
            ...GlobalMediaFragment
          }
        }
      }
    `
  )

  return filterEntries
    ? filterEntries
        .map((entry: SOG.Props.IGlobalMedia) =>
          globalMedia.nodes.find(
            (filterEntry: SOG.Contentful.IGlobalMedia) =>
              filterEntry.id === entry.id
          )
        )
        .filter(
          (entry: SOG.Contentful.IGlobalMedia) => entry.node_locale === locale
        )
        .map((entry: SOG.Contentful.IGlobalMedia) => getGlobalProp(entry))
    : globalMedia.nodes
        .filter(
          (entry: SOG.Contentful.IGlobalMedia) => entry.node_locale === locale
        )
        .map((entry: SOG.Contentful.IGlobalMedia) => getGlobalProp(entry))
}
export default useContentfulGlobalMedia
