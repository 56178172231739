import React from 'react'
import { Box, Breakpoint } from '@mui/material'

import Image from '@components/core/media/image'
import Section from '@components/core/container/section'
import QuoteComponent from '@components/core/text/quote'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'
import {
  addDelayToWhileInView,
  moduleSlideLeftMotionProps,
  moduleSlideRightMotionProps,
} from '@utils/animation'

export type QuoteProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    author: string
    copy: SOG.Props.RichText
    position?: string
    image?: SOG.Props.Image
    theme?: SOG.Contentful.Theme
    maxWidth?: boolean | Breakpoint
  }

export default function Quote({
  contentful_id,
  author,
  position,
  copy,
  image,
  theme,
  maxWidth,
  sx,
}: QuoteProps): React.ReactElement {
  return (
    <Section id={contentful_id} theme={theme} maxWidth={maxWidth} sx={sx}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          mt: 5,
          [theme.breakpoints.up('md')]: {
            mt: 0,
            flexDirection: 'unset',
          },
        })}
      >
        <StyledMotionDiv
          {...moduleSlideLeftMotionProps}
          sx={{
            width: '100%',
            zIndex: 1,
          }}
        >
          <QuoteComponent
            theme={theme}
            author={author}
            position={position}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                '& q': {
                  fontSize: '32px',
                  lineHeight: '40px',
                },
              },
            })}
          >
            {copy}
          </QuoteComponent>
        </StyledMotionDiv>
        {image && (
          <StyledMotionDiv
            {...moduleSlideRightMotionProps}
            whileInView={addDelayToWhileInView(
              moduleSlideRightMotionProps.whileInView,
              0.2
            )}
            sx={(theme) => ({
              alignSelf: 'flex-end',
              width: '85%',
              [theme.breakpoints.up('md')]: {
                alignSelf: 'unset',
                width: '45%',
                ml: 'auto',
                mt: 0,
                mr: 0,
              },
            })}
          >
            <Image {...image} />
          </StyledMotionDiv>
        )}
      </Box>
    </Section>
  )
}
