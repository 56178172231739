import { ListItem } from './tocList'

export function createTocList(
  modules: Array<
    SOG.Props.IDefault &
      SOG.Contentful.INodeDefaults & {
        year: string
      }
  >
): Array<ListItem> {
  return modules
    .filter((module) => !!module.year && !!module.contentful_id)
    .map((module) => {
      return {
        id: module.contentful_id,
        label: module.year,
      }
    })
}
