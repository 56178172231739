import React, {
  useState,
  useEffect,
  ReactElement,
  KeyboardEvent,
  useRef,
} from 'react'
import { Link } from 'gatsby'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

import theme from '@config/theme'
import Icon from '@components/core/ui/icon'
import { IMenuState } from './horizontalNavItem'
import SubNav from './horizontalSubNav'

export interface ISubNavItem {
  item: SOG.Props.LinkTo
  isOpen: boolean
  isFocused: boolean
  onNavigate: () => void
  level: number
  onMouseEnter: () => void
  isVisible?: boolean
  transitionDelay: number
}

const SxLink = styled(Link)({})

export default function SubNavItem({
  item,
  isOpen,
  isFocused,
  onNavigate,
  level,
  onMouseEnter,
  isVisible,
}: ISubNavItem): ReactElement {
  const [subMenu, setSubMenu] = useState<IMenuState>({
    open: isOpen || false,
    trigger: 'default',
  })
  // TODO: correct GatsbyLink type must be set/ imported somehow
  // not working Link<{}>, Link<unknown>, HTMLAnchorElement
  const navItemRef = useRef<any>(null)
  const itemLabelId = item.label?.replace(/\s+/g, '').toLowerCase()
  const itemDescId = `${item.label?.replace(/\s+/g, '').toLowerCase()}_desc`

  function handleItemKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'ArrowRight') {
      setSubMenu({ open: true, trigger: 'key' })
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  useEffect(() => {
    if (isFocused) {
      navItemRef.current?.focus()
    }
  }, [isFocused])

  useEffect(() => {
    setSubMenu({ open: isOpen, trigger: 'default' })
  }, [isOpen])

  useEffect(() => {
    if (!subMenu.open && subMenu.trigger === 'key' && isFocused) {
      // Escape or ArrowLeft on subMenu
      navItemRef.current?.focus()
    }
  }, [subMenu])

  return (
    <li role="none">
      <Box
        sx={{
          display: 'flex',
          padding: theme.spacing(3, 0),
          opacity: 0.4,
          lineHeight: 1.1,
          alignItems: 'center',
          transition: `opacity 0s ${theme.transitions.easing.easeOut} 0s`,
          ...(subMenu.open === true &&
            !!item.children && {
              color: 'text.secondary',
            }),
          ...(isVisible === true && {
            opacity: 1,
            transition: ({ transitionDelay }: { transitionDelay: number }) =>
              `opacity 1s ${theme.transitions.easing.easeOut} ${transitionDelay}s`,
          }),
        }}
      >
        <Box
          onMouseEnter={onMouseEnter}
          sx={{
            display: 'flex',
          }}
        >
          <SxLink
            ref={navItemRef}
            role="menuitem"
            aria-haspopup={item.children ? true : false}
            aria-expanded={
              item.children ? (subMenu.open ? true : false) : undefined
            }
            to={item.url}
            partiallyActive
            tabIndex={-1}
            onKeyDown={(ev) => {
              if (item.children && item.children?.length > 0) {
                handleItemKeyDown(ev)
              }
            }}
            onClick={onNavigate}
            sx={{
              position: 'relative',
              fontSize: 'medium',
              fontWeight: 400,
              textDecoration: 'none',
              '&:hover > span': {
                color: 'text.secondary',
              },
            }}
          >
            <span>{item.label}</span>
          </SxLink>
          {!!item.children?.length && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: theme.spacing(2),
              }}
            >
              <Icon name="ChevronRight" fontSize="small" />
            </Box>
          )}
        </Box>
      </Box>
      {item.children && item.children.length > 0 && (
        <SubNav
          items={item.children}
          level={level + 1}
          menuState={subMenu}
          returnFocus={() => {
            setSubMenu({ open: false, trigger: 'key' })
          }}
          onNavigate={onNavigate}
          aria-labelledby={itemLabelId}
          aria-describedby={itemDescId}
          isVisible
        />
      )}
    </li>
  )
}
