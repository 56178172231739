import React from 'react'
import { Box, Grid } from '@mui/material'

import Section from '@components/core/container/section'
import Image from '@components/core/media/image'
import Callout from '@components/core/ui/callout'
import { FormattedMessage } from 'react-intl'
import ModuleSection from '@components/core/container/moduleSection'
import { motion } from 'framer-motion'
import {
  moduleSlideLeftMotionProps,
  moduleSlideRightMotionProps,
  moduleStaggerChildUpMotionProps,
  moduleStaggerParentMotionProps,
} from '@utils/animation'

export type NewsProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption: string
    headline: string
    copy: SOG.Props.RichText
    entries: SOG.Props.IPageArticleDetail[]
    linkTo?: SOG.Props.LinkTo
    theme?: SOG.Contentful.Theme
  }

export default function News({
  caption,
  headline,
  copy,
  theme,
  entries,
  linkTo,
}: NewsProps): React.ReactElement {
  const mainArticle = entries.slice(0, 1)[0] ?? []
  const subArticles = entries.slice(1)

  return (
    <Section>
      <ModuleSection
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={
          linkTo
            ? {
                ...linkTo,
                label: <FormattedMessage id="button.readFurther" />,
              }
            : undefined
        }
        wrapped
      >
        <Box
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            mt: 10,
            [theme.breakpoints.up('lg')]: {
              flexDirection: 'row',
              mt: 20,
              mb: 24,
            },
          })}
        >
          <Image
            component={motion.div}
            {...moduleSlideLeftMotionProps}
            {...mainArticle.image}
            sx={(theme) => ({
              position: 'relative',
              width: '80%',
              ml: 'auto',
              [theme.breakpoints.up('lg')]: {
                width: '70%',
              },
            })}
          />

          <Callout
            component={motion.div}
            {...moduleSlideRightMotionProps}
            theme={theme}
            title={mainArticle.headline}
            linkTo={{
              ...mainArticle.linkTo,
              label: <FormattedMessage id="button.readFurther" />,
            }}
            sx={(theme) => ({
              position: 'relative',
              width: '80%',
              top: theme.spacing(-10),
              zIndex: 1,
              [theme.breakpoints.up('lg')]: {
                position: 'absolute',
                top: 'auto',
                bottom: theme.spacing(-12),
                left: 0,
                width: '50%',
              },
            })}
          />
        </Box>

        {subArticles && (
          <Grid>
            <Grid
              container
              component={motion.div}
              {...moduleStaggerParentMotionProps}
              columnSpacing={10}
              rowSpacing={4}
            >
              {subArticles.map((entry, index) => (
                <Grid
                  component={motion.div}
                  {...moduleStaggerChildUpMotionProps}
                  item
                  key={index}
                  xs={12}
                  md={4}
                  sx={(theme) => ({
                    position: 'relative',
                    marginTop: '16px',
                    '& > a > div': {
                      [theme.breakpoints.down('lg')]: {
                        padding: '0 !important',
                      },
                    },
                    '&:not(:last-child)::after': {
                      content: '" "',
                      position: 'absolute',
                      border: '1px solid',
                      borderColor: theme.palette.background.medium,
                      [theme.breakpoints.up('md')]: {
                        height: '80%',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '90%',
                        left: '40px',
                        bottom: '-15%',
                      },
                    },
                  })}
                >
                  <Callout
                    title={entry.headline}
                    linkTo={{
                      ...entry.linkTo,
                      label: <FormattedMessage id="button.readFurther" />,
                    }}
                    size="small"
                    sx={{ height: '100%', width: '100%' }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </ModuleSection>
    </Section>
  )
}
