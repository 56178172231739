import React, { ReactElement, useState, RefObject } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Box, IconButton } from '@mui/material'

import Icon from '../../icon'
import TextInput from '../textinput'

export type SearchInputProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    type?: 'default' | 'compact'
    value?: string
    placeholder: string | 'Placeholder'
    error?: string | boolean | false
    initialValue?: string | 'text'
    defaultValue?: string | 'text'
    withHints?: boolean
    isSubmitted?: boolean
    onClear?: () => void
  }

export default function SearchInput({
  placeholder,
  initialValue = '',
  defaultValue = '',
  onChange,
  onClear,
  onClick,
  variant = 'standard',
  type,
  value: controlledValue,
  isSubmitted = false,
  sx,
  ...props
}: SearchInputProps): ReactElement {
  // const inputRef = useRef<HTMLDivElement>()
  const [value, setValue] = useState(initialValue || defaultValue || '')
  const [inputRef] = useState<RefObject<HTMLDivElement>>(React.createRef())
  // const inputRef: RefObject<HTMLDivElement> = React.createRef()
  const isCompact = type === 'compact'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (controlledValue !== undefined) {
      setValue(e.target.value)
    }
    if (onChange) {
      onChange(e)
    }
  }

  const clearFieldHandler = () => {
    inputRef.current?.focus()
    if (!controlledValue !== undefined) {
      setValue('')
    }
    if (onClear) {
      onClear()
    }
  }

  return (
    <Box
      sx={[sx as SOG.Props.SxInArray, { position: 'relative', width: '100%' }]}
    >
      {variant === 'filled' && (
        <TextInput
          ref={inputRef}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleChange}
          value={controlledValue !== undefined ? controlledValue : value}
          data-testid="input"
          id="searchfield"
          type="text"
          {...props}
        />
      )}
      {variant === 'standard' && (
        <TextField
          variant="standard"
          sx={{
            height: '72px',
            '& .MuiInput-root': {
              height: '100%',
              '&:before': {
                borderBottom: '0px',
              },
              '&:after': {
                borderBottom: 'transparent',
              },
              '&:hover': {
                borderBottom: 'transparent',
                '&::before': {
                  borderBottom: '0px',
                },
                '&::after': {
                  borderBottom: 'transparent',
                },
              },
            },
          }}
          InputProps={{ disableUnderline: true }}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleChange}
          onFocus={clearFieldHandler}
          value={controlledValue || value}
          data-testid="input"
          id="searchfield"
          type="text"
          {...props}
        />
      )}
      {(isCompact || (controlledValue || value).length > 2) && (
        <IconButton
          component={'button'}
          onClick={
            isSubmitted
              ? clearFieldHandler
              : (onClick as React.MouseEventHandler<HTMLButtonElement>)
          }
          color="inherit"
          sx={(theme) => ({
            position: 'absolute',
            alignSelf: 'center',
            top: '50%',
            right: variant === 'standard' ? theme.spacing(-4) : 4,
            padding: variant === 'standard' ? 4 : 3,
            transform: 'translateY(-50%)',
          })}
        >
          {!isSubmitted && isCompact && <Icon name="Search" />}
          {!isSubmitted && !isCompact && <Icon name="ArrowUpRight" />}
          {isSubmitted && <Icon name="Close" />}
        </IconButton>
      )}
    </Box>
  )
}
