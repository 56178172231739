import React from 'react'

import Section from '@components/core/container/section'
import ToC from '@components/core/container/toc'
import { createTocList } from '@components/core/container/toc/createTocList'
import PageModule from '@components/system/PageModule'
import { SxProps, Theme } from '@mui/material'
import YearItem, { YearItemProps } from './parts/item'

export type ModuleHistoryProps = SOG.Props.IDefault & {
  items: Array<
    | SOG.Contentful.IModuleQuote
    | SOG.Contentful.IModuleTeaserContent
    | SOG.Contentful.IModuleTeaserContentTextOnly
    | YearItemProps
  >
}

export default function ModuleHistory({ items }: ModuleHistoryProps) {
  function renderItems() {
    return items.map((item, i) => {
      const sxProps: SxProps<Theme> = (theme) => ({
        marginBottom: theme.spacing(i === items.length - 1 ? 0 : 20),
        [theme.breakpoints.up('xl')]: {
          marginBottom: theme.spacing(i === items.length - 1 ? 0 : 30),
        },
      })

      switch (item.__typename) {
        case 'ContentfulModuleHistoryItem':
          return (
            <YearItem
              key={item.contentful_id}
              {...(item as YearItemProps)}
              sx={sxProps}
            />
          )
        default:
          return (
            <PageModule
              key={item.contentful_id}
              pagemodule={item}
              sx={sxProps}
            />
          )
      }
    })
  }

  return (
    <Section>
      <ToC items={createTocList(items)}>{renderItems()}</ToC>
    </Section>
  )
}
