import React, { ReactElement, useEffect, useRef } from 'react'
import { styled } from '@mui/system'

import { useIntl } from 'react-intl'
import { WidgetInstance } from 'friendly-challenge'

export type FriendlyCaptchaProps = SOG.Props.IDefault & {
  name: string
  setCaptchacode: () => void
}

const SxFriendlyCaptcha = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}))

export default function FriendlyCaptcha({
  name,
  setCaptchacode,
}: FriendlyCaptchaProps): ReactElement {
  const intl = useIntl()
  const container = useRef()
  const widget = useRef()

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        puzzleEndpoint: 'https://api.friendlycaptcha.com/api/v1/puzzle',
        language: intl.locale,
        doneCallback: doneCallback,
        sitekey: 'FCMMJ5NUJV5QAKCR',
      })
    }

    return () => {
      if (widget.current != undefined) widget.current.reset()
    }
  }, [container])

  function doneCallback(solution: string | null) {
    setCaptchacode(solution)
  }

  return (
    <SxFriendlyCaptcha
      data-testid="friendlycaptcha"
      data-usercentrics="Friendly Captcha"
    >
      <div
        className="frc-captcha"
        ref={container}
        id={`friendly-captcha-${name}`}
        data-start="none"
      />
    </SxFriendlyCaptcha>
  )
}
