import React from 'react'

import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import YouTube from '@components/core/media/youtube'
import Copy from '@components/core/text/copy'
import Image from '@components/core/media/image'

export type VideoImageProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    copy?: SOG.Props.RichText
    media: SOG.Props.IGlobalVideoImage
  }

export default function VideoImage({
  caption,
  headline,
  copy,
  media,
}: VideoImageProps): React.ReactElement {
  const isVideoOnly = !caption && !headline
  return (
    <Section>
      <ModuleSection caption={caption} headline={headline}>
        {media.sys.contentType.sys.id === 'globalVideo' && (
          <YouTube
            {...media}
            sx={(theme) => ({
              mt: !isVideoOnly ? 10 : undefined,
              [theme.breakpoints.up('lg')]: {
                mt: !isVideoOnly ? 20 : undefined,
              },
            })}
          />
        )}
        {media.sys.contentType.sys.id === 'globalImage' && (
          <Image
            {...media.image}
            sx={(theme) => ({
              mt: !isVideoOnly ? 10 : undefined,
              [theme.breakpoints.up('lg')]: {
                mt: !isVideoOnly ? 20 : undefined,
              },
            })}
          />
        )}
        {copy && (
          <Copy
            sx={(theme) => ({
              mt: 8,
              [theme.breakpoints.up('md')]: { columns: 2 },
              [theme.breakpoints.up('lg')]: { mt: 16 },
            })}
          >
            {copy}
          </Copy>
        )}
      </ModuleSection>
    </Section>
  )
}
