/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, ReactElement, useState } from 'react'

export interface IGlobalStateContext {
  setVideoAsShown: (fileName: string) => void
  hasVideoBeenShown: (fileName: string) => boolean
  setPopupAsShown: (fileName: string) => void
  hasPopupBeenShown: (fileName: string) => boolean
  globalFilters: SOG.Props.PressFilterValues
  setGlobaleFilters: (values: SOG.Props.PressFilterValues) => void
  globalFilterResultsTotal: number
  setGlobalFilterResultsTotal: (value: number) => void
}

const defaultFiltersState: SOG.Props.PressFilterValues = {
  topics: [],
  brands: [],
  mediums: [],
  targetGroups: [],
  formats: [],
}

export const GlobalStateContext = React.createContext<IGlobalStateContext>({
  setVideoAsShown: (fileName: string) => {
    return
  },
  hasVideoBeenShown: (fileName: string) => {
    return false
  },
  setPopupAsShown: (fileName: string) => {
    return
  },
  hasPopupBeenShown: (fileName: string) => {
    return false
  },
  globalFilters: defaultFiltersState,
  setGlobaleFilters: (values: SOG.Props.PressFilterValues) => {
    return
  },
  globalFilterResultsTotal: 0,
  setGlobalFilterResultsTotal: (value: number) => {
    return
  },
})

export type GlobalStateProviderProps = {
  children: ReactNode
}

export default function GlobalStateProvider({
  children,
}: GlobalStateProviderProps): ReactElement {
  const [videosShown, setVideosShow] = useState<string[]>([])
  const [popupsShown, setPopupsShown] = useState<string[]>([])
  const [globalFilters, setGlobaleFilters] =
    useState<SOG.Props.PressFilterValues>(defaultFiltersState)
  const [globalFilterResultsTotal, setGlobalFilterResultsTotal] =
    useState<number>(0)
  function setVideoAsShown(fileName: string) {
    setVideosShow([...videosShown, fileName])
  }
  function hasVideoBeenShown(fileName: string): boolean {
    return videosShown.includes(fileName)
  }

  function setPopupAsShown(id: string) {
    setPopupsShown([...popupsShown, id])
  }

  function hasPopupBeenShown(id: string): boolean {
    return popupsShown.includes(id)
  }

  return (
    <GlobalStateContext.Provider
      value={{
        hasVideoBeenShown,
        setVideoAsShown,
        globalFilters,
        setGlobaleFilters,
        globalFilterResultsTotal,
        setGlobalFilterResultsTotal,
        setPopupAsShown,
        hasPopupBeenShown,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
