import { defaultLang } from '../translations/'

export const capitalize = (str: string): string =>
  str.replace(/./, (w) => w.toUpperCase())

export const numberToByteSize = (size: number): string => {
  const sizeRange = Math.floor(Math.log(size) / Math.log(1024))
  const sizeConverted = size / Math.pow(1024, sizeRange)
  return `${sizeConverted.toFixed(2)} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][sizeRange]
  }`
}

export const timestampToLocaleDate = (
  dateString: string,
  locale = 'de-DE'
): string => {
  const date = new Date(dateString)
  return date.toLocaleDateString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
}

export const validateEmail = (email: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true
  }
  return false
}

export const pathToLocalePath = (path: string, locale: string) =>
  locale === defaultLang ? path : `/${locale}${path}`
