import { Theme, useMediaQuery } from '@mui/material'
type BreakPointValuesType = {
  sm: any
  md: any
  lg: any
}
export default function useBreakpointValue(values: BreakPointValuesType) {
  const md = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('md', 'lg')
  )
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  return lg ? values.lg : md ? values.md : values.sm
}
