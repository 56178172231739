import React, { ReactElement, ElementType } from 'react'
import {
  Accordion as AccordionMUI,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { MotionProps } from 'framer-motion'
import clsx from 'clsx'

import Icon from '../icon'
import Headline from '@components/core/text/headline'
import FontSize from '@config/theme/definitions/fontSize'

export type AccordionProps = SOG.Props.IDefault &
  MotionProps & {
    /**
     * The Title shown
     * Displays Prop Tables with these components
     * @default null
     */
    title: string
    variant?: 'outlined'
    /**
     * The Icon set used by the Component
     * @default null
     */
    chevron?: 'arrow' | 'plusMinus'
    expanded?: boolean
    defaultExpanded?: boolean
    onChange?: (
      event: React.SyntheticEvent<Element, Event>,
      expanded: boolean
    ) => void
    component?: ElementType<any>
    theme?: SOG.Contentful.Theme
  }

export default function Accordion({
  title,
  children,
  expanded,
  defaultExpanded,
  chevron,
  variant,
  onChange,
  initial,
  whileInView,
  viewport,
  component,
  theme,
  sx,
}: AccordionProps): ReactElement {
  const Chevron =
    chevron === 'arrow' ? (
      <Icon
        name="ChevronDown"
        color="inherit"
        sx={(sxTheme) => ({
          fontSize: FontSize.get('2lg'),
          color:
            theme === 'light'
              ? sxTheme.palette.common.black
              : sxTheme.palette.common.white,
        })}
      />
    ) : (
      <Icon
        name="ChevronDown"
        color="inherit"
        sx={(sxTheme) => ({
          fontSize: FontSize.get('2lg'),
          color:
            theme === 'light'
              ? sxTheme.palette.common.black
              : sxTheme.palette.common.white,
        })}
      />
    )
  const additionalProps = {
    ...(component && {
      component,
      initial,
      whileInView,
      viewport,
    }),
  }
  return (
    <AccordionMUI
      className={clsx({
        'MuiAccordion-outlined': variant === 'outlined',
      })}
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={onChange}
      {...additionalProps}
      sx={(sxTheme) => ({
        backgroundColor:
          theme === 'dark'
            ? sxTheme.palette.background.dark
            : theme === 'medium'
            ? sxTheme.palette.background.medium
            : sxTheme.palette.background.light,
        sx,
      })}
    >
      <AccordionSummary
        expandIcon={Chevron}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: 'common.white',
            '& > svg': {
              color:
                (theme !== 'light' && theme !== 'medium' && theme !== 'dark') ||
                theme === 'light'
                  ? 'common.black'
                  : 'common.white',
            },
          },
        }}
      >
        <Headline
          variant="h5"
          component="span"
          sx={{
            color:
              (theme !== 'light' && theme !== 'medium' && theme !== 'dark') ||
              theme === 'light'
                ? 'common.black'
                : 'common.white',
          }}
        >
          {title}
        </Headline>
      </AccordionSummary>
      <AccordionDetails
        sx={(sxTheme) => ({
          color:
            theme === 'light' || theme === undefined || theme === null
              ? sxTheme.palette.common.black
              : sxTheme.palette.common.white,
        })}
      >
        {children}
      </AccordionDetails>
    </AccordionMUI>
  )
}
