import React from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import { getLinkComponentAndProps } from '@utils/component'
import {
  brandwallItemMotionProps,
  brandwallItemIconMotionProps,
  moduleSlideUpMotionProps,
  brandwallItemImageMotionProps,
} from '@utils/animation'
import Image from '@components/core/media/image'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'

export type BrandWallProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption: string
    headline: string
    copy: SOG.Props.RichText
    brands: SOG.Props.IGlobalBrand[]
    linkTo?: SOG.Props.LinkTo
  }

export default function BrandWall({
  caption,
  headline,
  copy,
  brands = [],
  linkTo,
}: BrandWallProps): React.ReactElement {
  return (
    <Section>
      <ModuleSection
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={
          linkTo
            ? {
                ...linkTo,
                label: <FormattedMessage id="button.learnMore" />,
              }
            : undefined
        }
        wrapped
      >
        <Box
          sx={(theme) => ({
            mt: 10,
            [theme.breakpoints.up('lg')]: {
              mt: 20,
            },
          })}
        >
          <Grid container spacing={8}>
            {brands.map(({ icon, image, linkTo }, index) => (
              <Grid
                component={motion.div}
                {...moduleSlideUpMotionProps}
                item
                key={index}
                xs={12}
                md={6}
                sx={(theme) => ({
                  marginTop: 8,
                  paddingTop: '0 !important',
                  height: 193,
                  [theme.breakpoints.up('lg')]: {
                    height: 320,
                  },
                })}
              >
                <StyledMotionDiv
                  whileHover="hover"
                  {...brandwallItemMotionProps}
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    color: 'text.inverted',
                    backgroundColor: 'background.grey.600',
                    overflow: 'hidden',
                    '&:hover': {
                      backgroundColor: 'background.dark',
                    },
                    transition: 'background-color 500ms ease',
                  }}
                >
                  <Image
                    {...image}
                    component={motion.div}
                    {...brandwallItemImageMotionProps}
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <IconButton
                    {...getLinkComponentAndProps(linkTo)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      borderRadius: 0,
                    }}
                    color="inherit"
                    size="small"
                  >
                    <StyledMotionDiv
                      {...brandwallItemIconMotionProps}
                      sx={{
                        width: '50%',
                        display: 'inline-block',
                        justifyContent: 'center',
                      }}
                      dangerouslySetInnerHTML={{ __html: icon?.content }}
                    />
                  </IconButton>
                </StyledMotionDiv>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ModuleSection>
    </Section>
  )
}
