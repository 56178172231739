import React, { ComponentType, ReactElement } from 'react'
import { Paper } from '@mui/material'
import clsx from 'clsx'
import { MotionProps } from 'framer-motion'

export type ComponentContainerProps = SOG.Props.IDefault &
  MotionProps & {
    theme?: SOG.Contentful.Theme
    size?: 'small' | 'medium' | 'large' | 'xlarge'
    component?: ComponentType
  }

export default function ComponentContainer({
  theme,
  children,
  size,
  component,
  initial,
  animate,
  variants,
  transition,
  whileInView,
  viewport,
  sx,
}: ComponentContainerProps): ReactElement {
  const className = clsx({
    'MuiPaper-themeLight': theme === 'light',
    'MuiPaper-themeMedium': theme === 'medium',
    'MuiPaper-themeDark': theme === 'dark',
    'MuiPaper-sizeSmall': size === 'small',
    'MuiPaper-sizeMedium': size === 'medium',
    'MuiPaper-sizeLarge': size === 'large',
    'MuiPaper-sizeXLarge': size === 'xlarge',
  })
  const additionalProps = {
    ...(component && {
      component: component,
      initial,
      animate,
      variants,
      transition,
      whileInView,
      viewport,
    }),
  }
  return (
    <Paper className={className} sx={sx} {...additionalProps}>
      {children}
    </Paper>
  )
}
