import { Components, Theme } from '@mui/material'

export const TypographyOverrides = (
  theme: Theme
): Components['MuiTypography'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {},
  },
})
