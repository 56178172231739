import React, { useState, useCallback, RefObject } from 'react'

export default function useCallbackRef() {
  const [ref, setRef] = useState<null | RefObject<HTMLElement>>(null)
  const fn = useCallback((node: RefObject<HTMLElement>) => {
    setRef(node)
  }, [])

  return [ref, fn]
}
