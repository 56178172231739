import React, { useState } from 'react'
import { Grid, Box, Container } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import Headline from '@components/core/text/headline'
import { motion } from 'framer-motion'
import {
  moduleStaggerChildUpMotionProps,
  moduleStaggerParentMotionProps,
  moduleSlideUpMotionProps,
} from '@utils/animation'

export type JobsProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption: string
    headline: string
    copy: SOG.Props.RichText
    entries: SOG.Props.IGlobalJob[]
    linkTo?: SOG.Props.LinkTo
    theme?: SOG.Contentful.Theme
  }

export type JobProps = SOG.Props.IDefault & {
  caption: string
  title: string
  linkTo: SOG.Props.LinkTo
  isActive: boolean
  onMouseOver: () => void
  onMouseOut: () => void
  theme?: SOG.Contentful.Theme
}

function Job({
  caption,
  title,
  linkTo,
  isActive,
  onMouseOver,
  onMouseOut,
  theme,
}: JobProps) {
  return (
    <Box
      component={motion.a}
      {...moduleSlideUpMotionProps}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      href={linkTo.url}
      target="_blank"
      rel="noreferrer"
      sx={(theme) => ({
        height: '100%',
        pr: 12,
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
          pt: 8,
          pb: 8,
        },
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      })}
    >
      <Box>
        <Headline
          variant="caption"
          sx={(sxTheme) => ({
            mb: 2,
            color:
              theme === 'medium' || theme === 'dark'
                ? sxTheme.palette.common.white
                : undefined,
            [sxTheme.breakpoints.down('md')]: {},
            [sxTheme.breakpoints.up('md')]: {
              mb: 4,
            },
          })}
        >
          {caption}
        </Headline>
        <Headline
          variant="h5"
          component="p"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              mt: 2,
              mb: 3,
            },
            [theme.breakpoints.up('md')]: {
              mt: 2,
              mb: 6,
            },
          })}
        >
          {title}
        </Headline>
      </Box>
      <Button
        variant="text"
        to={linkTo.url}
        isExternal={linkTo.isExternal}
        isActive={isActive}
        theme={theme}
        sx={{
          alignSelf: 'flex-start',
          height: '30px',
        }}
      >
        {linkTo.label}
      </Button>
    </Box>
  )
}

export default function Jobs({
  caption,
  headline,
  theme,
  copy,
  entries = [],
  linkTo,
}: JobsProps): React.ReactElement {
  const [hoverIndex, setIsHoverIndex] = useState(-1)
  return (
    <Section maxWidth={false}>
      <Container maxWidth="lg">
        <ModuleSection
          caption={caption}
          headline={headline}
          copy={copy}
          linkTo={
            linkTo
              ? {
                  ...linkTo,
                  label: <FormattedMessage id="button.toJobPage" />,
                }
              : undefined
          }
        />
      </Container>
      <Box
        sx={(sxTheme) => ({
          backgroundColor:
            theme === 'dark'
              ? sxTheme.palette.background.dark
              : theme === 'medium'
              ? sxTheme.palette.background.medium
              : sxTheme.palette.background.light,
          color:
            theme === 'light' ? sxTheme.palette.text.primary : 'text.inverted',
          mt: 10,
          [sxTheme.breakpoints.up('md')]: {
            mt: 16,
          },
        })}
      >
        <Container maxWidth="lg">
          <Grid
            container
            component={motion.div}
            {...moduleStaggerParentMotionProps}
            sx={(theme) => ({
              width: '100%',
              display: 'flex',
              py: 12,
              [theme.breakpoints.up('md')]: {
                py: 14,
              },
            })}
          >
            {entries.map((entry, index) => (
              <Grid
                item
                component={motion.div}
                {...moduleStaggerChildUpMotionProps}
                key={index}
                sm={12}
                md={4}
                sx={(sxTheme) => ({
                  width: '100%',
                  px: 4,
                  color:
                    theme === null ? sxTheme.palette.common.black : undefined,

                  [sxTheme.breakpoints.down('md')]: {
                    px: 0,
                  },
                  '&:not(:first-child)': {
                    [sxTheme.breakpoints.down('md')]: {
                      pt: 8,
                    },
                  },
                  '&:not(:last-child)': {
                    [sxTheme.breakpoints.down('md')]: {
                      '& > .MuiBox-root': {
                        px: 0,
                      },
                      px: 0,
                      borderBottom: '2px solid',
                      borderColor:
                        theme === 'medium' || theme === 'dark'
                          ? sxTheme.palette.common.white
                          : 'text.secondary',
                      pb: 8,
                    },
                    [sxTheme.breakpoints.up('md')]: {
                      pr: 6,
                      '& > .MuiBox-root': {
                        borderRight: '2px solid',
                        borderColor:
                          theme === 'medium' || theme === 'dark'
                            ? sxTheme.palette.common.white
                            : 'text.secondary',
                      },
                    },
                  },
                })}
              >
                <Job
                  caption={entry.location}
                  title={entry.title}
                  theme={theme}
                  linkTo={{
                    ...entry.linkTo,
                    label: <FormattedMessage id="button.toJobPage" />,
                  }}
                  isActive={index === hoverIndex}
                  onMouseOver={() => setIsHoverIndex(index)}
                  onMouseOut={() => setIsHoverIndex(-1)}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Section>
  )
}
