import { Components, Theme } from '@mui/material'

export const BreadcrumbsOverrides = (
  theme: Theme
): Components['MuiBreadcrumbs'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      '&.MuiBreadcrumbs-themeMedium': {
        color: theme.palette.grey[400],
        li: {
          '&:last-of-type': {
            color: theme.palette.text.inverted,
          },
          '.MuiLink-root:hover': {
            color: theme.palette.text.inverted,
          },
        },
      },
      '&.MuiBreadcrumbs-themeDark': {
        color: theme.palette.text.secondary,
        li: {
          '&:last-of-type': {
            color: theme.palette.text.inverted,
          },
          '.MuiLink-root:hover': {
            color: theme.palette.text.inverted,
          },
        },
      },
    },
    li: {
      '.MuiLink-root:hover': {
        color: theme.palette.text.main,
      },
    },

    separator: {
      margin: 0,
    },
  },
})
