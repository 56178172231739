import Icon from '@components/core/ui/icon'
import MediaOverlay from '@components/core/ui/mediaOverlay'
import { Box, IconButton } from '@mui/material'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

export type VideoPlayerProps = SOG.Props.IDefault & {
  width?: string
  height?: string
  video: SOG.Props.Video
  thumbnail?: SOG.Props.Image
  onDownload?: () => void
  onFullscreen?: () => void
}

const VideoPlayer = ({
  width,
  height,
  video,
  onFullscreen,
  onDownload,
  sx,
}: VideoPlayerProps): ReactElement | null => {
  if (!video) return null
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isHover, setIsHover] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (!videoRef.current) return
    if (!isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [isPlaying])

  const toggleVideo = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <Box
      sx={[
        sx as SOG.Props.SxInArray,
        {
          position: 'relative',
          paddingTop: !height ? 'calc(9 / 16 * 100%)' : undefined,
          overflow: 'hidden',
        },
      ]}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      {!isPlaying && (
        <IconButton
          onClick={toggleVideo}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            color: 'text.inverted',
            zIndex: 1,
          }}
        >
          <Icon name="Play" fontSize="inherit" sx={{ fontSize: '80px' }} />
        </IconButton>
      )}
      {(onFullscreen || onDownload) && (
        <MediaOverlay
          onFullscreen={onFullscreen}
          onDownload={onDownload}
          isOpen={isHover}
        />
      )}
      <Box
        ref={videoRef}
        component="video"
        playsInline
        src={video.file.url}
        controls
        width={width || '100%'}
        height={height || '100%'}
        sx={{
          position: !height ? 'absolute' : undefined,
          top: 0,
          left: 0,
          display: 'block',
          mx: 'auto',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </Box>
  )
}

export default VideoPlayer
