import React from 'react'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import parse from 'html-react-parser'
import { Container, Theme, Box, SxProps } from '@mui/material'
import Image from '@components/core/media/image'
import { getAssetProp, getGlobalProp, isVideoFile } from './props'
import Headline from '@components/core/text/headline'
import List from '@components/core/text/list'
import PageModule from '@components/system/PageModule'
import Quote from '@components/core/text/quote'
import Link from '@components/core/ui/link'
import YouTube from '@components/core/media/youtube'
import VideoPlayer from '@components/core/media/videoPlayer'

export type DocumentNodeType = {
  nodeType: string
  data?: Record<string, unknown>
  content?: Array<DocumentNodeType>
  value?: string
}

export const richTextOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
  },
  renderText: (text) => {
    return parse(
      text
        .replaceAll('\n', '<br>')
        .replaceAll('®', '<sup>®</sup>')
        .replaceAll('©', '<sup>©</sup>')
        .replaceAll('℗', '<sup>℗</sup>')
    )
  },
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      const sxProp = (theme: Theme) => ({
        my: 4,
        '&:first-child': {
          mt: 0,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (node.data.container) {
        return (
          <Container maxWidth="sm" sx={sxProp}>
            <Box component="p" sx={{ my: 0 }}>
              {children}
            </Box>
          </Container>
        )
      }

      return (
        <Box component="p" sx={sxProp}>
          {children}
        </Box>
      )
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      const sxProp = (theme: Theme) => ({
        mb: 4,
        [theme.breakpoints.up('md')]: {
          mb: 8,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (node.data.container) {
        return (
          <Container maxWidth="sm" sx={sxProp}>
            <List type="unordered">{children}</List>
          </Container>
        )
      }

      return (
        <List type="unordered" sx={sxProp}>
          {children}
        </List>
      )
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => {
      const sxProp = (theme: Theme) => ({
        mb: 4,
        [theme.breakpoints.up('md')]: {
          mb: 8,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (node.data.container) {
        return (
          <Container maxWidth="sm" sx={sxProp}>
            <List type="ordered">{children}</List>
          </Container>
        )
      }

      return (
        <List type="ordered" sx={sxProp}>
          {children}
        </List>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <Link
          to={node.data.uri}
          isExternal={true}
          underline="always"
          sx={(theme) => ({
            color: theme.palette.hyperlink.active,
            '&:hover': {
              color: theme.palette.hyperlink.hover,
            },
          })}
        >
          {children}
        </Link>
      )
    },
    // eslint-disable-next-line react/display-name
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const link = node.data?.target?.fields?.linkTo
      return link ? (
        <Link
          to={link.url}
          isExternal={link.isExternal}
          underline="always"
          sx={(theme) => ({
            color: theme.palette.hyperlink.active,
            '&:hover': {
              color: theme.palette.hyperlink.hover,
            },
          })}
        >
          {children}
        </Link>
      ) : (
        children
      )
    },
    // eslint-disable-next-line react/display-name
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const link = node.data?.target?.file
      return link ? (
        <Link
          to={link.url}
          isExternal
          underline="always"
          sx={(theme) => ({
            color: theme.palette.hyperlink.active,
            '&:hover': {
              color: theme.palette.hyperlink.hover,
            },
          })}
        >
          {children}
        </Link>
      ) : (
        children
      )
    },
    [BLOCKS.QUOTE]: (node) => {
      const sxProp = (theme: Theme) => ({
        my: 10,
        [theme.breakpoints.up('md')]: {
          my: 12,
        },
        '&:first-child': {
          mt: 0,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (node.data.container) {
        return (
          <Container maxWidth="sm" sx={sxProp}>
            <Quote
              size="small"
              author={node.content[1]?.content?.[0].value}
              position={node.content[2]?.content?.[0].value}
              sx={sxProp}
            >
              {node.content[0]?.content?.[0].value}
            </Quote>
          </Container>
        )
      }

      return (
        <Quote
          size="small"
          author={node.content[1]?.content?.[0].value}
          position={node.content[2]?.content?.[0].value}
          sx={sxProp}
        >
          {node.content[0]?.content?.[0].value}
        </Quote>
      )
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      const sxProp = (theme: Theme) => ({
        mt: 10,
        mb: 4,
        [theme.breakpoints.up('md')]: {
          mt: 12,
          mb: 8,
        },
        '&:first-child': {
          mt: 0,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (node.data.container) {
        return (
          <Container maxWidth="sm" sx={sxProp}>
            <Headline variant="h5">{children}</Headline>
          </Container>
        )
      }

      return <Headline variant="h5">{children}</Headline>
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      const sxProp = (theme: Theme) => ({
        mt: 8,
        mb: 4,
        [theme.breakpoints.up('md')]: {
          mt: 6,
          mb: 4,
        },
        '&:first-child': {
          mt: 0,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (node.data.container) {
        return (
          <Container maxWidth="sm" sx={sxProp}>
            <Headline variant="h6">{children}</Headline>
          </Container>
        )
      }

      return <Headline variant="h6">{children}</Headline>
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { target, location } = node.data
      if (!target && !location) return null
      const sxProp = (theme: Theme) => ({
        my: 10,
        [theme.breakpoints.up('md')]: {
          my: 12,
        },
        '&:first-child': {
          mt: 0,
        },
        '&:last-child': {
          mb: 0,
        },
      })

      if (target.__typename.includes('ContentfulGlobal')) {
        return node.data.container ? (
          <Container maxWidth="sm" sx={sxProp}>
            {getInlineEntryComponent(target)}
          </Container>
        ) : (
          getInlineEntryComponent(target, sxProp)
        )
      }

      return (
        <PageModule
          key={`${node.data.target.id}`}
          location={location}
          pagemodule={target}
          sx={sxProp}
        />
      )

      console.info('Missing embedded entry', node)
      return <></>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file } = node.data.target
      const assetProp = getAssetProp(node.data.target)
      if (!assetProp) return
      const sxProp = (theme: Theme) => ({
        my: 10,
        [theme.breakpoints.up('md')]: {
          my: 12,
        },
        '&:first-child': {
          mt: 0,
        },
        '&:last-child': {
          mb: 0,
        },
      })
      if (file.contentType.startsWith('image')) {
        return node.data.container ? (
          <Container maxWidth="sm" sx={sxProp}>
            <Image {...(assetProp as SOG.Props.Image)} />
          </Container>
        ) : (
          <Image {...(assetProp as SOG.Props.Image)} sx={sxProp} />
        )
      }

      if (isVideoFile(file)) {
        return node.data.container ? (
          <Container maxWidth="sm" sx={sxProp}>
            <VideoPlayer video={assetProp} />
          </Container>
        ) : (
          <Box
            component="video"
            playsInline
            src={assetProp.file.url}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
            }}
          />
        )
      }

      console.warn('Missing embedded asset', node)
      return <></>
    },
  },
}

function getInlineEntryComponent(target: any, sx?: SxProps<Theme>) {
  switch (target.__typename) {
    case 'ContentfulGlobalVideo':
      const props = getGlobalProp(target) as SOG.Props.IGlobalVideo
      return <YouTube {...props} sx={sx} />
  }
}
