import React from 'react'
import { Box, styled } from '@mui/material'

import Image from '@components/core/media/image'
import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import { FormattedMessage } from 'react-intl'
import { motion } from 'framer-motion'
import {
  moduleSlideLeftMotionProps,
  moduleSlideRightMotionProps,
  moduleSlideUpMotionProps,
} from '@utils/animation'

export type TeaserProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    theme?: SOG.Contentful.Theme
    caption?: string
    headline: string
    copy: SOG.Props.RichText
    images?: SOG.Props.Image[]
    linkTo?: SOG.Props.LinkTo
  }

const FlexWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(6, 0),
    '& > :first-child': {
      padding: '24px',
    },
  },
  marginBottom: '80px',
}))

export default function Teaser({
  caption,
  headline,
  copy,
  images = [],
  linkTo,
  theme,
  sx = {},
}: TeaserProps): React.ReactElement {
  const isTextImage = images.length === 1 && !linkTo
  const isTextButton = images.length === 0 && linkTo
  const isTextOnly = images.length === 0 && !linkTo

  return !images.length ? (
    <Section
      theme={theme}
      paddingTop={!!theme}
      paddingBottom={!!theme && { sm: false }}
      sx={[
        {
          overflow: 'hidden',
          width: '100%',
          marginBottom: '80px',
        },
        sx as SOG.Props.SxInArray,
      ]}
    >
      <Box
        sx={{
          display: 'block',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            mb: !isTextButton && !isTextOnly ? 10 : undefined,
            [theme.breakpoints.up('md')]: {
              width: '100%',
            },
          })}
        >
          {caption && (
            <Headline
              component={motion.span}
              {...moduleSlideRightMotionProps}
              variant="caption"
              sx={(sxTheme) => ({
                display: 'inline-block',
                mb: 2,
                color:
                  theme === 'medium'
                    ? sxTheme.palette.common.white
                    : sxTheme.palette.background.medium,
                [sxTheme.breakpoints.up('md')]: {
                  mb: 4,
                },
              })}
            >
              {caption}
            </Headline>
          )}
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: isTextButton ? 'flex' : undefined,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  pr: isTextButton ? 20 : undefined,
                },
                [theme.breakpoints.up('lg')]: {
                  pr: isTextButton ? 25 : undefined,
                  maxWidth: isTextButton ? '75%' : undefined,
                },
              })}
            >
              <Headline
                component={motion.p}
                {...moduleSlideRightMotionProps}
                variant="h2"
                sx={(theme) => ({
                  mb: !isTextImage ? 4 : undefined,
                  [theme.breakpoints.up('lg')]: {
                    mb: 8,
                  },
                  [theme.breakpoints.down('lg')]: {
                    hyphens: 'auto',
                  },
                })}
              >
                {headline}
              </Headline>
              <Copy
                component={motion.p}
                {...moduleSlideRightMotionProps}
                sx={(theme) => ({
                  mt: 4,
                  [theme.breakpoints.up('lg')]: {
                    mt: 8,
                  },
                  [theme.breakpoints.down('md')]: isTextImage && {
                    display: 'none',
                  },
                })}
              >
                {copy}
              </Copy>
            </Box>
            {linkTo && (
              <Box
                component={motion.div}
                {...moduleSlideLeftMotionProps}
                sx={(theme) => ({
                  mt: 8,
                  alignSelf: 'flex-start',
                  flexShrink: 0,
                  [theme.breakpoints.up('md')]: {
                    mt: !isTextButton ? 16 : 0,
                    ml: isTextButton ? 'auto' : undefined,
                  },
                  [theme.breakpoints.up('lg')]: {
                    mt: isTextButton ? 4 : undefined,
                  },
                })}
              >
                <Button
                  variant={theme === 'medium' ? 'secondary' : 'primary'}
                  to={linkTo.url}
                  isExternal={linkTo.isExternal}
                >
                  <FormattedMessage id="button.readFurther" />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {isTextImage && (
          <Copy
            component={motion.p}
            {...moduleSlideLeftMotionProps}
            sx={(theme) => ({
              mt: 10,
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            {copy}
          </Copy>
        )}
      </Box>
    </Section>
  ) : (
    <Section sx={{ overflowX: 'hidden' }} maxWidth={false}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlexWrapper>
          <Box
            component={motion.div}
            {...moduleSlideRightMotionProps}
            sx={(sxTheme) => ({
              position: 'relative',
              background:
                theme === 'dark'
                  ? sxTheme.palette.background.dark
                  : theme === 'medium'
                  ? sxTheme.palette.background.medium
                  : sxTheme.palette.background.light,
              display: 'flex',
              flexDirection: 'column',
              [sxTheme.breakpoints.up('lg')]: {
                padding: '100px',
                width: '55vw',
              },
            })}
          >
            {caption && (
              <Headline
                variant="caption"
                sx={(sxTheme) => ({
                  display: 'inline-block',
                  mb: 2,
                  color:
                    theme === undefined || theme === null || theme === 'light'
                      ? sxTheme.palette.background.medium
                      : sxTheme.palette.common.white,
                  [sxTheme.breakpoints.up('md')]: {
                    mb: 4,
                  },
                })}
              >
                {caption}
              </Headline>
            )}

            {headline && (
              <Headline
                variant="h3"
                component="p"
                sx={(sxTheme) => ({
                  hyphens: 'auto',
                  fontWeight: 'regular',
                  mb: 4,
                  color:
                    theme === undefined || theme === null || theme === 'light'
                      ? sxTheme.palette.common.black
                      : sxTheme.palette.common.white,
                  [sxTheme.breakpoints.up('lg')]: {
                    mb: 8,
                  },
                })}
              >
                {headline}
              </Headline>
            )}
            <Copy
              sx={(sxTheme) => ({
                color:
                  theme === undefined || theme === null || theme === 'light'
                    ? sxTheme.palette.common.black
                    : sxTheme.palette.common.white,
              })}
              variant="body1"
            >
              {copy}
            </Copy>
            {linkTo && (
              <Box
                component={motion.div}
                {...moduleSlideUpMotionProps}
                sx={(sxTheme) => ({
                  mt: 8,
                  alignSelf: 'flex-start',
                  flexShrink: 0,
                  color:
                    theme === null || theme === 'medium'
                      ? sxTheme.palette.background.medium
                      : sxTheme.palette.common.white,
                  [sxTheme.breakpoints.up('md')]: {
                    mt: !isTextButton ? 16 : 0,
                    ml: isTextButton ? 'auto' : undefined,
                  },
                  [sxTheme.breakpoints.up('lg')]: {
                    mt: isTextButton ? 4 : undefined,
                  },
                })}
              >
                <Button theme={theme} variant="secondary" to={linkTo.url} isExternal={linkTo.isExternal}>
                  <FormattedMessage id="button.readFurther" />
                </Button>
              </Box>
            )}
          </Box>
          <Image
            component={motion.div}
            {...moduleSlideLeftMotionProps}
            {...images[0]}
            sx={(sxTheme) => ({
              height: '100%',
              aspectRatio: '1/1',
              [sxTheme.breakpoints.up('lg')]: {
                width: '45vw',
              },
              [sxTheme.breakpoints.down('lg')]: {
                padding: sxTheme.spacing(0, 6, 6, 6),
                background:
                  theme === 'dark'
                    ? sxTheme.palette.background.dark
                    : theme === 'medium'
                    ? sxTheme.palette.background.medium
                    : sxTheme.palette.background.light,
              },
            })}
          />
        </FlexWrapper>
      </Box>
    </Section>
  )
}
