import React, {
  useState,
  useEffect,
  ReactElement,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
} from 'react'

import { Box } from '@mui/material'

import theme from '@config/theme'
import Icon from '@components/core/ui/icon'
import SearchFlyout from './horizontalSearchFlyout'

export interface ISearch {
  isOpen: boolean
  isFocused: boolean
  toggleMenu: (open: boolean) => void
}

export interface IMenuState {
  open: boolean
  trigger: 'default' | 'key' | 'keyDown' | 'keyUp' | 'keyDownInitial'
}

export default function Search({
  isFocused,
  isOpen,
  toggleMenu,
}: ISearch): ReactElement {
  const [menu, setMenu] = useState<IMenuState>({
    open: isOpen || false,
    trigger: 'default',
  })
  const navItemRef = useRef<HTMLDivElement>(null)
  const navLinkRef = useRef<any>(null)

  useEffect(() => {
    // close menu, when user clicked outside/ other item
    if (!isOpen) {
      setMenu({ open: false, trigger: 'default' })
    }
  }, [isOpen])

  useEffect(() => {
    if (isFocused) {
      navItemRef.current?.focus()
    } else {
      // toggleMenu(false)
    }
  }, [isFocused])

  useEffect(() => {
    if (menu.open) {
      if (menu.trigger === 'keyDownInitial') {
        navLinkRef.current?.focus()
      }
    } else {
    }
  }, [menu])

  function handleItemKeyDown(ev: KeyboardEvent) {
    // key ' ' equals spacebar according to spec correct value here
    if ([' ', 'ArrowDown', 'ArrowUp'].includes(ev.key)) {
      if (ev.key === 'ArrowUp') {
        setMenu({ open: true, trigger: 'keyUp' })
      } else {
        setMenu({ open: true, trigger: 'keyDownInitial' })
      }
      toggleMenu(true)
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  function handleItemClick(ev: SyntheticEvent) {
    if (menu.open) {
      toggleMenu(false)
    } else {
      setMenu({ open: true, trigger: 'default' })
      toggleMenu(true)
      ev.preventDefault()
    }
  }

  return (
    <Box component="li" role="none">
      <Box
        component="div"
        sx={{
          display: 'inline-block',
          outline: 'none',
          ...theme.typography.navigation,
        }}
        ref={navItemRef}
        // tabIndex={tabIndex}
        //aria-haspopup={!!item.children ? true : false}
        //aria-expanded={!!item.children ? (menu.open ? true : false) : undefined}
        onClick={handleItemClick}
        onKeyDown={handleItemKeyDown}
      >
        <Box
          component="span"
          sx={{
            fontSize: 'medium',
            position: 'relative',
            display: 'inline-block',
            padding: theme.spacing(0, 0, 5, 0),
            cursor: 'pointer',
            '&:after': {
              content: `''`,
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '0%',
              height: theme.spacing(1),
              backgroundColor: theme.palette.grey[900],
            },
          }}
        >
          <Icon name="Search" />
        </Box>
      </Box>
      <SearchFlyout menuState={menu} toggleMenu={toggleMenu} />
    </Box>
  )
}
