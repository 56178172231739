import React, { ReactElement, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { HistoryLocation } from '@reach/router'

import Header from '@components/core/container/header'
import Footer from '@components/modules/footer'
import {
  pageMainMotionProps,
  // pageCoverMotionProps
} from '@utils/animation'
import useContentfulNavigation from '@components/system/hooks/contentful/useContentfulNavigation'
import { getContextRelatedPages } from '@utils/props'
import Breadcrumbs from '@components/core/ui/breadcrumbs'
import Button from '@components/core/ui/button'
import Section from '../section'
import {
  //StyledMotionDiv,
  StyledMotionMain,
} from '../fowardPropsContainer'

export type PageState = {
  location: HistoryLocation
  pageContext?: SOG.Helpers.IPageContext
}

export type LayoutProps = SOG.Props.IDefault & {
  location: HistoryLocation
  pageContext?: SOG.Helpers.IPageContext
}

export default function Layout({
  children,
  location,
  pageContext,
}: LayoutProps): ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [currentPageState, setCurrentPageState] = useState<PageState>({
    location,
    pageContext,
  })
  const { main } = useContentfulNavigation()
  const relatedPages = getContextRelatedPages(currentPageState.pageContext)

  const breadcrumbsConfig = useMemo((): {
    theme?: SOG.Contentful.Theme
    isAbsolute?: boolean
  } => {
    const data = (children as any)?.props?.data
    if (data?.contentfulPageGeneric) {
      const firstModule = data?.contentfulPageGeneric?.modules?.[0]
      return {
        theme: firstModule?.theme,
        isAbsolute:
          !!firstModule && firstModule?.__typename === 'ContentfulModuleStage',
      }
    }
    return {}
  }, [currentPageState.location])

  return (
    <React.Fragment>
      <Header
        mainnav={main.entries}
        reducedHeader={pageContext?.page?.reducedHeader}
      />

      <AnimatePresence exitBeforeEnter>
        <StyledMotionMain
          key={location.pathname}
          {...pageMainMotionProps}
          sx={(theme: Theme) => ({
            position: 'relative',
            minHeight: '80vh',
            // overflow: 'hidden', // Overflow Hidden here will make position sticky on the entire side broken (happens in all browsers)
            pt: 12.5,
            [theme.breakpoints.up('lg')]: {
              pt: 17,
            },
          })}
        >
          <Section
            maxWidth="lg"
            sx={{ position: 'relative' }}
            paddingTop={false}
            paddingBottom={false}
            theme={
              isMobile || !breadcrumbsConfig.isAbsolute
                ? breadcrumbsConfig.theme
                : undefined
            }
          >
            <Breadcrumbs
              theme={breadcrumbsConfig.theme}
              entries={pageContext?.page?.breadcrumbs.entries || []}
              isSingleBack={!!pageContext?.page?.breadcrumbs.isSingleBack}
              sx={{
                position:
                  !isMobile && breadcrumbsConfig.isAbsolute
                    ? 'absolute'
                    : undefined,
                zIndex: 2,
              }}
            />
          </Section>

          <Box
            sx={(theme: Theme) => ({
              '& > * + *': {
                mt: 10,
                [theme.breakpoints.up('md')]: {
                  mt: 20,
                },
                [theme.breakpoints.up('lg')]: {
                  mt: 30,
                },
              },
              '& > *:last-child:not(.Mui-themed)': {
                mb: 10,
                [theme.breakpoints.up('md')]: {
                  mb: 20,
                },
                [theme.breakpoints.up('lg')]: {
                  mb: 30,
                },
              },
              '& > *:first-child:not(.ModuleStage):not(.Mui-themed)': {
                mt: 10,
                [theme.breakpoints.up('md')]: {
                  mt: 12,
                },
                [theme.breakpoints.up('lg')]: {
                  mt: 12,
                },
              },
              '& > .Mui-themed:not(:first-of-type) + .Mui-themed': {
                mt: 0,
              },
            })}
          >
            {children}
          </Box>
        </StyledMotionMain>
      </AnimatePresence>

      <Button variant="primary" backtop icon="ChevronUp"></Button>

      <Footer
        location={currentPageState.location}
        key={currentPageState.location?.pathname}
        navigationItems={main.entries}
        metaNavigationItems={main.metaEntries}
        relatedPages={relatedPages}
        brandItems={main.brands}
        contactFormPage={main.contactFormPage}
        currentPageState={currentPageState}
      />
    </React.Fragment>
  )
}
