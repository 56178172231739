/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Container, styled, Theme } from '@mui/material'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

/*
  Prevent SX and other Props from being added as an DOM Attribute
*/

export const StyledGatsbyLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'enter',
})(({ theme }) => ({}))

export const StyledMuiContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'enter',
})(({ theme }) => ({}))

export const StyledMotionDiv = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'enter',
})(({ theme }) => ({}))

export const StyledMotionMain = styled(motion.main, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'enter',
})(({ theme }) => ({}))

export const StyledMotionSpan = styled(motion.span, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'enter',
})(({ theme }) => ({}))

export const StyledDiv = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'sx' &&
    prop !== 'enter' &&
    prop !== 'initial' &&
    prop !== 'viewport' &&
    prop !== 'component' &&
    prop !== 'whileInView',
})(({ theme }) => ({}))
