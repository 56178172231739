import React, { ElementType, ReactElement } from 'react'

import Typography from '@mui/material/Typography'
import { MotionProps } from 'framer-motion'

export type CopyProps = SOG.Props.IDefault &
  MotionProps & {
    level?: number
    id?: string
    html?: string
    variant?: 'body1' | 'body2' | 'textLarge'
    component?: ElementType<any>
  }

export default function Copy({
  children,
  id,
  html,
  variant = 'body1',
  component,
  initial,
  whileInView,
  viewport,
  sx,
}: CopyProps): ReactElement {
  const additionalProps = {
    ...(html && {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    }),
    ...((component || 'html') && {
      component: component || 'div',
      initial,
      whileInView,
      viewport,
    }),
  }
  return (
    <Typography
      id={id}
      variant={variant}
      {...additionalProps}
      sx={sx}
      gutterBottom={false}
    >
      {children}
    </Typography>
  )
}
