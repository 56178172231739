import React, { useEffect, KeyboardEvent, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Link } from 'gatsby'
import { Box, Container } from '@mui/material'
import { styled } from '@mui/system'
import { useIntl } from 'react-intl'

import theme from '@config/theme'
import Headline from '@components/core/text/headline'
import SubNav from './horizontalSubNav'
import { naviFlyoutMotionProps } from '@utils/animation'
import useHeaderPortalTarget from '@components/system/hooks/useHeaderPortalTarget'
import Image from '@components/core/media/image'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'

export interface NavItemFlyoutProps {
  item: SOG.Props.LinkTo
  menuState: IMenuState
  toggleMenu: (open: boolean) => void
  returnFocus: (bool: boolean) => void
  handleLinkKeyDown?: (event: KeyboardEvent) => void
}

export interface IMenuState {
  open: boolean
  trigger: 'default' | 'key' | 'keyDown' | 'keyUp' | 'keyDownInitial'
}

const SxLink = styled(Link)({})

const SxSubNav = styled(SubNav)({})

const NavItemFlyout = React.memo(function NavItemFlyout({
  item,
  menuState,
  toggleMenu,
  handleLinkKeyDown,
  returnFocus,
}: NavItemFlyoutProps) {
  const { messages } = useIntl()
  const { portalRef, wasOpen } = useHeaderPortalTarget()
  const [adjustedHeight, setAdjustedHeight] = useState<string>('auto')
  const navLinkRef = useRef<any>(null)
  useEffect(() => {
    if (menuState.open) {
      if (menuState.trigger === 'keyDownInitial') {
        navLinkRef.current?.focus()
      }
    } else {
    }
  }, [menuState])
  const itemLabelId = String(item.label).replace(/\s+/g, '').toLowerCase()
  const itemDescId = `${String(item.label)
    .replace(/\s+/g, '')
    .toLowerCase()}_desc`

  if (!portalRef || !portalRef.current) return null
  return createPortal(
    <StyledMotionDiv
      className="NavItemFlyout"
      role="none"
      custom={wasOpen}
      {...naviFlyoutMotionProps}
      animate={menuState.open ? 'show' : 'hide'}
      sx={{
        display: 'flex',
        zIndex: 1,
        alignItems: 'stretch',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Container
        role="none"
        sx={{
          display: 'flex',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          role="none"
          sx={{
            paddingTop: theme.spacing(8),
          }}
        >
          <Headline
            id={itemLabelId}
            variant="h5"
            sx={{
              [theme.breakpoints.up('lg')]: {
                width: '480px',
              },
            }}
          >
            <SxLink
              sx={{
                textDecoration: 'none',
                position: 'relative',
                borderBottom: '2px solid transparent',
                '&:after': {
                  content: `''`,
                  position: 'absolute',
                  left: 0,
                  bottom: -2,
                  width: '0%',
                  height: '2px',
                  transition: `width .3s ${theme.transitions.easing.easeInOut}`,
                },
                '&:hover': {
                  '&:after': {
                    width: '100%',
                  },
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
              ref={navLinkRef}
              role="menuitem"
              to={item.url}
              onClick={() => {
                toggleMenu(false)
              }}
              onKeyDown={handleLinkKeyDown}
            >
              {item.label}
            </SxLink>
          </Headline>
        </Box>
        {!!item.children && (
          <SxSubNav
            setAdjustedHeight={setAdjustedHeight}
            adjustedHeight={adjustedHeight}
            isFirstItemInTopLevelNav={
              item.contentful_id === '41MJB2Hkz5rLWwlnnYSm7v'
            }
            sx={{
              [theme.breakpoints.up('lg')]: {
                minWidth: '390px',
              },
            }}
            items={[
              { label: messages['button.overview'] as string, url: item.url },
              ...item.children,
            ]}
            level={2}
            menuState={menuState}
            returnFocus={(close: boolean) => {
              if (close) {
                toggleMenu(false)
                navLinkRef.current?.focus()
                returnFocus(false)
              } else {
                returnFocus(true)
              }
            }}
            onNavigate={() => toggleMenu(false)}
            isVisible={menuState.open}
            aria-labelledby={itemLabelId}
            aria-describedby={itemDescId}
          />
        )}
        <Image
          {...(item.teaserImage as SOG.Props.Image)}
          objectFit="cover"
          objectPosition="center"
          sx={{
            width: '40%',
            height: '100%',
            mr: -6,
            [theme.breakpoints.up('lg')]: {
              mr: -8,
              height: adjustedHeight === 'auto' ? '100%' : adjustedHeight,
              transition: 'height 0.1s ease-out',
            },
          }}
        />
      </Container>
    </StyledMotionDiv>,
    portalRef.current
  )
})

export default NavItemFlyout
