import React, { useRef } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import { Transition } from 'framer-motion'
import useMeasure from '@components/system/hooks/useMeasure'
import { StyledMotionDiv } from '../fowardPropsContainer'

export type CollapsableContainerProps = SOG.Props.IDefault & {
  delay?: number
  duration?: number
  ease?: string
  isOpen: boolean
  transitionOpen?: Transition
  transitionClose?: Transition
  containerSx?: SxProps<Theme>
}

export default function CollapsableContainer({
  duration,
  delay = 0,
  ease = 'easeOut',
  isOpen,
  transitionOpen,
  transitionClose,
  containerSx,
  children,
  sx,
}: CollapsableContainerProps) {
  const ref = useRef(null)
  const bounds = useMeasure(ref)

  function getAutoHeightDuration(height: number | undefined) {
    if (!height) return 0
    const constant = height / 36
    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10)
  }

  const variants = {
    open: (height: number) => ({
      height: height || 'auto',
      transition: transitionOpen,
    }),
    close: { height: 0, transition: transitionClose },
  }

  return (
    <StyledMotionDiv
      custom={bounds.height}
      initial={isOpen ? 'open' : 'close'}
      animate={isOpen ? 'open' : 'close'}
      inherit={false}
      variants={variants}
      transition={{
        delay,
        ease,
        duration:
          typeof duration === 'number'
            ? duration
            : getAutoHeightDuration(bounds.height) / 1000,
      }}
      sx={[{ overflow: 'hidden' }, sx as SOG.Props.SxInArray]}
    >
      <Box sx={containerSx} ref={ref}>
        {children}
      </Box>
    </StyledMotionDiv>
  )
}
