import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

import Bookmark from '../../../../../static/img/icons/bookmark.svg'
import Check from '../../../../../static/img/icons/check.svg'
import Close from '../../../../../static/img/icons/close.svg'
import Contact from '../../../../../static/img/icons/contact.svg'
import Download from '../../../../../static/img/icons/download.svg'
import ExternalLink from '../../../../../static/img/icons/external-link.svg'
import Fullscreen from '../../../../../static/img/icons/fullscreen.svg'
import Heart from '../../../../../static/img/icons/heart.svg'
import Location from '../../../../../static/img/icons/location.svg'
import Menu from '../../../../../static/img/icons/menu.svg'
import Minus from '../../../../../static/img/icons/minus.svg'
import Newsletter from '../../../../../static/img/icons/newsletter.svg'
import Play from '../../../../../static/img/icons/play.svg'
import Plus from '../../../../../static/img/icons/plus.svg'
import Search from '../../../../../static/img/icons/search.svg'
import Share from '../../../../../static/img/icons/share.svg'

import ChevronDown from '../../../../../static/img/icons/chevron/down.svg'
import ChevronLeft from '../../../../../static/img/icons/chevron/left.svg'
import ChevronRight from '../../../../../static/img/icons/chevron/right.svg'
import ChevronUp from '../../../../../static/img/icons/chevron/up.svg'

import ArrowDownLeft from '../../../../../static/img/icons/diagonal_arrow/24px/down_left.svg'
import ArrowDownRight from '../../../../../static/img/icons/diagonal_arrow/24px/down_right.svg'
import ArrowUpLeft from '../../../../../static/img/icons/diagonal_arrow/24px/up_left.svg'
import ArrowUpRight from '../../../../../static/img/icons/diagonal_arrow/24px/up_right.svg'

import Facebook from '../../../../../static/img/icons/socials/outline/facebook.svg'
import Instagram from '../../../../../static/img/icons/socials/outline/instagram.svg'
import LinkedIn from '../../../../../static/img/icons/socials/outline/linkedin.svg'
import Xing from '../../../../../static/img/icons/socials/outline/xing.svg'
import Phone from '../../../../../static/img/icons/socials/outline/phone.svg'

export type IconNamesType =
  | 'Bookmark'
  | 'Check'
  | 'Close'
  | 'Contact'
  | 'Download'
  | 'ExternalLink'
  | 'Fullscreen'
  | 'Heart'
  | 'Location'
  | 'Menu'
  | 'Minus'
  | 'Newsletter'
  | 'Play'
  | 'Plus'
  | 'Search'
  | 'Share'
  | 'ArrowDownLeft'
  | 'ArrowDownRight'
  | 'ArrowUpLeft'
  | 'ArrowUpRight'
  | 'ChevronDown'
  | 'ChevronUp'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'Instagram'
  | 'LinkedIn'
  | 'Facebook'
  | 'Xing'
  | 'Phone'

export type IconProps = SOG.Props.IDefault &
  SvgIconProps & {
    name: IconNamesType
  }

const icons: { [key: string]: any } = {
  Bookmark,
  Check,
  Close,
  Contact,
  Download,
  ExternalLink,
  Fullscreen,
  Heart,
  Location,
  Menu,
  Minus,
  Newsletter,
  Play,
  Plus,
  Search,
  Share,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowUpLeft,
  ArrowUpRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Facebook,
  Instagram,
  LinkedIn,
  Xing,
  Phone,
}

export default function Icon({ name, sx, ...props }: IconProps) {
  const Svg = icons[name]

  if (!Svg) return null

  return <SvgIcon sx={sx} {...props} component={Svg} />
}
