import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { createPortal } from 'react-dom'
import { navigate } from 'gatsby'

import theme from '@config/theme'
import useHeaderPortalTarget from '@components/system/hooks/useHeaderPortalTarget'
import { naviFlyoutMotionProps } from '@utils/animation'
import SearchInput from '@components/core/ui/formfields/searchinput'
import useContentfulSearch from '@components/system/hooks/contentful/useContentfulSearch'
import { pathToLocalePath } from '@utils/string-tools'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'

export interface ISearch {
  menuState: IMenuState
  toggleMenu: (open: boolean) => void
}

export interface IMenuState {
  open: boolean
  // trigger: 'default' | 'key' | 'keyDown' | 'keyUp' | 'keyDownInitial'
}

const SearchFlyout = React.memo(function SearchFlyout({
  menuState,
  toggleMenu,
}: ISearch): ReactElement | null {
  const { portalRef, wasOpen } = useHeaderPortalTarget()
  if (!portalRef || !portalRef.current) return null

  const initialState = ''

  const { locale, messages } = useIntl()
  //const [results, query, setQuery] = useContentfulSearch()
  const [query, setQuery] = useState(initialState)

  function keyDownHandler(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (!!query && query.length >= 3 && (ev as any).key === 'Enter') {
      ev.target.blur()
      navigate(pathToLocalePath(`/suche/#searchquery=${query}`, locale))
      toggleMenu(false)
    }
  }

  const searchInputRef = useRef(null)
  function setFocus() {
    searchInputRef.current?.focus()
  }
  useEffect(() => {
    menuState.open === true && setTimeout(setFocus, 500)
    menuState.open === false && setQuery('')
  }, [menuState.open])

  return createPortal(
    <StyledMotionDiv
      role="none"
      custom={wasOpen}
      {...naviFlyoutMotionProps}
      animate={menuState.open ? 'show' : 'hide'}
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1,
        alignItems: 'stretch',

        justifyContent: 'space-between',
      }}
    >
      <Container
        maxWidth="lg"
        role="none"
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <SearchInput
          value={query}
          placeholder={messages['search.placeholder'] as string}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setQuery(e.target.value)
          }
          onKeyDown={keyDownHandler}
          onClick={() => {
            navigate(pathToLocalePath(`/suche/#searchquery=${query}`, locale))
            toggleMenu(false)
          }}
          inputRef={searchInputRef}
        />
      </Container>
      {!!query && query.length > 0 && query.length < 3 && (
        <Box
          sx={{
            backgroundColor: theme.palette.grey[100],
            display: 'flex',
            width: '100%',
          }}
        >
          <Container maxWidth="lg" role="none" sx={{ py: 1 }}>
            <Typography variant="smallFixed">
              <FormattedMessage
                id="validation.minCharacters"
                values={{ total: 3 }}
              />
            </Typography>
          </Container>
        </Box>
      )}
    </StyledMotionDiv>,
    portalRef.current
  )
})

export default SearchFlyout
