import React, { useState, ReactElement, ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useLocation } from '@reach/router'
import { FormattedMessage } from 'react-intl'

import theme from '@config/theme'
import Link from '@components/core/ui/link'
import Icon from '@components/core/ui/icon'

export interface IDrawerNavItem extends SOG.Props.IDefault {
  to?: string | null
  activeSlug?: string
  label: string
  drawerClass?: string
  level: number
  onClick: (open: boolean) => void
}

interface IWrapper {
  children: ReactNode
  hasChildren: boolean
  className?: string
}

export default function DrawerNavItem({
  children,
  to,
  label,
  level,
  onClick,
}: IDrawerNavItem): ReactElement {
  const location = useLocation()

  const [open, setOpen] = useState(false)

  function toggleSubMenu(open: boolean) {
    setOpen(open)
  }

  function closeMenu() {
    onClick(false)
  }

  function Wrapper({ children, hasChildren }: IWrapper) {
    return (
      <Box
        data-testid={`toggle-${hasChildren ? '1' : '0'}`}
        onClick={hasChildren ? () => toggleSubMenu(true) : undefined}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          ...(to &&
            location.pathname.startsWith(to) === true && {
              '& SxTitleSpan': {
                borderBottomColor: 'currentColor',
              },
            }),
        }}
      >
        {to && !hasChildren && (
          <Link
            data-testid="link"
            to={to}
            onClick={closeMenu}
            sx={{
              width: '100%',
              color: 'inherit',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {children}
          </Link>
        )}
        {hasChildren && children}
      </Box>
    )
  }

  return (
    <>
      <ListItem
        disablePadding
        data-testid={`item-${level}`}
        button
        sx={{
          padding: 0,
          borderBottom: '1px solid',
          borderColor: theme.palette.grey[300],
          ...(level === 0 && {
            ...theme.typography.navigation,
          }),
        }}
      >
        <Wrapper hasChildren={!!children}>
          <Box
            component="span"
            sx={{
              lineHeight: '21px',
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: 'medium',
              padding: theme.spacing(4, 0, 3, 0),
              borderBottom: '4px solid transparent',
              px: 6,
              [theme.breakpoints.up('md')]: {
                px: 8,
              },
            }}
          >
            {label}
            <Box
              sx={{
                ...(level === 2 && {
                  display: 'none',
                }),
                ...(!children && {
                  display: 'none',
                }),
              }}
            >
              <Icon name="ChevronRight" fontSize="small" />
            </Box>
          </Box>
        </Wrapper>
      </ListItem>
      {children && (
        <SwipeableDrawer
          data-testid="submenu"
          anchor={'right'}
          variant="persistent"
          open={open}
          onClose={() => toggleSubMenu(false)}
          onOpen={() => toggleSubMenu(true)}
          transitionDuration={500}
          PaperProps={{
            sx: {
              width: '100vw',
              height: '100%',
              overflowY: 'auto',
              top: theme.spacing(12),
              borderTop: '1px solid',
              borderColor: theme.palette.grey[300],
            },
          }}
        >
          <Box
            onClick={() => toggleSubMenu(false)}
            sx={{
              display: 'flex',
              alignItems: 'center',

              padding: theme.spacing(4, 6, 3, 4.5),
              [theme.breakpoints.up('md')]: {
                padding: theme.spacing(4, 8, 3, 6.5),
              },
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.grey[300],
              },
              '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              },
            }}
          >
            <Box sx={{ mr: theme.spacing(2) }}>
              <Icon name="ChevronLeft" />
            </Box>
            <Box>
              <Typography>
                <FormattedMessage id="label.back" />
              </Typography>
            </Box>
          </Box>
          <Link
            onClick={() => toggleSubMenu(false)}
            sx={{
              backgroundColor: theme.palette.grey[200],
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: theme.palette.grey[300],

              '& span': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <Wrapper hasChildren={false}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  lineHeight: '21px',
                  fontSize: 'large',
                  fontWeight: 'bold',
                  borderBottomColor: theme.palette.primary.main,
                  borderBottom: '4px solid transparent',
                  p: theme.spacing(4, 6, 3, 6),
                  [theme.breakpoints.up('md')]: {
                    p: theme.spacing(4, 8, 3, 8),
                  },
                }}
              >
                {label}
                <Box>
                  <Icon name="ArrowUpRight" fontSize="small" />
                </Box>
              </Box>
            </Wrapper>
          </Link>
          {children}
        </SwipeableDrawer>
      )}
    </>
  )
}
