import React, {
  useState,
  useEffect,
  ReactElement,
  SyntheticEvent,
  useRef,
} from 'react'
import { useLocation } from '@reach/router'
import { Box } from '@mui/material'
import NavItemFlyout from './horizontalNavItemFlyout'

export interface INavItem {
  item: SOG.Props.LinkTo
  isOpen: boolean
  isFocused: boolean
  toggleMenu: (open: boolean) => void
  tabIndex: number
}

export interface IMenuState {
  open: boolean
  trigger: 'default' | 'key' | 'keyDown' | 'keyUp' | 'keyDownInitial'
}

const NavItem = React.memo(function NavItem({
  item,
  isFocused,
  isOpen,
  toggleMenu,
  tabIndex,
}: INavItem): ReactElement {
  const location = useLocation()
  const [menu, setMenu] = useState<IMenuState>({
    open: isOpen || false,
    trigger: 'default',
  })
  const navItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // close menu, when user clicked outside/ other item
    if (!isOpen) {
      setMenu({ open: false, trigger: 'default' })
    }
  }, [isOpen])

  useEffect(() => {
    if (isFocused) {
      navItemRef.current?.focus()
    } else {
      toggleMenu(false)
    }
  }, [isFocused])

  // function handleItemKeyDown(ev: KeyboardEvent) {
  //   // key ' ' equals spacebar according to spec correct value here
  //   if ([' ', 'ArrowDown', 'ArrowUp'].includes(ev.key)) {
  //     if (ev.key === 'ArrowUp') {
  //       setMenu({ open: true, trigger: 'keyUp' })
  //     } else {
  //       setMenu({ open: true, trigger: 'keyDownInitial' })
  //     }
  //     toggleMenu(true)
  //     ev.stopPropagation()
  //     ev.preventDefault()
  //   }
  // }

  function handleItemClick(ev: SyntheticEvent) {
    if (menu.open) {
      toggleMenu(false)
    } else {
      setMenu({ open: true, trigger: 'default' })
      toggleMenu(true)
      ev.preventDefault()
    }
  }

  // function handleLinkKeyDown(ev: KeyboardEvent) {
  //   // key ' ' equals spacebar according to spec correct value here
  //   if ([' ', 'ArrowDown', 'ArrowUp'].includes(ev.key)) {
  //     if (ev.key === 'ArrowUp') {
  //       setMenu({ open: true, trigger: 'keyUp' })
  //     } else {
  //       setMenu({ open: true, trigger: 'keyDown' })
  //     }
  //     ev.stopPropagation()
  //     ev.preventDefault()
  //   }
  // }

  return (
    <Box
      component="li"
      role="none"
      sx={(theme) => ({
        '&:not(:first-of-type)': {
          marginLeft: theme.spacing(10),
        },
        '& > $item': {
          whiteSpace: 'nowrap',
          '&:focus $label, & $label:focus': {
            borderBottomColor: 'currentColor',
            //textDecoration: 'underline',
          },
        },
        '&:hover > $item': {
          '&, & $label, & $label:hover, & $icon': {
            textDecoration: 'none',
          },
        },
      })}
    >
      <Box
        component="div"
        sx={(theme) => ({
          display: 'inline-block',
          outline: 'none',
          ...theme.typography.navigation,
        })}
        ref={navItemRef}
        tabIndex={tabIndex}
        aria-haspopup={!!item.children ? true : false}
        aria-expanded={!!item.children ? (menu.open ? true : false) : undefined}
        onClick={handleItemClick}
        // onKeyDown={handleItemKeyDown}
      >
        <Box
          component="span"
          sx={(theme) => ({
            fontSize: 'medium',
            position: 'relative',
            display: 'inline-block',
            padding: theme.spacing(0, 0, 5, 0),
            cursor: 'pointer',
            '&:after': {
              content: `''`,
              position: 'absolute',
              left: 0,
              bottom: '-1px',
              width: '0%',
              height: theme.spacing(1),
              backgroundColor: theme.palette.grey[900],
              transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
              ...(menu.open === true && {
                width: '100%',
              }),
              ...(item.url &&
                location.pathname.startsWith(item.url) === true && {
                  width: '100%',
                }),
            },
            '&:hover, :focus > &': {
              '&:after': {
                width: '100%',
              },
            },
          })}
        >
          {item.label}
        </Box>
      </Box>
      <NavItemFlyout
        item={item}
        menuState={menu}
        returnFocus={(bool: boolean) => bool && navItemRef.current?.focus()}
        toggleMenu={toggleMenu}
        // handleLinkKeyDown={handleLinkKeyDown}
      />
    </Box>
  )
})

export default NavItem
