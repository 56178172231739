import React, { ReactElement } from 'react'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import { navigate } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'

import Link from '@components/core/ui/link'
import Icon from '../icon'

export type BreadcrumbsProps = SOG.Props.IDefault & {
  theme?: SOG.Contentful.Theme
  entries: SOG.Props.LinkTo[]
  isSingleBack?: boolean
  isAllBack?: boolean
}

export default function Breadcrumbs({
  theme,
  entries = [],
  isSingleBack = false,
  isAllBack = false,
  sx,
}: BreadcrumbsProps): ReactElement {
  const className = clsx({
    'MuiBreadcrumbs-themeLight': theme === 'light',
    'MuiBreadcrumbs-themeMedium': theme === 'medium',
    'MuiBreadcrumbs-themeDark': theme === 'dark',
  })
  const entriesToRender = isSingleBack
    ? entries.slice(entries.length - 1)
    : entries

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const mobileEntriesToRender = entries[entries.length - 2]

  return (
    <MuiBreadcrumbs
      className={className}
      sx={sx}
      aria-label="breadcrumb"
      separator={false}
    >
      {!isMobile && (isAllBack || !isSingleBack) && (
        <Box
          sx={(theme) => ({
            pt: 4,
            [theme.breakpoints.up('lg')]: {
              pt: 7,
            },
          })}
        >
          {entriesToRender.map((entry, index) =>
            isAllBack || index < entries.length - 1 ? (
              <Link
                fontSize="small"
                to={entry.url}
                iconLayout="after"
                icon={
                  !isAllBack || index < entries.length - 1
                    ? {
                        name: 'ChevronRight',
                        fontSize: 'inherit',
                        sx: { width: '0.5em', height: '0.5em', mx: 1 },
                      }
                    : undefined
                }
                key={entry.url}
                sx={(theme) => ({
                  '& span': {
                    position: 'relative',
                    display: 'inline-block',
                    padding: theme.spacing(0, 0, 1, 0),
                    cursor: 'pointer',
                    '&:after': {
                      content: `''`,
                      position: 'absolute',
                      left: 0,
                      bottom: '-1px',
                      width: '0%',
                      height: '1px',
                      backgroundColor: theme.palette.grey[900],
                      transition: `width 0.3s ${theme.transitions.easing.easeInOut}`,
                    },
                    '&:hover, :focus > &': {
                      '&:after': {
                        width: '100%',
                      },
                    },
                  },
                })}
              >
                {entry.label}
              </Link>
            ) : entries.length === 1 ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="smallFixed"
                  key={entry.url}
                  sx={{
                    color: '#1e1e1e',
                  }}
                >
                  {entry.label}
                </Typography>
                <Icon
                  name="ChevronRight"
                  fontSize="inherit"
                  sx={{
                    color: '#1e1e1e',
                    width: '0.5em',
                    height: '0.5em',
                    mx: 1,
                  }}
                />
                <Typography
                  variant="smallFixed"
                  key={entry.url}
                  sx={{ color: '#1e1e1e' }}
                >
                  <FormattedMessage id="button.overview" />
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="smallFixed"
                key={entry.url}
                sx={{
                  color: '#1e1e1e',
                }}
              >
                {entry.label}
              </Typography>
            )
          )}
        </Box>
      )}
      {isSingleBack && !isMobile && (
        <Box
          sx={(theme) => ({
            pt: 4,
            [theme.breakpoints.up('lg')]: {
              pt: 7,
            },
          })}
        >
          <Link
            fontSize="small"
            onClick={() => navigate(-1)}
            iconLayout="before"
            icon={{
              name: 'ChevronLeft',
              fontSize: 'inherit',
              sx: { width: '0.5em', height: '0.5em', mr: 1, mb: 0.5 },
            }}
          >
            <FormattedMessage id="button.backToOverview" />
          </Link>
        </Box>
      )}

      {isMobile && !!mobileEntriesToRender && (
        <Box
          sx={(theme) => ({
            padding: theme.spacing(2, 0),
            [theme.breakpoints.up('lg')]: {
              pt: 7,
            },
          })}
        >
          <Link
            fontSize="small"
            to={mobileEntriesToRender.url}
            iconLayout="before"
            icon={
              !isAllBack
                ? {
                    name: 'ChevronLeft',
                    fontSize: 'inherit',
                    sx: {
                      width: '0.5em',
                      height: '0.5em',
                      mx: 1,
                      color: '#1e1e1e',
                    },
                  }
                : undefined
            }
            key={mobileEntriesToRender.url}
            sx={{
              color: '#1e1e1e',
            }}
          >
            <FormattedMessage id="button.backToOverview" />
          </Link>
        </Box>
      )}
    </MuiBreadcrumbs>
  )
}
