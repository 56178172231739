import React, { ReactElement } from 'react'

import { Box } from '@mui/material'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'

export type IconTextProps = SOG.Props.IDefault & {
  icon: SOG.Props.Svg
  headline: string
  copy?: SOG.Props.RichText
}

export default function IconText({
  icon,
  headline,
  copy,
  sx,
}: IconTextProps): ReactElement {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'flex-start',
        },
        sx as SOG.Props.SxInArray,
      ]}
    >
      <Box
        sx={(theme) => ({
          display: 'inline-flex',
          justifyContent: 'center',
          color: 'primary.main',
          minWidth: '40px',
          width: '40px',
          height: '40px',
          mr: 3,
          [theme.breakpoints.up('lg')]: {
            minWidth: '50px',
            width: '50px',
            height: '50px',
            mr: 6,
          },
        })}
        dangerouslySetInnerHTML={{ __html: icon?.originalContent }}
      />
      <Box>
        <Headline
          variant="h7"
          component="span"
          sx={{ display: 'block', paddingBottom: '8px' }}
        >
          {headline}
        </Headline>
        <Copy component="span">{copy}</Copy>
      </Box>
    </Box>
  )
}
