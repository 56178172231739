import React from 'react'
import { Box, Grid, Theme, useMediaQuery } from '@mui/material'

import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import ModuleSection from '@components/core/container/moduleSection'
import Image from '@components/core/media/image'
import { motion } from 'framer-motion'
import {
  moduleStaggerChildDownMotionProps,
  moduleStaggerChildUpMotionProps,
  moduleStaggerParentMotionProps,
} from '@utils/animation'

type EmployeeTextContent = SOG.Props.IDefault & {
  name: string
  position: string
  theme?: SOG.Contentful.Theme
}

const EmployeeTextContent = ({
  name,
  position,
  theme,
  sx,
}: EmployeeTextContent): React.ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        width: '75%',
        ...sx,
      }}
    >
      <Headline
        variant="h5"
        component="span"
        sx={(theme) => ({
          mb: 2,
          ...theme.mixins.fluidFontSize('xs', 'sm', 'lg', '2xl'),
          ...theme.mixins.fluidFontSize('sm', 'xl', 'lg', '2xl'),
        })}
      >
        {name}
      </Headline>
      <Headline
        variant="h7"
        component="span"
        sx={(sxTheme) => ({
          ...sxTheme.mixins.fluidFontSize('xs', 'sm', 'xs', '2lg'),
          ...sxTheme.mixins.fluidFontSize('sm', 'xl', 'xs', 'base'),
          color:
            theme === 'medium'
              ? sxTheme.palette.common.white
              : sxTheme.palette.text.secondary,
        })}
      >
        {position}
      </Headline>
    </Box>
  )
}

export type ManagementProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    theme?: SOG.Contentful.Theme
    caption?: string
    headline: string
    copy?: SOG.Props.RichText
    linkTo?: SOG.Props.LinkTo
    entries: SOG.Props.IGlobalEmployee[]
  }

export default function Management({
  caption,
  headline,
  copy,
  linkTo,
  entries,
  theme,
}: ManagementProps): React.ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Section theme={theme}>
      <ModuleSection
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={linkTo}
        theme={theme}
      >
        {entries && (
          <Box
            sx={(theme) => ({
              mt: 10,
              position: 'relative',
              display: 'flex',
              alignItems: 'flex-end',
              [theme.breakpoints.up('sm')]: {
                mt: 20,
              },
            })}
          >
            {!isMobile && (
              <Grid
                container
                component={motion.div}
                {...moduleStaggerParentMotionProps}
                columnSpacing={10}
                rowSpacing={{ xs: 8, sm: 4 }}
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                }}
              >
                {entries.map((employee, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={4}
                    component={motion.div}
                    {...(index % 2 === 0
                      ? moduleStaggerChildUpMotionProps
                      : moduleStaggerChildDownMotionProps)}
                  >
                    <EmployeeTextContent
                      sx={{ mb: 4 }}
                      name={employee.name}
                      position={employee.position}
                      theme={theme}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid
              container
              columnSpacing={10}
              rowSpacing={{ xs: 8, sm: 4 }}
              component={motion.div}
              {...moduleStaggerParentMotionProps}
            >
              {entries.map((employee, index) => (
                <Grid
                  item
                  component={motion.div}
                  {...(index % 2 === 0
                    ? moduleStaggerChildUpMotionProps
                    : moduleStaggerChildDownMotionProps)}
                  key={index}
                  xs={12}
                  sm={4}
                  sx={{
                    position: 'relative',
                  }}
                >
                  {isMobile && (
                    <EmployeeTextContent
                      sx={{
                        position: 'absolute',
                        width: 'auto',
                        right: index % 2 !== 0 ? '17.5%' : 'initial', //TODO: better way? (100% - 65%) / 2
                        bottom: 20,
                      }}
                      name={employee.name}
                      position={employee.position}
                      theme={theme}
                    />
                  )}
                  <Image
                    {...employee.image}
                    sx={(theme) => ({
                      width: '65%',
                      height: '100%',
                      ml: 'auto',
                      [theme.breakpoints.down('sm')]: index % 2 !== 0 && {
                        width: '65%',
                        mr: 'auto',
                        ml: 0,
                      },
                      [theme.breakpoints.up('sm')]: {
                        width: '75%',
                      },
                    })}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </ModuleSection>
    </Section>
  )
}
