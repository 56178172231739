import Copy from '@components/core/text/copy'
import { Box, IconButton } from '@mui/material'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from 'react'

import YouTubePlayer from 'react-youtube'
import { YouTubePlayer as YoutubePlayerTypes } from 'youtube-player/dist/types'
import Image from '../image'

export type YouTubeProps = SOG.Props.IDefault & {
  youtubeId?: string
  copyright?: string
  description?: string
  thumbnail?: SOG.Props.Image
  alt?: string
  thumbnailOnly?: boolean
  onThumbnailClick?: () => void
}

const YouTube = forwardRef(function YouTubeInner(
  {
    youtubeId,
    copyright,
    description,
    thumbnail,
    alt,
    thumbnailOnly,
    onThumbnailClick,
    sx,
  }: YouTubeProps,
  ref
): ReactElement {
  const ytRef = useRef(null)
  const [init, setInit] = useState(true)
  const [showiFrame, setShowiFrame] = useState(false)
  const [player, setPlayer] = useState<YoutubePlayerTypes | null>(null)

  useEffect(() => {
    if (!init) {
      player?.playVideo()
    }
  }, [youtubeId, init])

  function initVideo() {
    if (typeof onThumbnailClick === 'function') {
      onThumbnailClick()
    }
    if (!player || thumbnailOnly) return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const iframe = (player as any).g || (player as any).h || (player as any).i
    if (!iframe) return
    iframe.setAttribute('tabindex', '0')
    iframe.focus()
    setShowiFrame(true)
    setInit(false)
  }

  useImperativeHandle(ref, () => ({
    playVideo() {
      initVideo()
      setInit(false)
    },
  }))

  return (
    <Box
      sx={[
        (theme) => ({
          '& .uc-embedding-container': {
            position: 'absolute',
            zIndex: 100,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '& .uc-embedding-wrapper': {
              padding: theme.spacing(4),
              borderRadius: 0,
              '& .uc-embedding-more-info': {
                borderRadius: 0,
              },
              '& .uc-embedding-accept': {
                borderRadius: 0,
              },
            },
            '& img': {
              display: 'none',
            },
          },
        }),
        sx as SOG.Props.SxInArray,
      ]}
      data-testid={'youtube-video'}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingTop: 'calc(9 / 16 * 100%)',
          backgroundColor: 'background.primary',
          overflow: 'hidden',
          '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        }}
      >
        {!thumbnailOnly && (
          <YouTubePlayer
            ref={ytRef}
            videoId={youtubeId}
            opts={{
              host: 'https://www.youtube-nocookie.com',
              playerVars: {
                rel: 0,
              },
            }}
            onReady={({ target }) => {
              setPlayer(target)
            }}
          />
        )}
        {!showiFrame && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={initVideo}
          >
            <Image
              {...(thumbnail || {})}
              data={
                thumbnail
                  ? thumbnail.data
                  : {
                      aspectRatio: 1.7778,
                      src: `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`,
                      srcSet: `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`,
                      sizes: '',
                      media: `(min-width: 0px)`,
                    }
              }
              alt={alt || `Youtube Video ${youtubeId}`}
            />
            <IconButton
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                cursor: 'pointer',
              }}
            />
          </Box>
        )}
      </Box>
      {(description || copyright) && (
        <div>
          {description && <Copy>{description}</Copy>}
          {copyright && <Copy>{copyright}</Copy>}
        </div>
      )}
    </Box>
  )
})

export default YouTube
