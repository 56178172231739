import React from 'react'
import { Theme, Box, Typography, useMediaQuery } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { motion } from 'framer-motion'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import FontSize from '@config/theme/definitions/fontSize'
import { FormattedMessage, useIntl } from 'react-intl'
import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import Headline from '@components/core/text/headline'
import Icon from '@components/core/ui/icon'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer/'
import {
  moduleSlideUpMotionProps,
  moduleSlideRightMotionProps,
} from '@utils/animation'

export type FactsProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption: string
    headline: string
    copy: SOG.Props.RichText
    entries: SOG.Props.IGlobalFact[]
    linkTo?: SOG.Props.LinkTo
  }

export type FactProps = SOG.Props.IDefault & {
  type: 'wide' | 'large' | 'small'
  bgTheme: SOG.Contentful.Theme
  caption?: string
  factValue: number
  hasSeparator: boolean
  descriptionMax: string
  descriptionMin: string
  icon: SOG.Props.Svg
  sx?: SystemStyleObject
  isEntry3Icon?: boolean
  isEntry4Icon?: boolean
}

export type CounterProps = SOG.Props.IDefault & {
  target: number
  duration?: number
  hasSeparator?: boolean
}

function Fact({
  caption,
  factValue,
  hasSeparator,
  descriptionMax,
  descriptionMin,
  icon,
  type,
  bgTheme,
  sx,
  isEntry3Icon,
  isEntry4Icon,
}: FactProps) {
  const Counter = ({ target, duration, hasSeparator }: CounterProps) => {
    const { locale } = useIntl()
    const [ref, inView] = useInView({
      threshold: 0.3,
      triggerOnce: true,
    })
    return (
      <div ref={ref}>
        <CountUp
          start={0}
          end={inView ? target : 0}
          duration={duration}
          separator={locale === 'de' ? '.' : ','}
          useEasing={true}
          useGrouping={hasSeparator}
        >
          {({ countUpRef }) => (
            <span
              className="text-2xl md:text-4xl font-black leading-none"
              ref={countUpRef}
            />
          )}
        </CountUp>
        <style jsx>{`
          .delay {
            transition-delay: ${duration}s;
          }
          .transform-hide {
            transform: translate3d(0, 1rem, 0);
          }
          .transform-show {
            transform: translate3d(0, 0, 0);
          }
        `}</style>
      </div>
    )
  }

  return (
    <StyledMotionDiv
      {...moduleSlideUpMotionProps}
      sx={[
        (theme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent:
            type === 'small' || type === 'wide' ? 'space-between' : 'flex-end',
          backgroundColor: 'background.light',
          p: 4,
          [theme.breakpoints.up('sm')]: {
            p: 6,
          },
          [theme.breakpoints.up('md')]: {
            p: type === 'small' ? 4 : 6,
          },
          [theme.breakpoints.up('lg')]: {
            p: type === 'small' ? 6 : 8,
          },
        }),
        sx,
      ]}
    >
      {icon && (
        <Box
          sx={(theme) => ({
            display: 'inline-block',
            justifyContent: 'flex-start',
            color: 'background.primary',
            mr: 6,
            width: '40%',
            height: '40%',
            [theme.breakpoints.up('md')]: {
              maxWidth: '100px',
              maxHeight: '100px',
              width: '50%',
              height: '50%',
              transform: isEntry3Icon && 'translateX(-30%)',
            },
          })}
          dangerouslySetInnerHTML={{ __html: icon.originalContent }}
        />
      )}
      <Box>
        {!isEntry4Icon && caption && (
          <Typography
            variant="paragraph"
            component="div"
            sx={(theme) => ({
              color: theme.palette.grey[500],
              ...theme.mixins.lineClamp(2),
            })}
          >
            {caption}
          </Typography>
        )}
        <Headline
          variant="h1"
          sx={(theme) => ({
            display: 'block',
            textTransform: 'none',
            color: bgTheme === 'medium' ? 'text.inverted' : 'text.secondary',
            fontWeight: 400,
            ...theme.mixins.fluidFontSize(
              'xs',
              'sm',
              'lg',
              bgTheme === 'medium' ? '5xl' : '4xl'
            ),
            ...theme.mixins.fluidFontSize(
              'md',
              'xl',
              'lg',
              bgTheme === 'medium' ? '6xl' : '4xl'
            ),
            [theme.breakpoints.up('xl')]: {
              fontSize:
                bgTheme === 'medium'
                  ? FontSize.get('6xl')
                  : FontSize.get('4xl'),
            },
          })}
        >
          <Counter
            target={factValue}
            duration={1}
            hasSeparator={hasSeparator}
          />
        </Headline>
        <Headline
          variant="h7"
          component="div"
          sx={(theme) => ({
            color: bgTheme === 'light' ? 'text.primary' : 'text.inverted',
            ...theme.mixins.fluidFontSize('xs', 'md', 'xs', 'lg'),
            ...theme.mixins.fluidFontSize('md', 'xl', '2lg', 'xl'),
            ...theme.mixins.lineClamp(type === 'small' ? 2 : 3),
            [theme.breakpoints.up('lg')]: {
              width: type === 'small' ? '80%' : '100%',
            },
            [theme.breakpoints.down('md')]: {
              lineHeight: '24px',
            },
          })}
        >
          {type === 'small' ? descriptionMin : descriptionMax}
        </Headline>
        {isEntry4Icon && caption && (
          <Typography
            component="div"
            sx={(theme) => ({
              color: theme.palette.grey[500],
              ...theme.mixins.lineClamp(2),
            })}
          >
            {caption}
          </Typography>
        )}
      </Box>
    </StyledMotionDiv>
  )
}

export default function Facts({
  caption,
  headline,
  copy,
  entries = [],
  linkTo,
}: FactsProps): React.ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <Section>
      <ModuleSection
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={
          linkTo
            ? {
                ...linkTo,
                label: <FormattedMessage id="button.readFurther" />,
              }
            : undefined
        }
        wrapped
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'auto auto auto auto',
            gap: 2,
            mt: 16,
            [theme.breakpoints.up('md')]: {
              gap: 8,
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridTemplateRows: 'auto auto auto',
            },
          })}
        >
          {!isMobile && (
            <motion.div {...moduleSlideRightMotionProps}>
              <Icon
                name="ArrowDownRight"
                fontSize="large"
                sx={{
                  width: '75%',
                  height: '75%',
                  gridArea: '1 / 1 / 1 / 1',
                }}
              />
            </motion.div>
          )}
          <Fact
            {...entries[1]}
            type={isMobile ? 'wide' : 'large'}
            bgTheme={'medium'}
            sx={(theme) => ({
              '& > :first-child': {
                '& > svg > g > path': { fill: 'white' },
                [theme.breakpoints.down('md')]: {
                  '& > svg': {
                    height: '100%',
                  },
                },
                [theme.breakpoints.up('md')]: {
                  maxWidth: '140px',
                  maxHeight: '140px',
                  width: '50%',
                  height: '50%',
                },
              },
              '& > :last-child > h1 > div > span': {
                [theme.breakpoints.down('sm')]: {
                  fontSize: 'calc(18px + 30 * ((100vw - 0px) / 640))',
                },
                fontSize: '48px',
              },
              backgroundColor: 'background.primary',
              gridArea: '3 / 1 / 4 / 3',
              aspectRatio: '2',
              [theme.breakpoints.up('md')]: {
                gridArea: '2 / 1 / 4 / 3',
                alignSelf: 'end',
                aspectRatio: '1',
              },
            })}
          />
          <Fact
            {...entries[0]}
            type={'large'}
            bgTheme={'medium'}
            sx={(theme) => ({
              '& > :first-child': {
                '& > svg > g > path': { fill: 'white' },
                mr: 0,
                ml: 'auto',
                mb: 'auto',
                [theme.breakpoints.up('md')]: {
                  maxWidth: '240px',
                  maxHeight: '240px',
                  width: '50%',
                  height: '50%',
                },
              },
              backgroundColor: 'background.secondary',
              gridArea: '1 / 1 / 3 / 3',
              aspectRatio: '1',
              [theme.breakpoints.up('md')]: {
                '& > :nth-child(2) > div': {
                  paddingTop: '24px',
                },
                height: '100%',
                width: '100%',
                gridArea: '1 / 2 / 3 / 4',
                alignSelf: 'end',
              },
            })}
          />
          <Fact
            {...entries[2]}
            type={'small'}
            bgTheme={'light'}
            sx={(theme) => ({
              gridArea: '4 / 1 / 5 / 2',
              [theme.breakpoints.up('md')]: {
                gridArea: '1 / 4 / 2 / 5',
              },
            })}
          />
          <Fact
            {...entries[3]}
            type={'small'}
            bgTheme={'light'}
            isEntry3Icon
            sx={(theme) => ({
              gridArea: '4 / 2 / 5 / 3',
              aspectRatio: '1',
              [theme.breakpoints.up('md')]: {
                gridArea: '2 / 4 / 3 / 5',
              },
            })}
          />
          <Fact
            {...entries[4]}
            type={'wide'}
            bgTheme={'light'}
            isEntry4Icon
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                '& > :first-child > svg': {
                  width: '45%',
                },
              },
              gridArea: '5 / 1 / 6 / 3',
              aspectRatio: '2',
              [theme.breakpoints.up('md')]: {
                gridArea: '3 / 3 / 4 / 5',
              },
            })}
          />
        </Box>
      </ModuleSection>
    </Section>
  )
}
