import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@mui/material'

import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import { FormattedMessage } from 'react-intl'
import { motion } from 'framer-motion'
import {
  moduleSlideLeftMotionProps,
  moduleSlideRightMotionProps,
} from '@utils/animation'

export type TeaserContentTextOnlyProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    copy: SOG.Props.RichText
    linkTo?: SOG.Props.LinkTo
    textSize?: 'Large'
  }

export default function TeaserContentTextOnly({
  contentful_id,
  caption,
  headline,
  copy,
  textSize,
  linkTo,
}: TeaserContentTextOnlyProps): React.ReactElement {
  const containerRef = useRef<HTMLElement>()
  const [animationAmount, setAnimationAmount] = useState<number>(0.01)

  useEffect(() => {
    const containerHeight = containerRef.current?.clientHeight ?? 0
    const windowHeight = window.innerHeight

    if (windowHeight < (containerHeight / 100) * 25) {
      setAnimationAmount(0.01)
    } else {
      setAnimationAmount(0.25)
    }
  }, [contentful_id])

  return (
    <Section
      ref={containerRef}
      id={contentful_id}
      sx={{ overflowX: 'hidden' }}
      maxWidth={textSize === 'Large' ? 'lg' : 'xl'}
    >
      <Box
        component={motion.div}
        {...moduleSlideRightMotionProps}
        viewport={{
          once: true,
          amount: animationAmount,
        }}
        sx={(sxTheme) => ({
          position: 'relative',
          marginBottom: '10px',
          [sxTheme.breakpoints.up('lg')]: {
            margin: '0 auto 10px auto',
            padding: '64px 0',
            width: textSize === 'Large' ? '100%' : '50vw',
          },
        })}
      >
        {caption && (
          <Headline
            variant="caption"
            sx={(sxTheme) => ({
              display: 'inline-block',
              mb: 2,
              [sxTheme.breakpoints.up('md')]: {
                mb: 4,
              },
            })}
          >
            {caption}
          </Headline>
        )}

        {headline && (
          <Headline
            variant="h3"
            sx={(theme) => ({
              hyphens: 'auto',
              fontWeight: 'regular',
              mb: 4,
              [theme.breakpoints.up('lg')]: {
                mb: 8,
              },
            })}
          >
            {headline}
          </Headline>
        )}
        <Copy variant={textSize === 'Large' ? 'textLarge' : 'body1'}>
          {copy}
        </Copy>
      </Box>

      {linkTo && (
        <motion.div {...moduleSlideLeftMotionProps}>
          <Button
            sx={{ margin: '0 100px' }}
            variant="primary"
            to={linkTo.url}
            isExternal={linkTo.isExternal}
          >
            <FormattedMessage id="button.learnMore" />
          </Button>
        </motion.div>
      )}
    </Section>
  )
}
