import React, { ElementType, ReactElement } from 'react'

import Typography from '@mui/material/Typography'
import { MotionProps } from 'framer-motion'

export type HeadlineProps = SOG.Props.IDefault &
  MotionProps & {
    level?: number
    id?: string
    html?: string
    variant?:
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'h7'
      | 'quote'
      | 'subline'
      | 'caption'
      | 'small'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: ElementType<any>
  }

export default function Headline({
  ref,
  children,
  id,
  html,
  variant = 'h2',
  component,
  initial,
  whileInView,
  viewport,
  sx,
}: HeadlineProps): ReactElement {
  // function renderChildren() {
  //   if (html) return null
  //   if (typeof children == 'string')
  //     return parse(
  //       children
  //         .replaceAll('  ', '<br>')
  //         .replaceAll('®', '<sup>®</sup>')
  //         .replaceAll('©', '<sup>©</sup>')
  //         .replaceAll('℗', '<sup>℗</sup>')
  //     )
  //   return children
  // }
  const additionalProps = {
    ...(html && {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    }),
    ...(component && {
      component,
      initial,
      whileInView,
      viewport,
    }),
  }

  return (
    <Typography
      ref={ref}
      id={id}
      variant={variant}
      {...additionalProps}
      sx={sx}
      gutterBottom={false}
    >
      {children}
    </Typography>
  )
}
