import { SxProps, Theme } from '@mui/material'
import Stage, { StageProps } from '@components/modules/stage'
import Teaser, { TeaserProps } from '@components/modules/teaser'
import News, { NewsProps } from '@components/modules/news'
import Quote, { QuoteProps } from '@components/modules/quote'
import BrandWall, { BrandWallProps } from '@components/modules/brandWall'
import Carousel, { CarouselProps } from '@components/modules/carousel'
import TeaserHighlight, {
  TeaserHighlightProps,
} from '@components/modules/teaserHighlight'
import TeaserContent, {
  TeaserContentProps,
} from '@components/modules/teaserContent'
import TeaserContentTextOnly, {
  TeaserContentTextOnlyProps,
} from '@components/modules/teaserContentTextOnly'
import {
  getAssetProp,
  getAssetPropList,
  getGlobalProp,
  getGlobalPropList,
  getRichTextProp,
} from '@utils/props'
import Jobs, { JobsProps } from '@components/modules/jobs'
import Benefits, { BenefitsProps } from '@components/modules/benefits'
import Tabs, { TabsProps } from '@components/modules/tabs'
import useContentfulNewestGlobalJobs from './hooks/contentful/useContentfulNewestGlobalJobs'
import useContentfulPageArticleDetail from './hooks/contentful/useContentfulPageArticleDetail'
import AccordionGroup, {
  AccordionGroupProps,
} from '@components/modules/accordionGroup'
import Management, { ManagementProps } from '@components/modules/management'
import Contacts, { ContactsProps } from '@components/modules/contacts'
import useContentfulGlobalMedia from './hooks/contentful/useContentfulGlobalMedia'
import Facts, { FactsProps } from '@components/modules/facts'
import ContactForm, { ContactFormProps } from '@components/modules/contactForm'
import TeaserPopup, { TeaserPopupProps } from '@components/modules/teaserPopup'
import LogoParallax, {
  LogoParallaxProps,
} from '@components/modules/logoParallax'
import ModuleHistory, { ModuleHistoryProps } from '@components/modules/history'
import VideoImage, { VideoImageProps } from '@components/modules/videoImage'

type PageModuleParserType = {
  __typename: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: (mod: any, sx?: SxProps<Theme>) => SOG.Props.ModuleUnion
}

const PageModuleParser: Array<PageModuleParserType> = [
  {
    __typename: 'ContentfulModuleStage',
    component: Stage,
    props: (mod: SOG.Contentful.IModuleStage, sx): StageProps => {
      const {
        teaserPage,
        teaserImage,
        images,
        backgroundImage,
        backgroundVideo,
        headlines,
        linkPage,
        fullViewOrWidescreen,
        ...rest
      } = mod
      return {
        ...rest,
        headline: headlines?.headlines,
        images: getAssetPropList(images) as SOG.Props.Image[],
        backgroundImage: getAssetProp(backgroundImage) as SOG.Props.Image,
        backgroundVideo: getAssetProp(backgroundVideo) as SOG.Props.Video,
        teaserImage: getAssetProp(teaserImage) as SOG.Props.Image,
        teaserLinkTo: teaserPage?.fields?.linkTo,
        linkTo: linkPage?.fields?.linkTo,
        fullViewOrWidescreen: fullViewOrWidescreen,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaser',
    component: Teaser,
    props: (mod: SOG.Contentful.IModuleTeaser, sx): TeaserProps => {
      const { linkPage, copy, images, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        images: getAssetPropList(images) as SOG.Props.Image[],
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserCollage',
    component: Teaser,
    props: (mod: SOG.Contentful.IModuleTeaser, sx): TeaserProps => {
      const { linkPage, copy, images, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        images: getAssetPropList(images) as SOG.Props.Image[],
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserContent',
    component: TeaserContent,
    props: (mod: SOG.Contentful.IModuleTeaserContent): TeaserContentProps => {
      const { linkPage, copy, image, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as SOG.Props.Image,
        linkTo: linkPage?.fields?.linkTo,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserContentTextOnly',
    component: TeaserContentTextOnly,
    props: (
      mod: SOG.Contentful.IModuleTeaserContentTextOnly
    ): TeaserContentTextOnlyProps => {
      const { linkPage, copy, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        linkTo: linkPage?.fields?.linkTo,
      }
    },
  },
  {
    __typename: 'ContentfulModuleHistory',
    component: ModuleHistory,
    props: (mod: ModuleHistoryProps): ModuleHistoryProps => mod,
  },
  {
    __typename: 'ContentfulModuleTeaserHighlight',
    component: TeaserHighlight,
    props: (
      mod: SOG.Contentful.IModuleTeaserHighlight,
      sx
    ): TeaserHighlightProps => {
      const { linkPage, copy, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleNews',
    component: News,
    props: (mod: SOG.Contentful.IModuleNews, sx): NewsProps => {
      const { copy, topics, linkPage, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        entries: useContentfulPageArticleDetail(topics),
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBrandWall',
    component: BrandWall,
    props: (mod: SOG.Contentful.IModuleBrandWall, sx): BrandWallProps => {
      const { copy, linkPage, brands, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        brands: getGlobalPropList(brands) as SOG.Props.IGlobalBrand[],
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleCarousel',
    component: Carousel,
    props: (mod: SOG.Contentful.IModuleCarousel, sx): CarouselProps => {
      const { copy, linkPage, entries, ...rest } = mod
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const items: any = entries
      const defaultProps = {
        copy: copy ? getRichTextProp(copy) : undefined,
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
      switch (entries?.[0]?.__typename) {
        case 'ContentfulGlobalContent':
          return {
            ...rest,
            ...defaultProps,
            type: 'content',
            entries: getGlobalPropList(items) as SOG.Props.IGlobalContent[],
          }
        case 'ContentfulGlobalStory':
          return {
            ...rest,
            ...defaultProps,
            type: 'stories',
            entries: getGlobalPropList(items) as SOG.Props.IGlobalStory[],
          }
        case 'ContentfulGlobalMedia':
          const entries = getGlobalPropList(items) as SOG.Props.IGlobalMedia[]
          return {
            ...rest,
            ...defaultProps,
            type: 'media',
            entries,
            lightboxEntries: useContentfulGlobalMedia(entries),
          }
        default:
          return {
            ...rest,
            ...defaultProps,
            entries: [],
          }
      }
    },
  },
  {
    __typename: 'ContentfulModuleAccordion',
    component: AccordionGroup,
    props: (mod: SOG.Contentful.IModuleAccordion, sx): AccordionGroupProps => {
      const { copy, entries, linkPage, ...rest } = mod
      return {
        ...rest,
        copy: copy ? getRichTextProp(copy) : undefined,
        entries: getGlobalPropList(
          entries
        ) as SOG.Props.IGlobalAccordionContent[],
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleQuote',
    component: Quote,
    props: (mod: SOG.Contentful.IModuleQuote, sx): QuoteProps => {
      const { copy, image, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as SOG.Props.Image,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleJobs',
    component: Jobs,
    props: (mod: SOG.Contentful.IModuleJobs, sx): JobsProps => {
      const { copy, linkPage, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        entries: useContentfulNewestGlobalJobs(3),
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBenefits',
    component: Benefits,
    props: (mod: SOG.Contentful.IModuleBenefits, sx): BenefitsProps => {
      const { linkPage, benefits, copy, ...rest } = mod
      return {
        ...rest,
        copy: copy ? getRichTextProp(copy) : undefined,
        benefits: getGlobalPropList(benefits) as SOG.Props.IGlobalBenefit[],
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFacts',
    component: Facts,
    props: (mod: SOG.Contentful.IModuleFacts, sx): FactsProps => {
      const { linkPage, facts, copy, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        entries: getGlobalPropList(facts) as SOG.Props.IGlobalFact[],
        linkTo: linkPage?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleContactForm',
    component: ContactForm,
    props: (mod: SOG.Contentful.IModuleContactForm, sx): ContactFormProps => {
      const { linkPage, copy, ...rest } = mod
      return {
        ...rest,
        linkTo: linkPage?.fields?.linkTo,
        copy: getRichTextProp(copy),
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleVideoImage',
    component: VideoImage,
    props: (mod: SOG.Contentful.IModuleVideoImage, sx): VideoImageProps => {
      const { copy, media, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        media: getGlobalProp(media) as SOG.Props.IGlobalVideoImage,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTabs',
    component: Tabs,
    props: (mod: SOG.Contentful.IModuleTabs, sx): TabsProps => {
      const { copy, tabContent, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        entries: getGlobalPropList(tabContent) as SOG.Props.IGlobalContent[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleManagement',
    component: Management,
    props: (mod: SOG.Contentful.IModuleManagement, sx): ManagementProps => {
      const { copy, employees, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        entries: getGlobalPropList(employees) as SOG.Props.IGlobalEmployee[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleContacts',
    component: Contacts,
    props: (mod: SOG.Contentful.IModuleContacts, sx): ContactsProps => {
      const { contacts, ...rest } = mod
      return {
        ...rest,
        entries: getGlobalPropList(contacts) as SOG.Props.IGlobalContact[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserPopup',
    component: TeaserPopup,
    props: (mod: SOG.Contentful.IModuleTeaserPropup, sx): TeaserPopupProps => {
      const { linkPage1, linkPage2, copy1, copy2, ...rest } = mod
      return {
        ...rest,
        copy1: getRichTextProp(copy1),
        copy2: getRichTextProp(copy2),
        linkTo1: linkPage1?.fields?.linkTo,
        linkTo2: linkPage2?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleLogoParallax',
    component: LogoParallax,
    props: (mod: SOG.Contentful.IModuleLogoParallax, sx): LogoParallaxProps => {
      const { logo, images, ...rest } = mod
      return {
        ...rest,
        logo: getAssetProp(logo) as SOG.Props.Image | SOG.Props.Svg,
        images: getAssetPropList(images) as SOG.Props.Image[],
        sx,
      }
    },
  },
]

export default PageModuleParser
