import React, { ReactElement } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Swiper as SwiperInstance, SwiperOptions } from 'swiper'
import deepmerge from 'deepmerge'
import { alpha, Box, SxProps, Theme } from '@mui/material'

import Breakpoints from '@config/theme/definitions/breakpoints'
import Button from '../button'

import 'swiper/css'

export type SliderUiProps = SOG.Props.IDefault & {
  type?: 'overlay'
  loop?: boolean
  dir?: 'ltr' | 'rtl'
  theme?: SOG.Contentful.Theme
}

export const SliderUi = ({
  theme,
  sx,
  type,
  dir = 'ltr',
  loop,
}: SliderUiProps) => {
  const swiper = useSwiper()
  if (type === 'overlay') {
    return (
      <Box
        sx={[
          (theme) => ({
            display: 'flex',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
            // pointerEvents: 'none', // This is commented out to handle the arrows in Lightbox, it was disabled with this rule otherwise
            '.MuiButton-root': {
              color: 'text.inverted',
              border: 'none',
              backgroundColor: alpha(
                theme.palette.background.primary as string,
                0.5
              ),
            },
            '.MuiButton-root:hover': {
              color: 'text.inverted',
              backgroundColor: alpha(
                theme.palette.background.secondary as string,
                0.25
              ),
            },
          }),
          sx as SOG.Props.SxInArray,
        ]}
      >
        <Button
          variant="secondary"
          icon="ChevronLeft"
          onClick={() => swiper.slidePrev()}
          isDisabled={!loop && swiper.progress <= 0}
          sx={(theme) => ({
            pointerEvents: 'auto',
            [theme.breakpoints.down('lg')]: {
              ml: -8,
            },
            [theme.breakpoints.down('md')]: {
              ml: -6,
            },
          })}
        />
        <Button
          variant="secondary"
          icon="ChevronRight"
          onClick={() => swiper.slideNext()}
          isDisabled={!loop && swiper.progress >= 1}
          sx={(theme) => ({
            pointerEvents: 'auto',
            [theme.breakpoints.down('lg')]: {
              mr: -8,
            },
            [theme.breakpoints.down('md')]: {
              mr: -6,
            },
          })}
        />
      </Box>
    )
  }
  return (
    <Box
      sx={[
        {
          display: 'flex',
          mt: 8,
          justifyContent: 'flex-end',
          flexDirection: dir === 'rtl' ? 'row-reverse' : undefined,
          zIndex: 1,
        },
        sx as SOG.Props.SxInArray,
      ]}
    >
      <Button
        theme={theme}
        variant="secondary"
        icon="ArrowDownLeft"
        sx={{ mr: '2px' }}
        onClick={() => swiper.slidePrev()}
        isDisabled={!loop && swiper.progress <= 0}
      />
      <Button
        theme={theme}
        variant="secondary"
        icon="ArrowUpRight"
        onClick={() => swiper.slideNext()}
        isDisabled={!loop && swiper.progress >= 1}
      />
    </Box>
  )
}

export type SlideProps = SOG.Props.IDefault & {
  dir?: 'ltr' | 'rtl'
}

const scaleZoomProgress = (swiper: SwiperInstance, progress: number) => {
  const totalSlides = swiper.slides.length - 1
  const slideWidth = swiper.width
  const stepSize = 1 / totalSlides
  swiper.slides.forEach((slide: any, index) => {
    const slideProgress = (index / totalSlides - progress) / stepSize
    if (slideProgress < 0) {
      slide.style.width = `100%`
      const scale = 1 - Math.min(1, slideProgress * -1) * 0.25
      slide.style.transform = `
        translateX(${slideProgress * slideWidth}px)
        scale(${slideProgress < -(1 + stepSize) ? 0 : scale})
      `
    } else if (slideProgress > 0) {
      const width =
        slideProgress < 1 ? `${(1 - slideProgress) * 50 + 50}%` : '50%'
      slide.style.width = width
      slide.style.transform = 'translateX(0%) scale(1)'
    } else {
      slide.style.transform = 'translateX(0%) scale(1)'
      slide.style.width = `100%`
      slide.style.opacity = 1
    }
  })
}
const scaleZoomSetTransition = (swiper: SwiperInstance, transition: number) => {
  swiper.slides.forEach((slide: any) => {
    slide.style.transition = `width ${transition}ms ease, transform ${transition}ms ease`
  })
}

type SliderOptionsType = SwiperOptions & {
  height?: SOG.Props.NumberStringBreakpoint
}
type SliderBreakpointsType = {
  sm?: SliderOptionsType
  md?: SliderOptionsType
  lg?: SliderOptionsType
}

export type SliderProps = SOG.Props.IDefault & {
  type?: 'scaleZoom' | 'horizontal'
  arrowsType?: 'overlay' | 'none'
  slidesPerView?: number | 'auto'
  overflow?: boolean
  centeredSlides?: boolean
  loop?: boolean
  initialSlide?: number
  onChangeSlide?: (activeIndex: number) => void
  sxUi?: SxProps<Theme> | undefined
  breakpoints?: SliderBreakpointsType
  theme?: SOG.Contentful.Theme
}
export default function Slider({
  type,
  arrowsType,
  slidesPerView = 1,
  overflow = false,
  centeredSlides = false,
  loop = false,
  initialSlide = 0,
  onChangeSlide,
  children,
  sx,
  sxUi,
  theme,
  breakpoints = {},
}: SliderProps): ReactElement {
  const isScaleZoom = type === 'scaleZoom'
  const slides = React.Children.toArray(children)
  const direction = isScaleZoom ? 'rtl' : 'ltr'
  function getBreakpointOptions() {
    const options = deepmerge(
      breakpoints,
      type === 'horizontal'
        ? {}
        : {
            md: {
              spaceBetween: 32,
            },
            lg: {
              spaceBetween: 32,
            },
          }
    )
    return Object.entries(options).reduce(
      (obj: SliderBreakpointsType, [key, value]) => ({
        ...obj,
        [Breakpoints?.values?.[key]]: value,
      }),
      {}
    )
  }

  return (
    <Box
      sx={[
        sx as SOG.Props.SxInArray,
        (theme) => ({
          width: '100%',
          '> .swiper': {
            position: 'initial',
            // height: 'inherit',
            width: '100%',
            overflow: overflow ? 'visible' : undefined,
          },
          '.swiper-wrapper': {
            alignItems: isScaleZoom ? 'flex-end' : undefined,
            height: breakpoints?.sm?.height,
            [theme.breakpoints.up('md')]: {
              height: breakpoints?.md?.height,
            },
            [theme.breakpoints.up('lg')]: {
              height: breakpoints?.lg?.height,
            },
          },
          '.swiper-slide': {
            width: !isScaleZoom ? 'auto' : undefined,
          },
        }),
      ]}
    >
      <Swiper
        speed={750}
        spaceBetween={8}
        touchReleaseOnEdges={true}
        centeredSlides={centeredSlides}
        loop={loop}
        initialSlide={initialSlide}
        slidesPerView={isScaleZoom ? 1 : slidesPerView}
        dir={direction}
        breakpoints={getBreakpointOptions()}
        onSlideChange={
          onChangeSlide
            ? (swiper: SwiperInstance) => onChangeSlide(swiper.realIndex)
            : undefined
        }
        onProgress={isScaleZoom ? scaleZoomProgress : undefined}
        onSetTransition={isScaleZoom ? scaleZoomSetTransition : undefined}
      >
        {slides.map((child, index) => {
          return <SwiperSlide key={index}>{child}</SwiperSlide>
        })}
        {arrowsType !== 'none' && (
          <SliderUi
            theme={theme}
            dir={direction}
            type={arrowsType}
            loop={loop}
            sx={sxUi}
          />
        )}
      </Swiper>
    </Box>
  )
}
