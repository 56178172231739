import { fontFamily } from '@config/theme/definitions/fontFamily'
import { Components, rgbToHex, Theme } from '@mui/material'

import FontSize from '../../definitions/fontSize'

export const ButtonOverrides = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      fontFamily: fontFamily,
      fontSize: FontSize.get('base'),
      lineHeight: FontSize.get('base'),
      textTransform: 'none',
      borderRadius: 0,
      '.MuiButton-touchRipple': {},
    },
    text: {
      color: 'inherit',
      padding: 0,
      endIcon: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('lg')]: {
          marginLeft: theme.spacing(2),
        },
        '>*:nth-of-type(1)': {
          fontSize: '10px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: FontSize.get('xs'),
      },
      '.MuiButton-endIcon': {
        transform: 'translateX(0%)',
        transition: 'transform 1s ease',
      },
    },
    contained: {
      fontWeight: 500,
      '& .MuiButton-label, .MuiButton-endIcon, .MuiButton-startIcon': {
        zIndex: 1,
      },
      '&.MuiButton-containedTertiary': {
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.grey[500],
        '&:hover': {
          color: theme.palette.text.primary,
          borderColor: theme.palette.text.primary,
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgb(170, 170, 170)',
        border: '2px solid transparent',
        color: theme.palette.common.white,
      },
    },
    containedPrimary: {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },
    },
    containedSecondary: {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[900]}`,
      backgroundColor: theme.palette.grey[900],
      '&:hover': {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        backgroundColor: 'transparent',
      },
    },
    containedSizeSmall: {
      padding: 0,
      minWidth: '48px',
      minHeight: '48px',
      '&.MuiButton-containedPadding': {
        padding: theme.spacing(0, 6),
      },
      '.MuiButton-startIcon': {
        fontSize: '16px',
      },
      '.MuiButton-endIcon': {
        marginLeft: theme.spacing(3),
        fontSize: '16px',
      },
    },
    containedSizeMedium: {
      padding: 0,
      minWidth: '48px',
      minHeight: '48px',
      [theme.breakpoints.up('lg')]: {
        minWidth: '56px',
        minHeight: '56px',
        fontSize: FontSize.get('base'),
      },
      '&.MuiButton-containedPadding': {
        padding: theme.spacing(0, 4),
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(0, 5),
        },
      },
      '.MuiButton-startIcon': {
        fontSize: '16px',
        [theme.breakpoints.up('lg')]: {
          fontSize: '20px',
        },
      },
      '.MuiButton-endIcon': {
        fontSize: '16px',
        marginLeft: theme.spacing(3),
        [theme.breakpoints.up('lg')]: {
          marginLeft: theme.spacing(4),
          fontSize: '20px',
        },
      },
    },
    containedSizeLarge: {
      padding: 0,
      minWidth: '64px',
      minHeight: '64px',
      [theme.breakpoints.up('lg')]: {
        minWidth: '80px',
        minHeight: '80px',
        fontSize: FontSize.get('2lg'),
      },
      '&.MuiButton-containedPadding': {
        padding: theme.spacing(0, 6),
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(0, 8),
        },
      },
      '.MuiButton-startIcon': {
        fontSize: '20px',
        [theme.breakpoints.up('lg')]: {
          fontSize: '32px',
        },
      },
      '.MuiButton-endIcon': {
        marginLeft: theme.spacing(4),
        fontSize: '16px',
        [theme.breakpoints.up('lg')]: {
          marginLeft: theme.spacing(6.25),
          fontSize: '24px',
        },
      },
    },

    startIcon: {
      margin: 0,
      '>*:nth-of-type(1)': {
        fontSize: 'inherit',
      },
    },
    endIcon: {
      '>*:nth-of-type(1)': {
        fontSize: 'inherit',
      },
    },
  },
})
