import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'

import Image from '@components/core/media/image'
import Section from '@components/core/container/section'
import { StyledMotionDiv } from '@components/core/container/fowardPropsContainer'
import { moduleSlideUpMotionProps } from '@utils/animation'
import { useViewportScroll } from 'framer-motion'

export type LogoParallaxProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    logo: SOG.Props.Svg | SOG.Props.Image
    images: SOG.Props.Image[]
  }

export default function LogoParallax({
  logo,
  images = [],
}: LogoParallaxProps): React.ReactElement {
  const ref = useRef<HTMLDivElement>()
  const { scrollY } = useViewportScroll()
  const [scrollYProgress, setScrollYProgress] = useState<number>(0)
  const isLogoSvg = !!(logo as SOG.Props.Svg)?.originalContent
  const imagesBehind = images.slice(0, 2)
  const imageFront = images[2]
  useEffect(() => {
    return scrollY.onChange((v) => {
      const h = ref.current?.clientHeight || 0
      const wh = window.innerHeight
      const y1 = (ref.current?.offsetTop || 0) - wh
      const y2 = y1 + h + wh

      if (v >= y1 && v <= y2) {
        setScrollYProgress((y1 - v) / -(h + wh))
      }
    })
  }, [])

  const height = ref.current?.clientHeight || 0
  const getTransformY = (index = 0) => {
    const range = ((index + 1) * height) / 4
    return -range * scrollYProgress
  }
  return (
    <Section ref={ref}>
      <Box
        sx={(theme) => ({
          position: 'relative',
          height: imageFront ? 500 : 300,
          [theme.breakpoints.up('sm')]: {
            height: imageFront ? 700 : 500,
          },
          [theme.breakpoints.up('lg')]: {
            height: imageFront ? 1000 : 800,
          },
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: imageFront ? 'center' : 'flex-end',
            height: '100%',
          }}
        >
          {imagesBehind.length > 0 &&
            imagesBehind.map((entry, index) => (
              <StyledMotionDiv
                animate={{
                  y: getTransformY(index),
                }}
                transition={{ type: 'tween', ease: 'linear', duration: 0 }}
                sx={{
                  width: '35%',
                  zIndex: 0,
                }}
                key={index}
              >
                <Image {...(entry as SOG.Props.Image)} />
              </StyledMotionDiv>
            ))}
        </Box>
        {logo && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              right: 0,
              // width: '100%',
              height: '100%',
              zIndex: 1,
              [theme.breakpoints.up('md')]: {},
            })}
          >
            <StyledMotionDiv
              {...moduleSlideUpMotionProps}
              sx={(theme) => ({
                width: '95%',
                display: 'inline-flex',
                justifyContent: 'center',
                [theme.breakpoints.up('md')]: {
                  width: '80%',
                },
              })}
            >
              {isLogoSvg && (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: (logo as SOG.Props.Svg)?.originalContent,
                  }}
                  sx={{ width: '100%' }}
                />
              )}
              {!isLogoSvg && <Image {...(logo as SOG.Props.Image)} />}
            </StyledMotionDiv>
          </Box>
        )}
        {imageFront && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              top: height * 0.5,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              [theme.breakpoints.up('md')]: {
                top: height * 0.6,
              },
              [theme.breakpoints.up('lg')]: {
                top: height * 0.75,
              },
            })}
          >
            <StyledMotionDiv
              animate={{
                y: getTransformY(2),
              }}
              transition={{ type: 'tween', ease: 'linear', duration: 0 }}
              sx={(theme) => ({
                ml: 30,

                width: '40%',
                zIndex: 2,
                [theme.breakpoints.up('md')]: {
                  width: '32%',
                },
              })}
            >
              <Image {...imageFront} />
            </StyledMotionDiv>
          </Box>
        )}
      </Box>
    </Section>
  )
}
