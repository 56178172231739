import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import { moduleSlideUpMotionProps } from '@utils/animation'
import Accordion from '@components/core/ui/accordion'
import useAccordionControls from '@components/system/hooks/useAccordionControls'
import Section from '@components/core/container/section'
import ModuleSection from '@components/core/container/moduleSection'
import Copy from '@components/core/text/copy'

export type AccordionGroupProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    copy?: SOG.Props.RichText
    linkTo?: SOG.Props.LinkTo
    entries: SOG.Props.IGlobalAccordionContent[]
  }

export default function AccordionGroup({
  caption,
  headline,
  copy,
  linkTo,
  entries,
  sx,
}: AccordionGroupProps): ReactElement {
  const [activeAccordionId, handleAccordionChange] = useAccordionControls(false)
  return (
    <Section sx={sx}>
      <ModuleSection
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={
          linkTo
            ? {
                ...linkTo,
                label: <FormattedMessage id="button.learnMore" />,
              }
            : undefined
        }
        sx={(theme) => ({
          '& p.MuiTypography-root': {
            [theme.breakpoints.up('lg')]: {
              width: '66%',
            },
          },
        })}
      >
        <Box
          sx={(theme) => ({
            mt: 10,
            '> .MuiAccordion-root + .MuiAccordion-root': {
              mt: 0.5,
              '&::before': {
                backgroundColor: theme.palette.common.white,
              },
            },
            [theme.breakpoints.up('md')]: {
              mb: 0,
            },
          })}
        >
          {entries.map((itemProps, index) => (
            <Accordion
              component={motion.div}
              {...moduleSlideUpMotionProps}
              key={index}
              title={itemProps.title}
              theme={itemProps.theme}
              expanded={activeAccordionId === index}
              onChange={(event, isExpanded) =>
                handleAccordionChange(index, isExpanded)
              }
            >
              <Copy>{itemProps.content}</Copy>
            </Accordion>
          ))}
        </Box>
      </ModuleSection>
    </Section>
  )
}
