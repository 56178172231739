import React, { ReactElement } from 'react'

import { Box, IconButton } from '@mui/material'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import Button from '../button'
import Icon from '../icon'

export type PopupSectionProps = SOG.Props.IDefault & {
  headline: string
  copy: SOG.Props.RichText
  linkLabel: string
  linkTo: SOG.Props.LinkTo
  onClose?: () => void
  theme?: SOG.Contentful.Theme
}

function PopupSection({
  headline,
  copy,
  linkLabel,
  linkTo,
  theme,
  onClose,
  sx,
}: PopupSectionProps): ReactElement {
  return (
    <Box
      sx={[
        sx as SOG.Props.SxInArray,
        (theme) => ({
          display: 'flex',
          flexDirection: 'column',
          px: 8,
          py: 10,
          [theme.breakpoints.up('lg')]: {
            p: 20,
            width: '50%',
          },
        }),
      ]}
    >
      <Headline
        variant="h3"
        sx={(sxTheme) => ({
          mb: 4,
          hyphens: 'auto',
          [sxTheme.breakpoints.up('lg')]: {
            mb: 8,
            color:
              theme === null || theme === 'light'
                ? sxTheme.palette.common.black
                : sxTheme.palette.common.white,
          },
        })}
      >
        {headline}
      </Headline>
      <Copy
        sx={(sxTheme) => ({
          mb: 10,
          [sxTheme.breakpoints.up('lg')]: {
            mb: 16,
            color:
              theme === null || theme === 'light'
                ? sxTheme.palette.common.black
                : sxTheme.palette.common.white,
          },
        })}
      >
        {copy}
      </Copy>
      <Button
        to={linkTo.url}
        isExternal={linkTo.isExternal}
        onClick={onClose}
        variant="secondary"
        theme={theme}
        sx={{
          mt: 'auto',
          alignSelf: 'flex-start',
        }}
      >
        {linkLabel || linkTo.label}
      </Button>
    </Box>
  )
}

export type PopupProps = SOG.Props.IDefault & {
  headline1: string
  headline2: string
  copy1: SOG.Props.RichText
  copy2: SOG.Props.RichText
  linkLabel1: string
  linkLabel2: string
  linkTo1: SOG.Props.LinkTo
  linkTo2: SOG.Props.LinkTo
  theme?: SOG.Contentful.Theme
  onClose?: () => void
}

export default function Popup({
  headline1,
  headline2,
  copy1,
  copy2,
  linkLabel1,
  linkLabel2,
  linkTo1,
  linkTo2,
  theme,
  onClose,
  sx,
}: PopupProps): ReactElement {
  return (
    <Box
      sx={[
        sx as SOG.Props.SxInArray,
        (theme) => ({
          position: 'relative',
          backgroundColor: 'common.white',
          color: 'text.primary',
          [theme.breakpoints.up('lg')]: {
            display: 'flex',
          },
        }),
      ]}
    >
      <Box
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 6,
          top: 6,
          zIndex: 2,
          color: 'text.inverted',
          [theme.breakpoints.up('lg')]: {
            right: 12,
            top: 12,
            color: 'text.primary',
          },
        })}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: 'common.black',
          }}
        >
          <Icon name="Close" />
        </IconButton>
      </Box>
      <PopupSection
        headline={headline1}
        copy={copy1}
        linkLabel={linkLabel1}
        linkTo={linkTo1}
        onClose={onClose}
        theme={theme}
        sx={(sxTheme) => ({
          backgroundColor:
            theme === 'dark'
              ? sxTheme.palette.background.dark
              : theme === 'medium'
              ? sxTheme.palette.background.medium
              : sxTheme.palette.background.light,
          color:
            theme === 'light' ? sxTheme.palette.text.primary : 'text.inverted',
        })}
      />
      <PopupSection
        headline={headline2}
        copy={copy2}
        linkLabel={linkLabel2}
        linkTo={linkTo2}
        onClose={onClose}
        sx={(sxTheme) => ({
          '& > h3, div': {
            color: sxTheme.palette.common.black,
          },
        })}
      />
    </Box>
  )
}
