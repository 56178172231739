import React from 'react'
import { Box } from '@mui/material'

import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import { FormattedMessage } from 'react-intl'
import { motion } from 'framer-motion'
import {
  moduleSlideRightMotionProps,
  addDelayToWhileInView,
  moduleSlideLeftMotionProps,
} from '@utils/animation'

export type TeaserHighlightProps = SOG.Props.IDefault &
  SOG.Contentful.INodeDefaults & {
    theme?: SOG.Contentful.Theme
    headline: string
    subline: string
    copy: SOG.Props.RichText
    anchor: {
      id: string
    }
    linkLabel?: string
    linkTo?: SOG.Props.LinkTo
  }

export default function TeaserHighlight({
  headline,
  subline,
  copy,
  anchor,
  linkLabel,
  linkTo,
  theme,
  sx = {},
}: TeaserHighlightProps): React.ReactElement {
  return (
    <Section theme={theme} sx={sx}>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'flex',

            '& > *:first-of-type': { width: '60%', pr: 10 },
            '& > *:last-of-type': {
              width: '40%',
              // alignSelf: 'flex-end',
              pt: 2,
            },
          },
          [theme.breakpoints.up('lg')]: {
            '& > *:first-of-type': { width: '65%' },
            '& > *:last-of-type': {
              width: '35%',
              pt: 4,
            },
          },
        })}
      >
        <Box
          component={motion.div}
          {...moduleSlideRightMotionProps}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              mb: 6,
            },
          })}
        >
          <Headline
            variant="h2"
            sx={(theme) => ({
              mb: 6,
              [theme.breakpoints.up('lg')]: {
                mb: 8,
              },
            })}
          >
            {headline}
          </Headline>
          <Headline variant="h7">{subline}</Headline>
        </Box>
        <Box>
          <Copy
            component={motion.div}
            {...moduleSlideLeftMotionProps}
            whileInView={addDelayToWhileInView(
              moduleSlideLeftMotionProps.whileInView,
              0.2
            )}
            transition={{ delay: 1 }}
            sx={
              linkTo || anchor
                ? (theme) => ({
                    mb: 6,
                    [theme.breakpoints.up('lg')]: {
                      mb: 8,
                    },
                  })
                : undefined
            }
          >
            {copy}
          </Copy>
          {(linkTo || anchor) && (
            <motion.div
              {...moduleSlideLeftMotionProps}
              whileInView={addDelayToWhileInView(
                moduleSlideLeftMotionProps.whileInView,
                0.2
              )}
            >
              <Button
                theme={theme}
                variant="secondary"
                to={linkTo?.url || `#${anchor?.id}`}
                isExternal={linkTo?.isExternal || false}
                isAnchor={!!anchor}
              >
                {!!linkLabel && linkLabel}
                {!linkLabel && <FormattedMessage id="button.learnMore" />}
              </Button>
            </motion.div>
          )}
        </Box>
      </Box>
    </Section>
  )
}
