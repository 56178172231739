import useCallbackRef from './useCallbackRef'
import { useState, useEffect, RefObject } from 'react'

export default function useMeasure(ref: RefObject<HTMLElement> | null) {
  const [element, attachRef] = useCallbackRef()
  const [bounds, setBounds] = useState({ height: 0 })

  useEffect(() => {
    function onResize([entry]: ResizeObserverEntry[]) {
      setBounds({
        height: entry.contentRect.height,
      })
    }

    const observer = new ResizeObserver(onResize)

    if (element && element.current) {
      observer.observe(element.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [element])

  useEffect(() => {
    attachRef(ref)
  }, [attachRef, ref])

  return bounds
}
