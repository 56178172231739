import React, { ReactElement } from 'react'

import { Typography } from '@mui/material'
import { Box } from '@mui/material'

export type QuoteProps = SOG.Props.IDefault & {
  author?: string
  position?: string
  html?: string
  size?: 'small' | 'large'
  theme?: SOG.Contentful.Theme
}

export default function Quote({
  author,
  position,
  children,
  html,
  size = 'large',
  sx,
  theme,
}: QuoteProps): ReactElement {
  const additionalProps = {
    ...(html && {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    }),
  }

  const withCaptions = author || position
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
        },
        sx as SOG.Props.SxInArray,
      ]}
    >
      {size === 'small' && (
        <Box
          component="span"
          sx={(theme) => ({
            alignSelf: 'flex-start',
            color: 'text.secondary',
            fontSize: '150px',
            lineHeight: 1,
            mt: -3,
            [theme.breakpoints.up('lg')]: {
              mt: -1.5,
            },
          })}
        >
          ”
        </Box>
      )}

      <Box component="span">
        <Typography
          variant={size === 'small' ? 'quoteSmall' : 'quote'}
          component={size === 'small' ? 'span' : 'q'}
          {...additionalProps}
          sx={(sxTheme) => ({
            display: 'inline-block',
            mb: withCaptions ? 6 : undefined,
            wordBreak: 'break-word',
            '& > *': {
              display: 'inline',
            },
            '& u': {
              textDecorationColor:
                theme === 'medium'
                  ? sxTheme.palette.background.dark
                  : theme === 'light'
                  ? sxTheme.palette.background.medium
                  : '#e50040',
            },
          })}
          gutterBottom={false}
        >
          {children}
        </Typography>
        {author && (
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mb: 1,
            }}
          >
            {author}
          </Typography>
        )}
        {position && (
          <Typography
            variant="caption"
            sx={{ display: 'block', textTransform: 'none' }}
          >
            {position}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
