import React, { useEffect } from 'react'
import useGlobalState from '@components/system/hooks/useGlobalState'
import useLightbox from '@components/system/hooks/useLightbox'
import { PopupProps } from '@components/core/ui/popup'

export type TeaserPopupProps = SOG.Contentful.INodeDefaults & PopupProps

export default function TeaserPopup({
  id,
  headline1,
  headline2,
  copy1,
  copy2,
  linkLabel1,
  linkLabel2,
  linkTo1,
  linkTo2,
  theme,
}: TeaserPopupProps): React.ReactElement | null {
  const { hasPopupBeenShown, setPopupAsShown } = useGlobalState()
  const { showDialog } = useLightbox()

  useEffect(() => {
    if (hasPopupBeenShown(id)) return
    setTimeout(() => {
      showDialog({
        id,
        type: 'popup',
        props: {
          headline1,
          headline2,
          copy1,
          copy2,
          linkLabel1,
          linkLabel2,
          linkTo1,
          linkTo2,
          theme,
        },
      })
      setPopupAsShown(id)
    }, 1000)
  }, [])
  return null
}
