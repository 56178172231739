import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { AppBar, Toolbar, Box, IconButton, Container } from '@mui/material'
import Menu from '@mui/material/Menu'
import { motion, useViewportScroll } from 'framer-motion'

import theme from '@config/theme'
import HorizontalNav from '@components/modules/navigation/horizontalNav'
import DrawerNav from '@components/modules/navigation/drawerNav'
import HorizontalSearch from '@components/modules/navigation/horizontalSearch'
import Burger from '@components/core/ui/burger'
import Icon from '@components/core/ui/icon'
import { appBarMotionProps, naviDimmerMotionProps } from '@utils/animation'
import HeaderPortalProvider from '@components/system/providers/HeaderPortalProvider'
import CollapsableContainer from '../collapsableContainer'

import IconLogo from '@static/img/sog-logo.svg'
import { StyledGatsbyLink } from '../fowardPropsContainer'
import { reduce } from 'bluebird'

export type HeaderProps = {
  mainnav: Array<SOG.Props.LinkTo>
  metanav?: Array<SOG.Props.LinkTo>
  reducedHeader?: boolean
}

export default function Header({
  mainnav,
  reducedHeader,
}: HeaderProps): ReactElement {
  const [appAniVariant, setAppAniVariant] = useState<'hide' | 'show'>('show')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const scroll = useViewportScroll()
  const portalRef = useRef<HTMLElement>(null)

  /* const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  } */

  useEffect(() => {
    let prevScrollY = scroll.scrollY.get()
    scroll.scrollY.onChange((v) => {
      let variant: 'hide' | 'show' = 'show'
      const scrollY = scroll.scrollY.get()
      if (scrollY > 80 && scrollY > prevScrollY) {
        variant = 'hide'
      }
      prevScrollY = scroll.scrollY.get()
      if (appAniVariant !== variant) {
        setAppAniVariant(variant)
      }
    })
  }, [scroll, appAniVariant])

  useEffect(() => {
    if (flyoutOpen !== navOpen || searchOpen) {
      setFlyoutOpen(navOpen || searchOpen)
    }
  }, [navOpen, searchOpen])

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  function toggleDrawer(open: boolean) {
    setDrawerOpen(open)
  }

  const homeItem = mainnav[0]
  const filteredMainnav = mainnav.slice(1)

  return (
    <React.Fragment>
      <Box
        component={motion.div}
        animate={navOpen || searchOpen ? 'show' : 'hide'}
        {...naviDimmerMotionProps}
        sx={(theme) => ({
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '150%',
          backgroundColor: 'background.dark',
          background:
            'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%)',
          zIndex: theme.zIndex.appBar,
        })}
      />
      <AppBar
        component={motion.header}
        animate={appAniVariant}
        {...appBarMotionProps}
        sx={{ position: 'fixed', pr: '0 !important' }}
      >
        <HeaderPortalProvider portalRef={portalRef} wasOpen={flyoutOpen}>
          <Toolbar
            disableGutters
            sx={(theme) => ({
              maxWidth: '100vw',
              minHeight: theme.spacing(17),
            })}
          >
            <Container maxWidth="lg" sx={{ display: 'flex' }}>
              <StyledGatsbyLink
                to={homeItem.url}
                sx={{
                  mr: 2,
                  display: { xs: 'block', md: 'flex' },
                  alignSelf: 'center',
                  width: { xs: '187px', md: '227px' },
                  '& > svg': {
                    display: 'flex',
                  },
                }}
                onClick={() => toggleDrawer(false)}
              >
                <IconLogo />
              </StyledGatsbyLink>
              {!reducedHeader && (
                <Box
                  sx={{
                    display: { xs: 'none', lg: 'flex' },
                    mx: 'auto',
                  }}
                >
                  <HorizontalNav
                    items={filteredMainnav}
                    onOpen={setNavOpen}
                    forceClose={appAniVariant === 'hide'}
                  />
                </Box>
              )}

              {!reducedHeader && (
                <>
                  <Box
                    sx={{
                      display: { xs: 'none', lg: 'flex' },
                    }}
                  >
                    <HorizontalSearch
                      items={filteredMainnav}
                      onOpen={setSearchOpen}
                    />
                  </Box>

                  <Box
                    sx={{
                      minHeight: '50px',
                      flexGrow: 1,
                      justifyContent: 'right',
                      display: { xs: 'flex', lg: 'none' },
                      gap: '16px',
                    }}
                  >
                    <IconButton
                      size="large"
                      aria-label="Search Icon"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => toggleDrawer(!drawerOpen)}
                      color="inherit"
                      sx={{
                        display: !drawerOpen ? 'block' : 'none',
                        padding: '0',
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          justifyContent: 'right',
                          display: { xs: 'flex', lg: 'none' },
                        }}
                      >
                        <Icon name="Search" />
                      </Box>
                    </IconButton>
                    <IconButton
                      size="large"
                      aria-label="Burger Menu"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => toggleDrawer(!drawerOpen)}
                      color="inherit"
                      sx={{
                        mr: theme.spacing(-2),
                        padding: '0',
                      }}
                    >
                      <Burger active={drawerOpen} />
                    </IconButton>
                  </Box>
                </>
              )}
            </Container>
          </Toolbar>
        </HeaderPortalProvider>
        <CollapsableContainer
          isOpen={navOpen || searchOpen}
          transitionOpen={{
            type: 'tween',
            ease: 'easeOut',
            duration: 0.4,
            delay: flyoutOpen ? 0.1 : 0,
          }}
          transitionClose={{
            type: 'tween',
            ease: 'easeIn',
            duration: 0.4,
          }}
          sx={(theme) => ({
            backgroundColor: 'common.white',
            transition: 'background-color 250ms ease 250ms',
            borderTop: '1px solid',
            borderBottom: navOpen || searchOpen ? '1px solid' : undefined,
            borderColor: theme.palette.grey[300],
          })}
        >
          <Box
            sx={{
              position: 'relative',
            }}
            ref={portalRef}
          />
        </CollapsableContainer>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { sm: 'block', lg: 'none' },
          }}
        >
          <DrawerNav
            items={filteredMainnav}
            openState={drawerOpen}
            toggleDrawer={toggleDrawer}
          ></DrawerNav>
        </Menu>
      </AppBar>
    </React.Fragment>
  )
}
