import React, { createContext, ReactElement } from 'react'

export interface IHeaderPortalContext {
  portalRef: React.RefObject<HTMLElement> | null
  wasOpen: boolean
}

export const PortalContext = createContext<IHeaderPortalContext>({
  portalRef: null,
  wasOpen: false,
})

export type HeaderPortalProviderProps = SOG.Props.IDefault & {
  portalRef: React.RefObject<HTMLElement>
  wasOpen: boolean
}

export default function HeaderPortalProvider({
  children,
  portalRef,
  wasOpen,
}: HeaderPortalProviderProps): ReactElement {
  return (
    <PortalContext.Provider value={{ portalRef, wasOpen }}>
      {children}
    </PortalContext.Provider>
  )
}
