import { useEffect, useState } from 'react'
import { Index } from 'elasticlunr'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'
import queryString from 'query-string'

import { getRichTextProp } from '@utils/props'
import { useLocation } from '@reach/router'

type SearchResultsType = {
  headline: string
  copy: SOG.Props.RichText
  breadcrumbs: SOG.Props.LinkTo[]
  locale: string
  hideInNavigation?: boolean
}

const useContentfulSearch = (): [
  SearchResultsType[],
  string,
  (query: string) => void,
  string
] => {
  const { locale } = useIntl()
  const { siteSearchIndex } = useStaticQuery(
    graphql`
      query {
        siteSearchIndex {
          index
        }
      }
    `
  )

  const [index, setIndex] = useState<Index<unknown> | null>(null)
  const [query, setQuery] = useState('')
  const [locationQuery, setLocationQuery] = useState('')
  const [results, setResults] = useState<SearchResultsType[]>([])
  const location = useLocation()

  useEffect(() => {
    if (!index) setIndex(Index.load(siteSearchIndex.index))
  }, [])

  useEffect(() => {
    const value = (queryString.parse(location.hash).searchquery as string) || ''
    setLocationQuery(value)
    setQuery(value)
  }, [location.hash])

  useEffect(() => {
    if (!query || !query.length) return
    search(query)
  }, [query])

  function search(searchquery: string) {
    if (!index) return
    const results: SearchResultsType[] = index
      .search(searchquery, { expand: true })
      .map(({ ref }) => index.documentStore.getDoc(ref))
      .filter((entry: any) => entry.locale === locale)
      .filter((entry: any) => entry.hideInNavigation === false)
      .map(({ copy, ...rest }: any) => ({
        ...rest,
        copy: copy?.raw ? getRichTextProp(copy) : copy,
      }))
    setResults(results)
  }

  return [results, query, setQuery, locationQuery]
}
export default useContentfulSearch
