import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { navigate } from 'gatsby'

import { availableLang, defaultLang } from '../../../../translations/'
import Link from '../link'

export type LanguageSwitchProps = SOG.Props.IDefault

export default function LanguageSwitch({
  sx = {},
}: LanguageSwitchProps): ReactElement {
  const { messages, locale } = useIntl()

  const changeLang = (locale: string) => {
    let path = location?.pathname || ''
    availableLang.forEach((locale) => {
      path = path.replace(`/${locale}`, '')
    })

    if (locale === defaultLang) {
      navigate(path)
      return
    }

    navigate(`/${locale}${path}`)
  }

  const switchLocale = locale === 'de' ? 'en' : 'de'

  const handleSwitch = (event: React.SyntheticEvent) => {
    changeLang(switchLocale)
  }

  return (
    <Box sx={sx}>
      <Link
        id="lang-switch"
        onClick={handleSwitch}
        fontWeight="bold"
        fontSize="small"
        sx={{display: 'block'}}
      >
        {messages[`locale.${switchLocale}`]}
      </Link>
    </Box>
  )
}
