import React, { ReactElement, useState } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'

export type TextInputProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    label: string
    variant?: string
    charLimit: number
    placeholder: string | 'Placeholder'
    className?: string | ''
    id: string
    error?: string | boolean | false
    type?: string | 'text'
    initialValue?: string | 'text'
    defaultValue?: string | 'text'
    multiline?: boolean | false
    rows?: string | number | undefined
    rowsMax?: string | number | undefined
    outlined?: boolean | false
    value?: string
  }

export default function TextArea({
  label,
  charLimit,
  placeholder,
  className,
  id,
  type,
  multiline,
  rows,
  initialValue = '',
  defaultValue = '',
  variant = 'filled',
  minRows,
  rowsMax,
  outlined,
  error,
  required,
  value,
  ...props
}: TextInputProps): ReactElement {
  const CHARACTER_LIMIT = charLimit

  return (
    <div>
      <InputLabel
        htmlFor={id}
        error={error}
        sx={
          error === true ? { color: 'primary.main' } : { color: 'text.primary' }
        }
        required={required}
      >
        {label}
      </InputLabel>

      <TextField
        variant={variant}
        sx={{
          '& .MuiFilledInput-root:before': {
            borderColor: error === true ? 'primary.main' : '#AAAAAA',
          },
          '&:after': {
            borderColor: error === true ? 'primary.main' : '#1E1E1E',
          },
          '& .MuiFormHelperText-root': {
            width: '100%',
            textAlign: 'right',
          },
        }}
        placeholder={placeholder}
        value={value}
        data-testid="input"
        id={id}
        type={type}
        multiline
        inputProps={{
          style: type === 'date' ? { textTransform: 'uppercase' } : {},
          maxlength: CHARACTER_LIMIT,
        }}
        error={error}
        helperText={`${value?.length}/${CHARACTER_LIMIT}`}
        required={required}
        rows={rows}
        minRows={minRows}
        maxRows={rowsMax}
        {...props}
      />
    </div>
  )
}
