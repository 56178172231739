import React, { useEffect, useState, useRef, ReactElement } from 'react'

import 'intersection-observer'

import ToCList, { ListItem } from './tocList'
import { Grid } from '@mui/material'

export type TocProps = SOG.Props.IDefault & {
  items: Array<ListItem>
}

export default function ToC({ children, items }: TocProps): ReactElement {
  const [itemState, setItemState] = useState(
    items?.map(() => {
      return {
        active: false,
      }
    })
  )
  const [position, setPosition] = useState(0)

  const observer = useRef<IntersectionObserver>()
  const ListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let curPos = 0
    if (itemState)
      itemState.forEach((item, i) => {
        if (item.active) {
          curPos = i
        }
      })

    setPosition(curPos)
  }, [itemState])

  useEffect(() => {
    observer.current = new window.IntersectionObserver(handleIntersection, {
      threshold: 0.5,
      rootMargin: '0px 0px -30% 0px',
    })

    if (!items) return
    items.forEach((item, i) => {
      const hl = document.getElementById(item.id)
      if (hl) {
        hl.dataset.position = i.toString(10)
        observer.current?.observe(hl)
      }
    })

    return () => observer.current?.disconnect()
  }, [])

  function handleIntersection(entries: Array<IntersectionObserverEntry>) {
    entries.forEach((entry) => {
      const target = entry.target as HTMLElement
      const position = target.dataset.position
      if (!target || !position) return
      const pos = parseInt(position, 10)
      let curState = false
      if (entry.isIntersecting || entry.boundingClientRect.y < 0) {
        curState = true
      }
      setItemState((state) => {
        const newState = [...state]
        newState[pos] = { active: curState }
        return newState
      })
    })
  }

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        maxWidth: '1104px',
        margin: 'auto',
      }}
    >
      <Grid
        item
        xs={12}
        lg={3}
        ref={ListRef}
        sx={(theme) => ({
          position: 'relative',
          padding: theme.spacing(4, 8),
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(40, 4, 0, 8),
          },
        })}
      >
        {!!items && <ToCList items={items} position={position} />}
      </Grid>
      <Grid item xs={12} lg={9}>
        {children}
      </Grid>
    </Grid>
  )
}
