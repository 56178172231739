import React, { ReactElement, useState } from 'react'
import { Box, Breakpoint, Container, styled, Theme } from '@mui/material'
import clsx from 'clsx'

import Image from '@components/core/media/image'
import { default as themeConfig } from '@config/theme'
import Spacing from '@config/theme/definitions/spacing'
import { StyledMotionDiv } from '../fowardPropsContainer'

export type SectionProps = SOG.Props.IDefault & {
  id?: string
  theme?: SOG.Contentful.Theme | 'white'
  background?: SOG.Props.Video | SOG.Props.Image
  objectFit?: 'contain' | 'fill' | 'none' | 'cover' | 'scale-down'
  objectPosition?: string
  onBackgroundComplete?: () => void
  paddingTop?: SOG.Props.BoxPadding
  paddingBottom?: SOG.Props.BoxPadding
  maxWidth?: boolean | Breakpoint
  withSeparator?: boolean
  paddingSize?: 'sm' | 'md' | 'lg'
}

function getThemeStyles(
  muiTheme: Theme,
  theme?: SOG.Contentful.Theme,
  withBackground?: boolean
) {
  switch (theme) {
    case 'light':
      return {
        backgroundColor: !withBackground
          ? muiTheme.palette.background.light
          : undefined,
      }
    case 'medium':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: !withBackground
          ? muiTheme.palette.background.medium
          : undefined,
      }
    case 'dark':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: !withBackground
          ? muiTheme.palette.background.dark
          : undefined,
      }
    default:
      return {}
  }
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'moduleTheme' && prop !== 'withBackground',
})<{
  withBackground?: boolean
  moduleTheme?: SOG.Contentful.Theme
}>(({ theme, moduleTheme, withBackground }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ...getThemeStyles(theme, moduleTheme, withBackground),
}))

const Section = React.forwardRef(function Section(
  {
    id,
    theme,
    className,
    children,
    background,
    onBackgroundComplete,
    paddingTop,
    paddingBottom,
    maxWidth = true,
    withSeparator = false,
    paddingSize = 'md',
    sx,
  }: SectionProps,
  ref
): ReactElement {
  const isBackgroundImage = background?.file?.contentType.startsWith('image')
  const withBackground = !!background || !!theme
  const withAutoPaddingTop = paddingTop === undefined && withBackground
  const withAutoPaddingBottom = paddingBottom === undefined && withBackground
  const [isBackgroundLoaded, setIsBackgroundLoaded] =
    useState(isBackgroundImage)

  return (
    <Wrapper
      id={id}
      ref={ref}
      className={clsx([theme && 'Mui-themed', className])}
      moduleTheme={theme}
      withBackground={!!background}
      sx={sx}
    >
      <Container
        maxWidth={maxWidth === true ? 'lg' : maxWidth}
        sx={(muiTheme: Theme) => ({
          px: maxWidth === false ? '0 !important' : undefined,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          ...muiTheme.mixins.spacingContainer(
            withAutoPaddingTop || paddingTop || false,
            withAutoPaddingBottom || paddingBottom || false,
            paddingSize
          ),
        })}
      >
        {withSeparator ? (
          <Box sx={{ position: 'relative' }}>
            {children}
            <Box
              sx={(muiTheme: Theme) => ({
                position: 'absolute',
                bottom: themeConfig.spacing(
                  Spacing.padding[paddingSize].md * -1
                ),
                left: 0,
                right: 0,
                borderBottom: '1px solid',
                borderColor: themeConfig.palette.grey[500],
                [muiTheme.breakpoints.up('sm')]: {
                  bottom: themeConfig.spacing(
                    Spacing.padding[paddingSize].lg * -1
                  ),
                },
              })}
            ></Box>
          </Box>
        ) : (
          children
        )}
      </Container>
      {background && (
        <StyledMotionDiv
          initial={{ opacity: 0 }}
          animate={
            isBackgroundLoaded
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                }
          }
          transition={{ duration: 0.25 }}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {isBackgroundImage && (
            <Image
              {...(background as SOG.Props.Image)}
              sx={{
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
              }}
            />
          )}
          {!isBackgroundImage && (
            <Box
              component="video"
              autoPlay
              playsInline
              muted
              src={background?.file?.url}
              onLoadedData={() => setIsBackgroundLoaded(true)}
              onEnded={onBackgroundComplete}
              sx={{
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </StyledMotionDiv>
      )}
    </Wrapper>
  )
})

export default Section
